import { UseFormWatch } from 'react-hook-form';

export const rules = {
  yAxisMin: (watch: UseFormWatch<any>) => {
    return {
      validate: () => {
        const yAxisMin = watch('yAxisMin') ? Number(watch('yAxisMin')) : null;
        const yAxisMax = watch('yAxisMax') ? Number(watch('yAxisMax')) : null;
        if (yAxisMin === null || yAxisMax === null) {
          return null;
        }

        return yAxisMin < yAxisMax ? null : 'Y-Axis Min should be less than Y-Axis Max';
      }
    };
  },
  yAxisMax: (watch: UseFormWatch<any>) => {
    return {
      validate: () => {
        const yAxisMin = watch('yAxisMin') ? Number(watch('yAxisMin')) : null;
        const yAxisMax = watch('yAxisMax') ? Number(watch('yAxisMax')) : null;
        if (yAxisMin === null || yAxisMax === null) {
          return null;
        }
        return yAxisMin < yAxisMax ? null : 'Y-Axis Max should be more than Y-Axis Min';
      }
    };
  }
};
