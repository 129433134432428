import { Grid, Button } from '@mui/material';
import LegendMarker from './LegendMarker';
import {
  MapChartDeviceSettingsTypes,
  MAP_CHART_DEVICES_SETTINGS_TYPES
} from './MapChartDefaultViewConfig';

interface MapChartLegendsProps {
  setShowCritical: React.Dispatch<React.SetStateAction<boolean>>;
  showCritical: boolean;
  setShowHigh: React.Dispatch<React.SetStateAction<boolean>>;
  showHigh: boolean;
  setShowModerate: React.Dispatch<React.SetStateAction<boolean>>;
  showModerate: boolean;
  setShowOther: React.Dispatch<React.SetStateAction<boolean>>;
  showOther: boolean;
  deviceSettings: MapChartDeviceSettingsTypes;
}

export const MapChartLegends = (props: MapChartLegendsProps) => {
  const handleCriticalLegend = () => {
    props.setShowCritical((prev) => !prev);
  };

  const handleHighLegend = () => {
    props.setShowHigh((prev) => !prev);
  };

  const handleModerateLegend = () => {
    props.setShowModerate((prev) => !prev);
  };

  const handleOtherLegend = () => {
    props.setShowOther((prev) => !prev);
  };

  return (
    <Grid container>
      <Grid item>
        <Button onClick={handleCriticalLegend}>
          <LegendMarker severity={'ALERT_FATAL'} show={props.showCritical} />
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={handleHighLegend}>
          <LegendMarker severity="ALERT_ERROR" show={props.showHigh} />
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={handleModerateLegend}>
          <LegendMarker severity="ALERT_WARNING" show={props.showModerate} />
        </Button>
      </Grid>
      {props.deviceSettings === MAP_CHART_DEVICES_SETTINGS_TYPES.ALL && (
        <Grid item>
          <Button onClick={handleOtherLegend}>
            <LegendMarker severity="ALERT_NONE" show={props.showOther} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
