export interface GetDeviceEventsApiArgs {
  slug: string;
  deviceId: number;
  payload: {
    start: number;
    end: number;
    timezone: string;
    dpid?: number;
  };
}

export interface GetDeviceEventsDataArgs {
  slug: string;
  deviceId: number | undefined;
  eventId: number;
  dpid: number;
  unit: Y_AXIS_UNIT.G | Y_AXIS_UNIT.INCHES_PER_SEC;
}

export interface EventsDataObjType {
  eventId: string;
  data: Array<[number, number]>;
}
export interface eventsDataType {
  eventId: string;
  data: Array<[number, number]>;
  ts: string;
}

export enum FREQUENCY_UNIT {
  Hz = 'Hz',
  CPM = 'CPM',
  Orders = 'Orders'
}

export enum Y_AXIS_UNIT {
  G = 'G',
  INCHES_PER_SEC = 'inches/second'
}
export interface ZoomValue {
  min: number | null;
  max: number | null;
}
export interface WaterfallState {
  selectedDatapoint: number | null;
  xUnit: FREQUENCY_UNIT.CPM | FREQUENCY_UNIT.Hz | FREQUENCY_UNIT.Orders;
  yUnit: Y_AXIS_UNIT.G | Y_AXIS_UNIT.INCHES_PER_SEC;
  selectedDevice: number | undefined;
  isStructureborneDatapointSelected: boolean;
  xAxisZoomValue: ZoomValue;
  isSkipNullCpm: boolean;
  speed: number | undefined;
  selectedEventCount: number;
  skipEventCount: number;
  yAxisExtremesValue: ZoomValue;
}
export interface EventType {
  event_time: string;
  event_type: string;
  event_date: string;
  event_name: string;
  event_id: string;
  event_speed_hz: number | null;
  ts: string;
  epochTime: number;
}
export interface DeviceEvents {
  accelerometerEvents: EventType[];
  structureborneEvents: EventType[];
  accelerometerEventsWithCPMValue: EventType[];
  structureborneEventsWithCPMValue: EventType[];
  selectedAccelerometerEvents: string[];
  selectedStructurebornEvents: string[];
}
interface EventData {
  data: [number, number][];
  frequency_step: number;
  raw_dp_name: string;
}

interface DeviceData {
  [key: string]: EventData;
}

export interface DeviceEventData {
  device_name: string;
  event_data: DeviceData;
}

interface dropdownOptions {
  label: string;
  value: number;
}
export interface WaterfallFormObject {
  startDate: number;
  endDate: number;
  dateValue: string;
  xUnit: FREQUENCY_UNIT.CPM | FREQUENCY_UNIT.Hz | FREQUENCY_UNIT.Orders;
  date_range: string;
  deviceId: dropdownOptions;
  datapointId: dropdownOptions;
  yUnit: Y_AXIS_UNIT.G | Y_AXIS_UNIT.INCHES_PER_SEC;
}

export interface eventsData {
  eventId: string;
  data: Array<[number, number]>;
  ts: string;
  speed?: number | null;
}

export interface Config3d {
  alpha: number;
  beta: number;
  depth: number;
  viewDistance: number;
  lineGap: number;
}
