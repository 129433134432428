/**
 * Device Sidebar Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import { SidebarMenu, useSlugContext } from '../../framework';
import { DeviceIcon } from '../../framework/icons';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function DeviceMenu() {
  const { slug } = useSlugContext();
  return (
    <SidebarMenu
      testID={sidebarId.menuItems('assets')}
      route={`/${slug}/app/devices`}
      textKey="assets"
      defaultText="Assets"
      icon={<DeviceIcon className="MuiSvgIcon-root" />}
    />
  );
}
