/**
 * Workflow Sidebar Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import { SidebarMenu, useSlugContext } from '../../framework';
import { WorkflowIcon } from '../../framework/icons';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function WorkflowMenu() {
  const { slug } = useSlugContext();
  return (
    <SidebarMenu
      testID={sidebarId.menuItems('workflow')}
      route={`/${slug}/app/workflow`}
      textKey="workflow"
      defaultText="Workflow"
      icon={<WorkflowIcon className="MuiSvgIcon-root" />}
    />
  );
}
