/**
 * SL Color Picker
 * @author meghneel.adke@shorelineiot.com
 */

import React, { ReactNode, useState } from 'react';
import { SketchPicker } from 'react-color';
import Popover from '@mui/material/Popover';
import { Controller, get, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
import { useStyles } from './colorPicker.styles';
import SLInputLabel, { SLInputLabelProps } from '../form/SLInputLabel';
/**
 *
 */
interface ColorPickerProps {
  name: string;
  label?: ReactNode;
  labelProps?: SLInputLabelProps;
  value?: any;
  handleColorChange?: Function;
}

type Color =
  | {
      hex: string;
      rgb?: string;
    }
  | undefined;
/**
 *
 * @param param0
 * @returns
 */
function SLColorPicker({
  name,
  value,
  label = 'Color',
  labelProps,
  handleColorChange
}: ColorPickerProps) {
  const {
    formState: { errors, defaultValues },
    setValue
  } = useFormContext();
  const defaultValue: Color = get(defaultValues, name);

  const [color, setColor] = useState<any>(value || defaultValue);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const classes = useStyles();

  const setRGBA = (rgba?: any) => {
    return `rgba(${rgba?.r},${rgba?.g},${rgba?.b},${rgba?.a})`;
  };

  const handleChange = (event: any) => {
    setColor(event);
    setValue(name, { hex: event.hex });
    if (handleColorChange) {
      handleColorChange(event);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <FormControl fullWidth error={Boolean(errors[name])} className={classes.root}>
      <Controller
        name={name}
        defaultValue={defaultValues?.[name]?.hex || { hex: defaultValues?.[name] }}
        render={({ field: { ref }, fieldState: { error } }) => (
          <>
            <SLInputLabel bold shrink {...labelProps}>
              {label}
            </SLInputLabel>
            <div role="button" className={classes.colorPicker} onClick={handleClick} tabIndex={-1}>
              <div
                className={classes.color}
                style={{
                  backgroundColor: color?.rgb ? setRGBA(color?.rgb) : color?.hex
                }}
              />
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <SketchPicker ref={ref} color={color} onChangeComplete={handleChange} />
            </Popover>
            <FormHelperText error>{error && error?.message}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
}

export default SLColorPicker;
