/**
 * Line Chart Common Editor
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import WidgetSettingAccordion from '../../common/WidgetAccordion';
import ColumnSorting from './header/ColumnSorting';
import ColumnAlignment from './header/ColumnAlignment';
import FullRowRedirection from './header/FullRowRedirection';

interface Props {
  currentSettings: any;
  handleUpdate: (data: any) => void;
}

const TableWidgetCommonEditor = ({ currentSettings, handleUpdate }: Props) => {
  return (
    <>
      <WidgetSettingAccordion title="common">
        <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
          <Grid item xs={6}>
            <ColumnSorting value={currentSettings?.sorting} handleUpdate={handleUpdate} />
          </Grid>
          <Grid item xs={6}>
            <ColumnAlignment value={currentSettings?.columnAlignment} handleUpdate={handleUpdate} />
          </Grid>
          <Grid item xs={12}>
            <FullRowRedirection
              value={currentSettings?.fullRowRedirection}
              handleUpdate={handleUpdate}
            />
          </Grid>
        </Grid>
      </WidgetSettingAccordion>
    </>
  );
};
export default TableWidgetCommonEditor;
