/**
 * ################################################################
 * ####### WARNING: DO NOT MAKE ANY CHANGES IN THIS FILE. #########
 * ################################################################
 *
 * This file is responsible for connecting Device WebSocket and maintaining
 * the connection while the user is active on the webpage. Any new data or
 * feature should be added inside ./DeviceMessageHandler.ts.
 * Device Service WebSocket Connection
 * @author mahesh.kedari@shorelineiot.com
 */
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { WSProps } from './WSProps';
import { orgMessageHandler } from './org/OrgMessageHandler';
import { PING_PONG_INTERVAL, websocketActions, WEBSOCKET_TYPE } from '../../websocket';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Sends a ping message to the WebSocket server to keep the connection alive.
 * @param {WebSocket} socketInstance - The WebSocket instance to send the ping message through.
 */
function pingStartSubscription(socketInstance: WebSocket) {
  if (socketInstance?.readyState === WebSocket.OPEN) {
    console.debug('Sending ping to keep WebSocket connection alive.');
    socketInstance.send(JSON.stringify({ action: 'OnPing' }));
  }
}

/**
 * Sets up an interval to keep the WebSocket connection alive by sending ping messages.
 * Clears any existing interval before setting up a new one.
 * @param {WebSocket} socketInstance - The WebSocket instance for which to keep the connection alive.
 * @param {React.MutableRefObject<NodeJS.Timeout | null>} intervalRef - A ref to store the interval ID.
 */
function keepSubscriptionAlive(
  socketInstance: WebSocket,
  intervalRef: React.MutableRefObject<NodeJS.Timeout | null>
) {
  // Clear existing interval if it exists
  if (intervalRef.current) clearInterval(intervalRef.current);

  console.debug('Setting up interval for WebSocket ping messages.');
  const keepAlive = setInterval(() => {
    pingStartSubscription(socketInstance);
  }, PING_PONG_INTERVAL);

  intervalRef.current = keepAlive;
}

/**
 * Retrieves the WebSocket endpoint based on the WebSocket type.
 * @param {WEBSOCKET_TYPE} type - The type of WebSocket connection (DEVICE or ORG).
 * @returns {string | null} The WebSocket endpoint URL or null if not found.
 */
const getEndpoint = (type: WEBSOCKET_TYPE) => {
  switch (type) {
    case WEBSOCKET_TYPE.DEVICE:
      return import.meta.env.VITE_DEVICE_WS_ENDPOINT;
    case WEBSOCKET_TYPE.ORG:
      return import.meta.env.VITE_ORG_WS_ENDPOINT;
    default:
      console.warn('Unsupported WebSocket type.');
      return null;
  }
};

/**
 * React component for managing WebSocket connections based on the provided slug.
 * Handles both ORG and DEVICE WebSocket types.
 * @param {WSProps} props - Component props containing the slug for WebSocket connections.
 */
const WebsocketComponent = ({ slug }: WSProps) => {
  const dispatch = useDispatch();
  const deviceSocketRef = useRef<WebSocket | null>(null);
  const orgSocketRef = useRef<WebSocket | null>(null);
  const deviceIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const orgIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Reference to track if we are manually closing a connection
  const isManuallyClosingRef = useRef<{ [key in WEBSOCKET_TYPE]: boolean }>({
    [WEBSOCKET_TYPE.DEVICE]: false,
    [WEBSOCKET_TYPE.ORG]: false,
    [WEBSOCKET_TYPE.CREATE_ORG]: false // Add other types here if needed
  });

  /**
   * Initializes a WebSocket connection based on the given type and slug.
   * Sets up message handlers, error handling, and intervals for connection maintenance.
   * @param {WEBSOCKET_TYPE} type - The type of WebSocket (DEVICE or ORG).
   * @param {string} slug - The slug used as a parameter in the WebSocket URL.
   */
  const initializeWebSocket = useCallback(async (type: WEBSOCKET_TYPE, slug: string) => {
    try {
      const token: any = (await fetchAuthSession()).tokens?.idToken?.toString();
      const endpoint = getEndpoint(type);
      if (!endpoint) {
        console.error(`Failed to start WebSocket for ${type}: endpoint not found.`);
        return;
      }

      if (!token) {
        console.error(`Failed to start WebSocket for ${type}: authentication token missing.`);
        return;
      }

      const JWTToken = `Bearer ${token}`;
      const socket = new WebSocket(`${endpoint}?slug=${slug}&Authorization=${JWTToken}`);

      console.debug(`Initializing WebSocket for ${type}.`);

      // Handle incoming messages
      socket.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        console.debug(`Received message for ${type}:`, data);
        if (type === WEBSOCKET_TYPE.ORG) {
          orgMessageHandler(dispatch, { ...data, slug });
        }
      };

      // Handle errors
      socket.onerror = (event) => {
        console.error(`${type} WebSocket connection error:`, event);
      };

      // Handle socket closure
      socket.onclose = () => {
        console.info(`WebSocket for ${type} is closing.`);
        if (!isManuallyClosingRef.current[type]) {
          console.info(`Reinitializing WebSocket for ${type} since it was not manually closed.`);
          if (type === WEBSOCKET_TYPE.DEVICE) {
            deviceSocketRef.current = null;
            if (deviceIntervalRef.current) {
              clearInterval(deviceIntervalRef.current);
            }
            initializeWebSocket(WEBSOCKET_TYPE.DEVICE, slug);
          } else if (type === WEBSOCKET_TYPE.ORG) {
            orgSocketRef.current = null;
            if (orgIntervalRef.current) {
              clearInterval(orgIntervalRef.current);
            }
            initializeWebSocket(WEBSOCKET_TYPE.ORG, slug);
          }
        } else {
          console.info(`Manual closure detected for ${type}, skipping reinitialization.`);
          isManuallyClosingRef.current[type] = false;
        }
      };

      // Assign the socket reference based on type
      if (type === WEBSOCKET_TYPE.DEVICE) {
        deviceSocketRef.current = socket;
      } else if (type === WEBSOCKET_TYPE.ORG) {
        orgSocketRef.current = socket;
      }

      // Dispatch the WebSocket instance to the Redux store
      dispatch(websocketActions.setWebSocket(socket, type));

      // Set up the keep-alive mechanism for the WebSocket connection
      keepSubscriptionAlive(
        socket,
        type === WEBSOCKET_TYPE.DEVICE ? deviceIntervalRef : orgIntervalRef
      );
    } catch (error) {
      console.error(`Error initializing WebSocket for ${type}:`, error);
    }
  }, []);

  /**
   * Closes the WebSocket connection and clears any associated interval.
   * @param {WebSocket | null} socket - The WebSocket instance to close.
   * @param {React.MutableRefObject<NodeJS.Timeout | null>} intervalRef - The interval ref to clear.
   * @param {WEBSOCKET_TYPE} type - The type of WebSocket connection (DEVICE or ORG).
   */
  const closeWebSocket = useCallback(
    (
      socket: WebSocket | null,
      intervalRef: React.MutableRefObject<NodeJS.Timeout | null>,
      type: WEBSOCKET_TYPE
    ) => {
      if (socket) {
        console.debug(`Closing WebSocket connection for ${type}.`);
        isManuallyClosingRef.current[type] = true;
        socket.close();
      }
      if (intervalRef.current) {
        console.debug(`Clearing interval for ${type} WebSocket ping.`);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    },
    []
  );

  useEffect(() => {
    console.debug('Effect triggered: setting up WebSocket connections.');

    // Clean up old connections if they exist
    if (deviceSocketRef.current) {
      closeWebSocket(deviceSocketRef.current, deviceIntervalRef, WEBSOCKET_TYPE.DEVICE);
    }
    if (orgSocketRef.current) {
      closeWebSocket(orgSocketRef.current, orgIntervalRef, WEBSOCKET_TYPE.ORG);
    }

    // Initialize new WebSocket connections
    initializeWebSocket(WEBSOCKET_TYPE.DEVICE, slug);
    initializeWebSocket(WEBSOCKET_TYPE.ORG, slug);

    return () => {
      console.debug('Cleanup effect: closing WebSocket connections.');
      closeWebSocket(deviceSocketRef.current, deviceIntervalRef, WEBSOCKET_TYPE.DEVICE);
      closeWebSocket(orgSocketRef.current, orgIntervalRef, WEBSOCKET_TYPE.ORG);
    };
  }, [closeWebSocket, initializeWebSocket, slug]); // Only re-run when the slug changes

  return null;
};

export default WebsocketComponent;
