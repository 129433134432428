import {
  BatteryStatusConditionObject,
  BatteryStatusFormattedCellProps,
  TableCellFormattingType,
  TableCellStylesType
} from './batteryStatusTypes';
import { BatteryRemainingDisplayTypes } from './BatteryStatusConstants';
import { renderCorrectBatteryIcon } from '../../dashboard-widgets/SLBatteryWidget/battery.utils';
import { buildStylesObj, matchesCondition } from './batteryStatusUtils';
import { merge } from 'lodash';
import { useStyles } from './batteryStatus.styles';
import { Box } from '@mui/material';

const BatteryStatusFormattedCell = ({
  value,
  settings,
  suffix,
  type
}: BatteryStatusFormattedCellProps) => {
  const classes = useStyles();

  let stylesObj: TableCellFormattingType = {};
  settings?.conditions?.map((condition: BatteryStatusConditionObject) => {
    if (matchesCondition(condition.condition, condition.value, value, type)) {
      const newStylesObj = buildStylesObj(condition.formatters);
      stylesObj = merge({}, newStylesObj);
    }
  });

  const styles: TableCellStylesType = {};
  if (stylesObj?.color) {
    styles.color = stylesObj.color;
  }
  if (stylesObj?.bgcolor) {
    styles.backgroundColor = stylesObj.bgcolor;
  }

  const cellValue = suffix !== undefined ? `${value} ${suffix}` : value;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...styles }}>
      <Box display={'inherit'}>
        {settings?.display &&
          settings.display === BatteryRemainingDisplayTypes.ICON &&
          renderCorrectBatteryIcon(value, {
            width: '1rem',
            height: '2rem'
          })}
      </Box>
      <span style={{ marginLeft: '5px' }}>
        {'indicator' in stylesObj && (
          <span className={classes.indicator} style={{ backgroundColor: stylesObj.indicator }} />
        )}
        {cellValue}
      </span>
    </Box>
  );
};

export default BatteryStatusFormattedCell;
