import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    welcomeStatement: 'Language',
    email: 'Email',
    password: 'Password',
    submit: 'Submit',
    dashboard: 'Dashboard',
    device: 'Device',
    powertrains: 'Powertrains',
    device_profile: 'Device Profile',
    profile: 'Profile',
    sensor_module: 'Sensor Module',
    rules: 'Rules',
    organization: 'Org Settings',
    export_data: 'Export Data',
    firmware: 'Firmware',
    workflow: 'Workflow',
    reports: 'Reports',
    settings: 'Customization',
    user_settings: 'User Settings',
    activity_logs: 'Activity Logs',
    genai_bot: 'GenAI Bot',
    custom_dashboard: 'Dashboard',
    alarm: 'Alarms',
    alerts: 'Alerts',
    snapshot: 'Snapshot',
    assets: 'Assets',
    templates: 'Templates',
    addDashboard: 'Add Dashboard',
    viewAll: 'View All',
    sensorAlarms: 'Sensor Alarms',
    assetAlarms: 'Asset Alarms',
    redirect_to_asset_alarms: 'Redirect to Asset Alarms',
    survey: 'Surveys',
    leak_detection: 'Leak Detection',
    user_script: 'User Scripts',
    event_reports: 'Events Report',
    device_data: 'Device Data',
    external_data: 'External Data',
    device_mailbox: 'Device Mailbox',
    component: 'Component',
    powered_by_shoreline: 'Powered by Shoreline',
    log_in: 'Log In',
    reset_password: 'Reset Password',
    register: 'Register',
    next: 'Next',
    help: 'Help',
    settings_button: 'Settings',
    edit: 'Edit',
    duplicate: 'Duplicate',
    delete: 'Delete',
    add_new: 'Add New',
    critical: 'Critical',
    moderate: 'Moderate',
    high: 'High',
    all: 'All',
    export: 'Export',
    view_active_alarms: 'View Active Alarms',
    view_historical_alarms: 'View Historical Alarms',
    reload_page: 'Reload Page',
    name: 'Name',
    state: 'State',
    created: 'Created',
    latest: 'Latest',
    modified_by: 'Modified By',
    site: 'Site',
    path: 'Path',
    actions: 'Actions',
    view_orbit_plot: 'View Orbit Plot',
    view_asset_dashboard: 'View Asset Dashboard',
    view_sensor_dashboard: 'View Sensor Dashboard',
    view_sensor_data: 'View Sensor Data',
    view_vibration_analysis: 'View Vibration Analysis',
    view_waterfall: 'View Waterfall',
    view_custom: 'View Custom',
    device_images: 'Device Images',
    alarm_notes: 'Alarm Notes',
    alarm_report: 'Alarm Report',
    occurrence: 'Occurrence',
    type: 'Type',
    last_connected: 'Last Connected',
    serial_no: 'Serial No',
    move_to: 'Move To',
    move_here: 'Move Here',
    redirect_to_trends_chart: 'Redirect To Trends Chart',
    delete_powertrain: 'Delete Powertrain',
    copy_powertrain: 'Copy Powertrain',
    copy: 'Copy',
    edit_group: 'Edit Group',
    edit_powertrain: 'Edit Powertrain',
    create: 'Create',
    save: 'Save',
    confirmation: 'Confirmation',
    cancel: 'Cancel',
    download: 'Download',
    data: 'Data',
    activity: 'activity',
    vibration_analysis: 'Vibration Analysis',
    anomaly_configurator: 'Anomaly Configurator',
    trends_analysis: 'Trends Analysis',
    vibration_analysis_message: 'Analyze device sensor data',
    anomaly_configuration_mesage: 'Configure Anomaly',
    trends_analysis_message: 'Analyze trends using historical data',
    start: 'Start',
    powertrain: 'Powertrain',
    unit: 'Unit',
    anomaly_redirection_message: 'Redirect to Anomaly V2',
    create_new: 'Create New',
    fetch: 'Fetch',
    orbit_plot_redirection_message: 'Redirect to Orbit Plot',
    waterfall_redirection_message: 'Redirect to Waterfall',
    show: 'Show',
    invite_user: 'Invite User',
    invite: 'Invite',
    group: 'Group',
    status: 'Status',
    notifications: 'Notifications',
    role_name: 'Role Name',
    role_description: 'Role Description',
    user_attached: 'User Attached',
    clone: 'Clone',
    reset_default: 'Reset Default',
    create_token: 'Create Token',
    creator: 'Creator',
    created_on: 'Created On',
    key: 'Key',
    label: 'Label',
    last_used: 'Last Used',
    category: 'Category',
    operation_details: 'Operation Details',
    user_name: 'User Name',
    timestamp: 'Timestamp',
    device_name: 'Device Name',
    site_name: 'Site Name',
    age: 'Age',
    total_data: 'Total Data',
    observations: 'Observations',
    humidity: 'Humidity',
    link: 'Link',
    upload_timestamp: 'Upload Timestamp',
    source: 'Source',
    total_records: 'Total Records',
    success: 'Success',
    failure: 'Failure',
    request_type: 'Request Type',
    acknowledged_on: 'Acknowledged On',
    request: 'Request',
    response: 'Response',
    upload_firmware: 'Upload Firmware',
    upload: 'Upload',
    version: 'Version',
    description: 'Description',
    model: 'Model',
    release_notes: 'Release Notes',
    attach_firmware_message: 'Attach Firmware to device',
    view: 'View',
    run: 'Run',
    clear: 'Clear',
    import_component: 'Import Component',
    class: 'Class',
    sub_class: 'Sub Class',
    manufacturer: 'Manufacturer',
    last_modified: 'Last Modified',
    apply: 'Apply',
    apply_to_devices: 'Apply To Devices',
    create_copy: 'Create Copy',
    id_provider_name: 'ID Provider Name',
    domain: 'Domain',
    provider_type: 'Provider Type',
    enable_disable: 'Enable/Disable',
    user_notification: 'User Notification',
    rule_name: 'Rule Name',
    level: 'Level',
    tools: 'Tools',
    edit_dashboard: 'Edit Dashboard',
    delete_dashboard: 'Delete Dashboard',
    delete_component: 'Delete Component',
    delete_condition: 'Delete Condition',
    delete_trigger: 'Delete Trigger',
    delete_action: 'Delete Action',
    edit_group_name: 'Edit Group Name',
    unblock_user: 'Unblock User',
    block_user: 'Block User',
    delete_user: 'Delete User',
    resend_invite: 'Resend Invite',
    cannot_delete_script_based_anomaly: 'Cannot delete script-based anomaly',
    export_anomaly_data: 'Export anomaly data',
    delete_variable: 'Delete variable',
    remember_me: 'Remember Me',
    forgot_password: 'Forgot Password',
    user_email: 'User Email',
    please_select: 'Please Select',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    select_the_role: 'Select the role',
    select_org_suborg_to_invite: 'Select Org/Sub org to invite',
    power_train: 'Power Train',
    create_device: 'Create Device',
    add_powertrain: 'Add Powertrain',
    create_power_train: 'Create Power Train',
    create_group: 'Create Group',
    device_type: 'Device Type',
    select_a_group: 'Select a Group',
    device_creation_note: 'Note - Using default profile for the device',
    name_of_power_train: 'Name of Power Train',
    enter_name: 'Enter Name',
    min: 'Min',
    max: 'Max',
    rpm_range: 'RPM Range',
    override_speed_detection: 'Override Speed Detection:',
    no: 'No',
    yes: 'Yes',
    manual: 'Manual',
    cpm_value: 'CPM Value',
    device_datapoint: 'Device Datapoint',
    select_device: 'Select Device',
    select_datapoint: 'Select Datapoint',
    add_component: 'Add Component',
    driver: 'Driver',
    intermediate: 'Intermediate',
    driven: 'Driven',
    name_of_component: 'Name of Component',
    serial_number_full: 'Serial Number',
    select_manufacturer: 'Select Manufacturer',
    select_model: 'Select Model',
    sensor_mount_points: 'Sensor Mount Points',
    sensor_mount_point_message:
      ' We recommend sensors in these locations for best machine coverage. Consult support with questions about coverage with fewer sensors',
    import_manual_narrowband: 'Import Manual Narrowband',
    id: 'ID',
    additional_configuration: 'Additional Configuration',
    accelerometer_microphone: 'Accelerometer Microphone',
    logging_time_battery_hr: 'Logging Time: Battery (hr)',
    sampling_interval_battery: 'Sampling Interval: Battery',
    g_range: 'G Range',
    cloud_update_interval_battery_hr: 'Cloud Update Interval: Battery (hr)',
    set_the_hour_and_minutes: 'Set the hour and minutes',
    set_the_day_s_of_the_week: 'Set the day(s) of the week',
    operating_hours: 'Operating Hours',
    select_the_hour_range: 'Select the hour range',
    seconds_ago: 'seconds ago',
    minutes_ago: 'minutes ago',
    hours_ago: 'hours ago',
    days_ago: 'days ago',
    months_ago: 'months ago',
    years_ago: 'years ago',
    online: 'Online',
    a_few_seconds_ago: 'a few seconds ago',
    trigger_level: 'Trigger (> Level)',
    diagnosis_possible_cause: 'Diagnosis/Possible Cause',
    recommended_actions: 'Recommended Actions',
    analyst_notes: 'Analyst Notes',
    anomaly: 'Anomaly',
    description_of_closure: 'Description of Closure',
    alarms_selected: 'Alarms Selected',
    acknowledged: 'Acknowledged',
    new_alarm: 'New Alarm',
    work_in_progress: 'Work in Progress',
    fixed: 'Fixed',
    false: 'False',
    new: 'New',
    in_progress: 'In Progress',
    alarm_transition: 'Alarm Transition',
    write_here: 'Write Here...',
    reason: 'Reason',
    add_your_notes_here: 'Add your notes here!',
    add_notes: 'Add Notes',
    title: 'Title',
    report_title: 'Report Title',
    asset: 'Asset',
    alarm_type: 'Alarm Type',
    reported_on: 'Reported On',
    recommendation: 'Recommendation',
    diagnosis: 'Diagnosis',
    customer_feedback: 'Customer Feedback',
    show_preview: 'Show Preview',
    click_to_view_pdf_attachment: 'Click to view PDF attachment',
    ok: 'Ok',
    upload_image: 'Upload Image',
    create_org: 'Create Org',
    orgnization_name: 'Organization Name',
    sub_orgnization_name: 'Sub Organization Name',
    short_name: 'Short Name',
    country: 'Country',
    create_organization: 'Create Organization',
    create_sub_org: 'Create Sub Org',
    please_select_sensor: 'Please Select Sensor',
    select_sensor: 'Select Sensor',
    no_data_to_display: 'No Data To Display',
    fetching_data: 'Fetching Data',
    add_trace: 'Add Trace',
    merge_similar_axes: 'Merge similar axes',
    take_tour: 'Take Tour',
    go_to_device_settings_page: 'Go to Device Settings Page',
    hold_down_shift_key_to_pan: 'Hold down shift key to pan',
    mark_narrowband: 'Mark Narrowband',
    search_narrowbands: 'Search Narrowbands',
    go_to_waterfall_page: 'Go to Waterfall Page',
    time: 'Time',
    frequency: 'Frequency',
    count: 'Count',
    manual_scale_y_axis: 'Manual Scale Y Axis',
    indicators: 'Indicators',
    peak_search: 'Peak Search',
    harmonics: 'Harmonics',
    sideband: 'Side Band',
    none: 'None',
    center: 'Center',
    upper: 'Upper',
    lower: 'Lower',
    delta: 'Delta',
    sliding_window: 'Sliding Window',
    delta_markers: 'Delta Markers',
    show_all_hide_all: 'Show all / Hide all',
    unlock_delta_markers: 'Unlock delta markers',
    lock_delta_markers: 'Lock delta markers',
    click_to_unlock_harmonics: 'Click to unlock harmonics',
    set_harmonics_on_chart_to_lock: 'Set harmonics on chart to lock',
    click_to_unlock_sideband: 'Click to unlock sideband',
    set_sideband_on_chart_to_lock: 'Set sideband on chart to lock',
    select_trend: 'Select Trend',
    waterfall: 'Waterfall',
    orbit_plot: 'Orbit Plot',
    trend_chart: 'Trend Chart',
    select_devices: 'Select Devices',
    devices: 'Devices',
    datapoint: 'Datapoint',
    date_range: 'Date Range',
    x_unit: 'X Unit',
    y_unit: 'Y Unit',
    no_events_found_for_selected_criteria: 'No events found for selected criteria',
    events: 'Events',
    select_events_to_plot: 'Select Events to Plot',
    speed_0: 'Speed 0',
    event_count: 'Event Count',
    skip_count: 'Skip Count',
    zoom: 'Zoom',
    min_x: 'Min X',
    max_x: 'Max X',
    reset_x_zoom: 'Reset X Zoom',
    invalid_chart_range: 'Invalid Chart Range',
    min_y: 'Min Y',
    max_y: 'Max Y',
    reset_y_zoom: 'Reset Y Zoom',
    waterfall_chart: 'Waterfall Chart',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    depth: 'Depth',
    view_distance: 'View Distance',
    line_gap: 'Line Gap',
    default: 'Default',
    odr: 'ODR',
    value: 'Value',
    phase_estimate: 'Phase estimate',
    radians: 'radians',
    degrees: 'degrees',
    datapoints: 'Datapoints',
    select_datapoints: 'Select Datapoints',
    select_range: 'Select Range',
    select_notification: 'Select Notification',
    sms: 'SMS',
    app: 'App',
    alert: 'Alert',
    billing: 'Billing',
    pending: 'Pending',
    send_mail: 'Send Mail',
    select_group: 'Select Group',
    select_sub_org: 'Select Sub Org',
    filter_by_role: 'Filter by Role',
    search_user: 'Search User',
    search: 'Search',
    unblock: 'Unblock',
    block: 'Block',
    select_user: 'Select User',
    user_block_msg: 'Are you sure you want to block the user',
    user_unblock_msg: 'Are you sure you want to unblock the user',
    changes_confirmation_msg: 'Are you sure you want to make following changes?',
    type_message_here: 'Type message here',
    super_admin: 'Super Admin',
    admin: 'Admin',
    user: 'User',
    site_manager: 'Site Manager',
    owner_account_administrator: '	Owner Account Administrator',
    organization_user_can_view_everything: 'Organization User can view everything',
    search_group_powertrain: 'Search Group/Powertrain',
    org_url: 'Org URL',
    billing_email_address: 'Billing Email Address',
    appearance: 'Appearance',
    changes_save_msg: 'Do you want to save changes you made?',
    logo: 'Logo',
    favicon: 'Favicon',
    colours: 'Colours',
    primary_color: 'Primary Color',
    secondary_color: 'Secondary Color',
    text_primary_color: 'Text-Primary Color',
    text_secondary_color: 'Text-Secondary Color',
    discard_msg: 'Are you sure you want to discard changes?',
    discard_changes: 'Discard Changes',
    api_token_msg:
      'Use this API key to connect with the cloud. Store the API key and secret below somewhere safe. This is the only time you will see the secret.',
    copy_to_clipboard: 'Copy to clipboard',
    i_have_saved_my_api_key_and_am_ready_to_continue:
      'I have saved my API Key and am ready to continue',
    severity: 'Severity',
    threshold: 'Threshold',
    trigger_value: 'Trigger Value',
    next_connection: 'Next Connection',
    last_month: 'Last Month',
    last_24_hours: 'Last 24 Hours',
    last_7_days: 'Last 7 Days',
    invalid_position_details: 'Invalid Position Details',
    no_data_for_widget_msg: 'No data available for this widget',
    unknown_status: 'Unknown Status',
    replace_in: 'Replace In',
    days: 'Days',
    cellular_signal: 'Cellular Signal',
    device_deletion_msg: 'All the data for this device will be deleted, do you want to continue?',
    export_device_data: 'Export Device Data',
    date: 'Date',
    event: 'Event',
    no_record_available_for_current_date_and_time: 'No record available for current date and time',
    please_select_atleast_one_event: 'Please select atleast one event',
    remove_sensor: 'Remove Sensor',
    add_sensor: 'Add Sensor',
    apply_this_aggregation_to_all: 'Apply this Aggregations to all',
    loading_schema: 'Loading Schema',
    view_details: 'View Details',
    last_update: 'Last Update',
    live_data: 'Live Data',
    historical: 'Historical',
    no_data_found: 'No Data Found',
    error_while_fetching_data: 'Error while fetching data',
    no_sensor_seleted_please_select_a_sensor: 'No sensor seleted, please select a sensor',
    no_data_found_for_selected_criteria: 'No data found for selected criteria',
    hide: 'Hide',
    remove: 'Remove',
    reset: 'Reset',
    sensor_delete_msg: 'Are you sure you want to delete the sensor?',
    failed_to_fetch_sensor_module: 'Failed to fetch sensor module',
    sensor_type_is_not_supported: 'Sensor type is not supported',
    general_settings: 'General Settings',
    delete_device: 'Delete Device',
    rebooting: 'Rebooting',
    reboot: 'Reboot',
    standby_device: 'Standby Device',
    firmware_no_more_supported: 'Firmware no more supported',
    firmware_confirmation: 'Are you sure you want to apply the firmware?',
    interface: 'Interface',
    serial_number: 'Serial Number',
    ble_mac: 'BLE MAC',
    network: 'Network',
    cellular: 'Cellular',
    ethernet: 'Ethernet',
    wifi: 'Wi-Fi',
    network_name: 'Network Name',
    ip_address: 'IP Address',
    subnet_mask: 'Subnet Mask',
    gateway: 'Gateway',
    dns_1: 'DNS 1',
    dns_2: 'DNS 2',
    imei_no: 'IMEI No',
    sim_1: 'SIM 1',
    apn_name: 'APN Name',
    network_provider: 'Network Provider',
    imsi_number: 'IMSI Number',
    sim_2: 'SIM 2',
    edit_sim_setting: 'Edit SIM Setting',
    sim_1_scan_sequence: 'SIM 1: Scan Sequence',
    sim_2_scan_sequence: 'SIM 2: Scan Sequence',
    auto: 'Auto',
    device_photos: 'Device Photos',
    fetching_uploaded_photos: 'Fetching uploaded photos',
    overall_machine: 'Overall Machine',
    device_install: 'Device Install',
    machine_nameplate: 'Machine Nameplate',
    edit_location: 'Edit Location',
    schedule: 'Schedule',
    accelerometer: 'Accelerometer',
    structureborne_microphone: 'Structureborne microphone',
    narrowband: 'Narrowband',
    airborne_microphone: 'Airborne Microphone',
    cloud_update_interval_power_hr: 'Cloud Update Interval: Power (hr)',
    cloud_update_interval: 'Cloud Update Interval',
    set_default: 'Set Default',
    same_as_battery: 'Same as battery',
    at_every: 'At every',
    hour_interval: 'hour interval',
    from: 'from',
    select_the_day_s_of_week: 'Select the day(s) of week',
    clear_all: 'Clear All',
    on_battery: 'On Battery',
    on_power: 'On Power',
    select_the_hour: 'Select the hour',
    select_the_minute: 'Select the minute',
    set: 'Set',
    select_the_interval_hour: 'Select the interval (hour)',
    Accelerometer_Structure_borne_Mic: 'Accelerometer/ Structure borne Mic',
    logging_time_power_min: 'Logging Time: Power (min)',
    sampling_interval_power: 'Sampling Interval: Power',
    Temperature_Humidity_Airborne_Mic: 'Temperature/Humidity/Airborne Mic',
    sample_count: 'Sample Count',
    no_of_reading: 'No of Readings',
    axial_orientation: 'Axial Orientation',
    horizontal_orientation: 'Horizontal Orientation',
    vertical_orientation: 'Vertical Orientation',
    reference_orientation: 'Reference Orientation',
    rotating_machine: 'Rotating Machine',
    raciprocating_machine: 'Raciprocating Machine',
    on: 'On',
    off: 'Off',
    add: 'Add',
    add_manual_narrowband: 'Add Manual Narrowband',
    on_cloud: 'On Cloud',
    on_device: 'On Device',
    alerts_threshold_are_in_inchhes_seconds: 'Alerts threshold are in inches/seconds',
    automated_narrowband: 'Automated Narrowband',
    manual_narrowband: 'Manual Narrowband',
    order_x_freq_hz: 'Order X Freq Hz',
    search_anomaly: 'Search Anomaly',
    anomaly_export: 'Anomaly Export',
    delete_anomaly_msg: 'Are you sure you want to delete the anomaly?',
    sensor: 'Sensor',
    '(none)': '(none)',
    anomaly_name: 'Anomaly Name',
    skip_review: 'Skip Review',
    enter_expression_msg: 'Enter your Mathematical Expression',
    field_is_required: 'Field is required',
    configure_anomaly: 'Configure Anomaly',
    add_variable: 'Add Variable',
    magnitude: 'Magnitude',
    operation: 'Operation',
    operation_interval: 'Operation Interval',
    select_n: 'Select "n"',
    amplitude: 'Amplitude',
    amplitude_g: 'Amplitude (G)',
    sum: 'SUM',
    slope: 'SLOPE',
    average: 'AVERAGE',
    every_n_minutes: 'Every n minutes',
    every_n_hours: 'Every n hours',
    every_n_days: 'Every n days',
    enter_your_mathematical_expression: 'Enter your Mathematical Expression',
    evaluate: 'Evaluate',
    result: 'Result',
    validation_count: 'Validation Count',
    alert_level: 'Alert Level',
    recommended_action: 'Recommended Action',
    anomaly_validation_msg: 'Value should be Moderate < High < Critical or empty',
    enter_a_value: 'Enter a value',
    enter_diagnosis_probable_cause: 'Enter Diagnosis/Probable Cause',
    enter_a_recommended_action: 'Enter a recommended action',
    update: 'Update',
    leave: 'Leave',
    url: 'URL',
    choose_an_option: 'Choose an option',
    asset_dashboard: 'Asset Dashboard',
    sensor_dashboard: 'Sensor Dashboard',
    sensor_data: 'Sensor Data',
    custom: 'Custom',
    asset_list: 'Asset List',
    custom_url: 'custom URL',
    required: ' Required',
    invalid_url: 'Invalid URL',
    url_builder: 'URL Builder',
    anomaly_edit_message: 'It looks like you have been editing something. ',
    anomaly_leave_confirmation_message: 'If you leave before saving, your changes will be lost.',
    create_sso_configuration: 'Create SSO Configuration',
    edit_sso_configuration: 'Edit SSO Configuration',
    help_page: 'Help Page',
    custom_provider_type: 'Custom Provider Type',
    enter_custom_provider_type: 'Enter Custom Provider Type',
    enter_provider_name: 'Enter Provider Name',
    provider_name_already_exists: 'Provider Name already exists',
    domain_name: 'Domain Name',
    enter_domain_name: 'Enter Domain Name',
    sso_note:
      'Note - Below fields are not required for provider type Google. Hence they are disabled.',
    metadata_document_source: 'Metadata Document Source',
    enter_url: 'Enter URL',
    file_upload: 'File Upload',
    enter_saml_attribute_for_email: 'Enter SAML attribute for Email',
    enter_saml_attribute_for_given_name: 'Enter SAML attribute for given_name',
    enter_saml_attribute_for_family_name: 'Enter SAML attribute for family_name',
    fw_ver: 'FW Ver',
    batt_used: 'Batt Used',
    axial: 'Axial',
    surf_temp: 'Surf Temp',
    env_temp: 'Env Temp',
    broadband: 'Broadband',
    data_size: 'Data Size(MB)',
    firmware_file: 'Firmware File',
    choose_file: 'Choose File',
    firmware_name: 'Firmware Name',
    firmware_name_already_exists: 'Firmware Name already exists',
    manifest_version: 'Manifest Version',
    manifest_version_already_exists: 'Manifest Version already exists',
    current_firmware: 'Current Firmware',
    no_devices_to_display: 'No devices to display',
    set_review: 'Set Review',
    selected: 'Selected',
    subclass: 'Subclass',
    class_subclass_mismatch_msg:
      ' Above mentioned class and subclass combination does not exist in our records. Try uploading components with supported class and subclass',
    manufacturer_model_mismatch_msg:
      'Above mentioned combination already exists. Try again by changing the Manufacturer and Model name',
    invalid_json_file: 'Invalid JSON file',
    rev_id: 'Rev ID',
    create_component: 'Create Component',
    add_mount_point_first: 'Add mount point first',
    add_new_row: 'Add new row',
    narrowband_editor: 'Narrowband Editor',
    feaquency_order: 'Frequency/Order',
    mount_points: 'Mount Points',
    delete_selected_narrowband_: 'Are you sure you want to delete the selected Narrowband?',
    nominal_rpm: 'Nominal RPM',
    attached_device_table: 'Attached Device Table',
    org: 'Org',
    sub_org: 'Sub Org',
    add_dashboard: 'Add Dashboard',
    default_dashboard: 'Default Dashboard',
    set_as_default_dashboard: 'set as default dashboard',
    default_dashboard_confirmation_msg: 'Are you sure you want to set this dashboard as default?',
    delete_dashboard_confirmation_msg: 'Are you sure you want to delete this dashboard?',
    new_widget_add_msg: 'Click “Add New” button to add new widgets to your dashboard.',
    add_widget: 'Add Widget',
    raw_data_widget: 'Raw-Data Widget',
    map_chart: 'Map Chart',
    dial_widget: 'Dial Widget',
    plant_hralth: 'Plant Health',
    plant_health_trend: 'Plant Health Trend',
    asset_severity_age: 'Asset Severity Age',
    separator: 'Separator',
    assigned_to: 'Assigned To',
    loading: 'Loading',
    measures: 'Measures',
    dimensions: 'Dimensions',
    segments: 'Segments',
    for: 'For',
    by: 'By',
    filters: 'Filters',
    fill_missing_dates: 'Fill Missing Dates',
    pivot: 'Pivot',
    order: 'Order',
    limit: 'Limit',
    number: 'Number',
    field: 'Field',
    record_count: 'Record Count',
    every: 'Every',
    expand_dropdown_view: 'Expand Dropdown View',
    max_days_left: 'Max Days Left',
    sort_by: 'Sort By',
    record_count_error_msg: 'Record Count must be from 1 to 5000',
    invalid_chart_type: 'Invalid Chart Type',
    widget_type: 'Widget Type',
    no_result_for_selected_criteria: 'No result for selected criteria',
    unable_to_fetch_data: 'Unable to fetch data for selected criteria.',
    please_select_a_class_subclass_manufacturer_or_model:
      'Please select a Class, SubClass, Manufacturer or Model',
    auto_selection_feature_is_yet_to_be_implemented:
      'Auto selection feature is yet to be implemented',
    please_select_some_devices: 'Please select some devices',
    no_alarm_for_selected_criteria: 'There are no Alarms to be displayed for the selected Devices',
    please_select_widget_type: 'Please Select Widget Type',
    only_alarms: 'Only Alarms',
    all_devices: ' All Devices',
    location_marker: 'Location Marker',
    bubble_marker: ' Bubble Marker',
    bubble_makrer_info_text:
      'Bubble-marker - Bubble size increases based on the number of days the alarm(s) have been active. This is intended to draw attention to those alarms that may have been left open/unattended-to for a long time.',
    what_is_bubble_marker: 'What is Bubble Marker?',
    x_axis_title: 'X Axis Title',
    marker_on_off: 'Marker On/Off',
    grid_off: 'Grid Off',
    grid_width: 'Grid Width',
    single_y_axis: 'Single Y Axis',
    single_y_axis_title: 'Single Y Axis Title',
    crosshair: 'Crosshair',
    configs_3d: '3D Configs',
    enable_3d: 'Enable 3D',
    configurations_for: 'Configurations for',
    hide_y_axis: 'Hide Y Axis',
    opposite_side: 'Opposite Side',
    tick_interval: 'Tick Interval',
    y_axis_title: 'Y Axis Title',
    redirection_type: 'Redirection Type',
    parameters: 'Parameters',
    dashboard_list: 'Dashboard List',
    allow_sorting: 'Allow Sorting',
    column_alignment: 'Column Alignment',
    left: 'Left',
    full_row_redirection_: 'Full Row Redirection',
    right: 'Right',
    hide_column: 'Hide Column',
    conditional_formatting: 'Conditional Formatting',
    add_condition: 'Add Condition',
    inner_size: 'Inner Size',
    show_legends: 'Show Legends',
    data_label_value_unit: 'Data Label Value Unit',
    inner_radius: 'Inner Radius',
    hide_label: 'Hide Label',
    scale_min: 'Scale Min',
    scale_max: 'Scale Max',
    decimal_point_resolution: 'Decimal Point Resolution',
    height: 'Height',
    common: 'Common',
    stack_line_chart: 'Stack-line Chart',
    coming_soon: 'Coming soon',
    zoom_x: 'Zoom X',
    battery_icon: 'Battery Icon',
    percentage: 'Percentage',
    add_fromatter: ' add_fromatter',
    display_range: 'Display Range',
    min_value: 'Min Value',
    max_value: 'Max Value',
    show_limit_exceeded: 'Show Limit Exceeded',
    color_configurator: 'Color Configurator',
    to: 'To',
    add_a_color_range: 'Add a Color Range',
    alarm_age: 'Alarm Age',
    select_powertrain: 'Select Powertrain',
    total_powertrain: 'Total Powertrain',
    in_critical: 'In Critical',
    in_high: 'In High',
    no_alarms: 'No Alarms',
    create_powertrain: 'Create Powertrain',
    move_to_note: 'Note - Devices/Groups can be moved within same site only.',
    sensor_status_count: 'Sensor Status Count',
    provisioned: 'Provisioned',
    deprovisioned: 'De-provisioned',
    never_provisioned: 'Never provisioned',
    provisioning_failed: 'Provisioning Failed',
    oldest_connected: 'Oldest Connected',
    newest_connected: 'Newest Connected',
    search_powertrain: 'Search Powertrain',
    no_powertrains_associated_msg: 'Note - No Powertrains are associated to you.',
    no_records_found_try_removing_filter: 'No records found. Try removing filter',
    to_see_any_records_are_available: 'to see if any records are available.',
    apply_filter_powertrain:
      'Showing groups and powertrains associated to you. Click this icon to remove the filter.',
    not_applied_filter_powertrain:
      'Showing all groups and powertrains. Click the icon to view only those associated to you.',
    more_actions: 'More Actions',
    trends_chart: 'Trends Chart',
    dashboard_not_available: 'Sensor Dashboard is not available for this device type',
    sensor_list: 'Sensor List',
    image_and_photos_2d: '2D Image & Photos',
    previous: 'Previous',
    no_photos: 'No Photos',
    trends: 'Trends',
    no_data_available: 'No Data Available',
    redirect_to_aset_alarms: 'Redirect to Asset Alarms',
    alarms: 'Alarms',
    anomaly_definitions: 'Anomaly Definitions',
    custom_note_1: ' Note - The',
    custome_note_2: 'will be created in the selected',
    sub_organization: 'Sub-Organization',
    group_name: 'Group Name',
    search_rules: 'Search Rules',
    rule_delete_confirmation_msg: 'Are you sure you want to delete the selected rules?',
    level_filters: 'Level Filters',
    rule_level: 'Rule Level',
    no_device_found: 'No Device Found',
    condition: 'Condition',
    only_5_conditions_are_allowed: 'Only 5 conditions are allowed for a rule.',
    save_rule: 'Save Rule',
    create_action: 'Create Action',
    send_email: 'Send Email',
    send_sms: 'Send SMS',
    set_variable: 'Set Variable',
    set_webhook_trigger: 'Set Webhook Trigger',
    no_records_to_display: 'No Records To Display',
    total_battery_remaining: 'Total Battery Remaining %',
    sensor_name: 'Sensor Name',
    replace_battery_days: 'Replace Battery (days)',
    log_battery_replacement: 'Log Battery Replacement',
    battery_replaced: 'Battery Replaced',
    battery_replacement_msg: 'Please confirm if battery is replaced on Sensor?',
    invalid_measure_dimensions_segments_msg:
      'Invalid selection of Measures / Dimensions / Segements',
    log_out: 'Log Out',
    local_time_zone: 'Local Time Zone',
    sso_password_msg: ' Password change not permitted since SSO is enabled',
    unit_of_measurement: 'Unit of Measurement',
    languages: 'Languages',
    Password: 'Password',
    change: 'Change',
    change_password: 'Change Password',
    old_password: 'Old Password',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    y_axis_minimum: 'Manual Scale Y Axis Min',
    y_axis_maximum: 'Manual Scale Y Axis Max',
    from_group: 'From',
    to_group: 'To',
    moving: 'Moving',
    cloud_update_interval_default_power_msg:
      'Default power setting is every 1 hour interval everyday.',
    power_setting: 'Power Setting',
    hour_interval_on: 'hour interval on',
    everyday: 'everyday',
    logging_time_power_msg: 'Default power setting is every 1 hour.',
    sampling_interval_power_msg: 'Default power setting is every 5 mins.',
    same_as_power: 'Same as Power',
    provider_name: 'Provider Name',
    hide_markers: 'Hide Markers',
    show_markers: 'Show Markers'
  }
};
