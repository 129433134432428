/**
 * Formatter Value Object
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SLSelect, SLTextField } from '../../../../..';
import SLColorPicker from '../../../../../color-picker/SLColorPicker';
import { IconsList } from '../../../common/IconsList';

interface Props {
  prefix: string;
  handleUpdate: () => void;
}

const FormatterValueObject = ({ prefix, handleUpdate }: Props) => {
  const { watch } = useFormContext();
  const formatterType = watch(`${prefix}.type`);
  switch (formatterType) {
    case 'color':
      return <SLColorPicker name={`${prefix}.color`} handleColorChange={handleUpdate} />;
    case 'bgcolor':
      return <SLColorPicker name={`${prefix}.bgcolor`} handleColorChange={handleUpdate} />;
    case 'icon':
      return (
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <SLSelect
              name={`${prefix}.icon`}
              label="Icon"
              options={IconsList}
              onSelectChange={handleUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <SLColorPicker name={`${prefix}.color`} handleColorChange={handleUpdate} />
          </Grid>
        </Grid>
      );
    case 'format':
      return <SLTextField name={`${prefix}.format`} label="Format" onBlur={handleUpdate} />;
    case 'text':
      return <SLTextField name={`${prefix}.text`} label="Text" onBlur={handleUpdate} />;
    case 'prefix':
      return <SLTextField name={`${prefix}.prefix`} label="Prefix" onBlur={handleUpdate} />;
    case 'suffix':
      return <SLTextField name={`${prefix}.suffix`} label="Suffix" onBlur={handleUpdate} />;

    default:
      return null;
  }
};

export default FormatterValueObject;
