/**
 * Unauthennticated Route
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useRef } from 'react';
import { Navigate, Route, RouteProps, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeAllToasts, CONFIRM_ACTION, SLConfirmationDialog } from '../components';
import { useSlugContext } from './slugContext';
import { useQuery } from '../custom-hooks';
import * as loginActions from '../../features/auth/login/store/actions/login.actions';
import { LoginStateType } from '../../features/auth/login/store/types/LoginStateType';
import { AuthService } from '../services/auth.service';
import { useLoginState } from '../../features/auth/login/store/reducers/login.selectorHooks';
import { HISTORICAL_ALARMS } from '../../features/alarms/store';

type Props = RouteProps & {
  children: ReactNode; // 'children' will render 'SET PASSWORD' page
};

export function UnauthenticatedRoute({ children, ...rest }: Props) {
  const { slug, setSlug } = useSlugContext();
  const login: LoginStateType = useLoginState();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email');
  const authService = new AuthService();

  const redirectionRef = useRef(window.location.href);
  let redirection_url = null;
  const partitions = redirectionRef.current.split('redirect=');
  if (partitions.length > 1) {
    redirection_url = partitions[1];
  }
  if (
    redirection_url !== '' &&
    redirection_url !== null &&
    redirection_url.includes(`alarms/${HISTORICAL_ALARMS}`)
  ) {
    sessionStorage.setItem('redirect', redirection_url);
  }

  /**
   * If user is trying to accept invitation when another user is already logged into same browser, he needs to either
   * 1. Logout existing user and proceed with accepting Invite
   * 2. Terminate Accept Invite process and continue with already logged in user. Below function handles user's choice.
   * @param action
   */
  const handleUserResponse = (action: CONFIRM_ACTION) => {
    if (action === CONFIRM_ACTION.CONFIRM) {
      authService.signOutCurrentUser();
      dispatch(loginActions.logout(false));
      // Remove User Details
      localStorage.clear();
      sessionStorage.clear();
      // Clear fedarated session in cognito
      authService.navigateToFederatedLogOut();
    } else {
      navigate(`${slug}/app`);
    }
  };
  /**
   * Below condition handles the case where one user is already logged-in and another is trying to accept invitation from same browser
   */
  if (
    login.status === 'LOGGED_IN' && // If user is already Logged in
    location.pathname === `/${slug}/auth/invite-accepted` && // Current unauthenticated path is invite-accepted
    login?.session?.email &&
    email?.toLowerCase() !== login?.session?.email?.toLowerCase() // current logged in user is not a desired user to accept invite
  ) {
    // then offer a choice to user either to log-out existing user or terminate invitation acceptance process
    authService.getCurrentUser();
    return (
      <SLConfirmationDialog
        message="Another user is already logged in. Do you wish to logout and continue Accept Invite?"
        confirmButtonName="Yes"
        onConfirm={handleUserResponse}
        displayConfirmationDialog
      />
    );
  }

  const session_storage_redirect_url = sessionStorage.getItem('redirect');

  // temporary workaround - close all the toasts that were raised when the user had not logged in yet
  if (login.status === 'LOGGED_IN') {
    dispatch(closeAllToasts());
  }

  const getRedirectLink = () => {
    //Extract slug from redirect url
    const slug = session_storage_redirect_url?.match(/\/([^/]+)/)?.[1] as string;
    setSlug(slug);
    return `${session_storage_redirect_url}`;
  };
  return (
    <Routes>
      <Route
        path="*"
        element={
          login.status === 'LOGGED_IN' &&
          location.pathname !== `/${slug}/auth/invite-accepted` &&
          !location.pathname.includes(`/${slug}/auth/federated_invite_accept`) ? (
            <Navigate
              to={
                session_storage_redirect_url === '' || session_storage_redirect_url === null
                  ? `/${slug}/app`
                  : getRedirectLink()
              } // Check if URL consists of redirection path, if yes, go to that path else go to home screen
              replace
            />
          ) : (
            children
          )
        }
      />
    </Routes>
  );
}
