/**
 * Organisation Menu Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PopoverPosition, MenuItem, Menu, Typography } from '@mui/material';
// import * as slColors from '@slcloud/constants/colors';
import AddOutline from '@mui/icons-material/AddCircleOutline';
import { alertsListActions } from '../../../alerts/store';
import { deviceReportsActions, deviceMailboxAction } from '../../../reports/store';
import { AnomalyActions } from '../../../workflow/anomaly-configurator/store';
import { VAactions } from '../../../workflow/vibration-analysis/store';
import {
  ACCESS_PERMIT,
  hideBackdrop,
  resetLogoPath,
  setLogoPath,
  showBackdrop,
  SLNestedMenuItem,
  useIsHistoricalAlarms,
  usePermissions,
  useQuery,
  useSlugContext,
  useThemeContext
} from '../../../../framework';
import CreateOrgMenu from './CreateOrgMenu';
import defaultTheme from '../../../../framework/theme/default/theme';
import { useStyles } from '../orgList.styles';
import {
  saveIntoLacalStorage,
  updateThemeData
} from '../../../org-settings/customization/constants/personalizationParser';
// import { ShorelineFavicon } from '../../../../assets';
import { getUserSelf } from '../../../user-settings/getUserSelf';
import {
  DASHBOARD_LIST_ACTIONS,
  LOCAL_DASHBOARD_ITEMS_ACTIONS,
  DEFAULT_DASHBOARD_ACTIONS
} from '../../../dashboard/store';
import {
  alarmsDetailsActions,
  ALARM_TYPE_OPTIONS,
  HISTORICAL_ALARMS,
  ALARM_TAG
} from '../../../alarms/store';
import {
  PERSONALIZE_SETTINGS_STATUS,
  personalizeSettingAction,
  orgUpdateAction,
  roleListActions,
  usePersonalizeSettings,
  useLazyFetchUsers,
  USER_TAG_TYPES
} from '../../../org-settings/store';
import {
  Organisation,
  subOrgCreateActions,
  orgSelectActions,
  subOrgListActions
} from '../../store';
import { rawDatapointsActions, togglePowertrainUserGroupsFilter } from '../../../device';
import { datapointActions, deviceHistoricalActions } from '../../../device/device-details';
import { ORGANISATION_PERMISSIONS } from '../../organisation.permissions';
import {
  useLazyFetchAssetDevices,
  useLazyFetchDeviceGroups,
  useLazyFetchDevices
} from '../../../device/store/device.hooks';
import { resetTrendChart, resetWaterfall } from '../../../workflow/trend-analysis/store';
import { sidebarId } from '../../../../framework/components/sidebar/sidebar.utils';
import TranslateText from '../../../../i18n/TranslateText';
import { updateQueryParameters } from '../../../workflow/trend-analysis/orbit-plot';
import {
  applyFilters,
  emptyFiltersInitialState,
  setFilterChanges,
  setFilterSelections
} from '../../../alarms/store/alarmsFilters.slice';
import { deviceApi } from '../../../device/store/device.api';
import { DEVICE_TAG } from '../../../device/store/device.types';
import { alarmsApi } from '../../../alarms/store/alarms.api';
import { userApi } from '../../../org-settings/store/user-management/components/user-list/user.api';

/**
 *
 */
interface OrganisationMenuProps {
  orgList: Array<Organisation>;
  menuPosition: PopoverPosition | undefined;
  setMenuPosition: Function;
}
/**
 *
 */
const OrganisationMenu = forwardRef(
  (
    { orgList, menuPosition, setMenuPosition }: OrganisationMenuProps,

    ref: any
  ) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useQuery();
    const { slug, setSlug } = useSlugContext();
    const { theme, setTheme } = useThemeContext();
    const { fetchLazyUsers } = useLazyFetchUsers();

    const isCreateSubOrgPermission: ACCESS_PERMIT = usePermissions(
      ORGANISATION_PERMISSIONS.CREATE_SUBORG
    );
    const appSettings = usePersonalizeSettings();
    const isHistoricalAlarmsSelected = useIsHistoricalAlarms();

    const { fetchLazyDevices } = useLazyFetchDevices();
    const { fetchLazyDeviceGroups } = useLazyFetchDeviceGroups();
    const { fetchLazyAssetDevices } = useLazyFetchAssetDevices();

    const historicalAlarmsPrefix = isHistoricalAlarmsSelected ? `/${HISTORICAL_ALARMS}` : '';

    const redirectPaths = [
      {
        path: '/app/custom-dashboard',
        query: null
      },
      {
        path: `/app/alarms${historicalAlarmsPrefix}`,
        query: `alarm_type=${
          params.get('alarm_type') || ALARM_TYPE_OPTIONS.ASSET.value
        }&severity=ALERT_FATAL,ALERT_ERROR`
      },
      {
        path: '/app/devices',
        query: null
      },
      {
        path: '/app/new-asset-dashboard',
        query: null
      },
      {
        path: '/app/alerts',
        query: null
      },
      {
        path: '/app/workflow/vibration-analysis',
        query: null
      },
      {
        path: '/app/workflow/anomaly-configurator',
        query: null
      },
      {
        path: '/app/workflow/ticket-management',
        query: null
      },
      {
        path: '/app/workflow/trend-analysis',
        query: null
      },
      {
        path: '/app/workflow',
        query: null
      },
      {
        path: '/app/firmware',
        query: null
      },
      {
        path: '/app/reports/device-data',
        query: null
      },
      {
        path: '/app/reports/external-data',
        query: null
      },
      {
        path: '/app/reports/device-mailbox',
        query: null
      },
      {
        path: '/app/org-settings/user-management',
        query: null
      },
      {
        path: '/app/org-settings/roles',
        query: null
      },
      {
        path: '/app/org-settings/groups',
        query: null
      },
      {
        path: '/app/org-settings/settings',
        query: null
      },
      {
        path: '/app/org-settings/api-tokens',
        query: null
      },
      {
        path: '/app/activity-logs',
        query: null
      },
      {
        path: '/app/templates/component-compiler',
        query: null
      },
      {
        path: '/app/anomaly-templates',
        query: null
      },
      {
        path: '/app/org-settings/feature-configurations',
        query: null
      },
      {
        path: '/app/org-settings/data-export',
        query: null
      }
      // '/app/alerts/create',
    ];
    useEffect(() => {
      switch (appSettings.status) {
        case PERSONALIZE_SETTINGS_STATUS.READY: {
          if (slug) {
            saveIntoLacalStorage(slug, appSettings?.personalization_info);
          }
          let customTheme = theme;

          if (customTheme) {
            const themeData = appSettings?.personalization_info?.personalization;
            customTheme = updateThemeData(customTheme, themeData, defaultTheme);
            if (setTheme) setTheme(customTheme);
          } else if (setTheme) setTheme(defaultTheme);

          //TODO: favicon variable is not declared anywhere so commenting below
          //once all is stable we need to remove this

          // if (
          //   appSettings?.personalization_info != null &&
          //   appSettings?.personalization_info?.images?.hasOwnProperty('favicon') &&
          //   appSettings?.personalization_info?.images?.favicon?.length > 0
          // ) {
          //   // @ts-ignore
          //   favicon.href = appSettings?.personalization_info?.images?.favicon;
          // } else {
          //   // @ts-ignore
          //   favicon.href = ShorelineFavicon;
          // }
          if (
            appSettings?.personalization_info != null &&
            Object.prototype.hasOwnProperty.call(
              appSettings?.personalization_info?.images,
              'logo'
            ) &&
            appSettings?.personalization_info?.images?.logo?.length > 0
          ) {
            dispatch(resetLogoPath());
            dispatch(setLogoPath(appSettings?.personalization_info?.images?.logo));
          } else {
            dispatch(resetLogoPath());
          }

          break;
        }
        default:
          break;
      }
    }, [appSettings.status]);
    /**
     *
     */
    const createOrg = () => {
      setMenuPosition(undefined);
      navigate(`/${slug}/app/org/create`);
    };

    const createSubOrg = (org: Organisation) => {
      setMenuPosition(undefined);
      dispatch(subOrgCreateActions.initSubOrg(org));
      navigate(`/${slug}/app/org/create-suborg`);
    };

    const resetAlarmRelatedMetaData = () => {
      const handleToggleUserGroupsFilterToDefault = () => {
        dispatch(togglePowertrainUserGroupsFilter(true));
      };

      // Removing `path` URL param from the Alarms page while switching an org.
      updateQueryParameters(location, navigate, undefined, ['path']);
      dispatch(setFilterChanges(emptyFiltersInitialState));
      dispatch(setFilterSelections(emptyFiltersInitialState));
      dispatch(applyFilters());
      dispatch(deviceApi.util.invalidateTags([DEVICE_TAG.DEVICES, DEVICE_TAG.DEVICE_GROUPS]));
      dispatch(userApi.util.invalidateTags([USER_TAG_TYPES.USER_GROUP]));
      dispatch(alarmsApi.util.invalidateTags([ALARM_TAG.ALARMS]));
      handleToggleUserGroupsFilterToDefault();
    };

    const handleOrgSelect = (event: React.MouseEvent, value: Organisation) => {
      if (value?.slug !== slug) {
        setMenuPosition(undefined);
        dispatch(showBackdrop());
        dispatch(orgSelectActions.switchOrg(value));
        if (value?.slug !== 'org') {
          getUserSelf(value?.slug, dispatch);
          dispatch(personalizeSettingAction.getPersonalizationInfoProgress(value?.slug));
        }
        if (setSlug && value?.slug) {
          dispatch(VAactions.fullResetEventsState());
          dispatch(VAactions.fullResetDeviceState());

          dispatch(datapointActions.resetDatapoints());
          dispatch(rawDatapointsActions.resetRawDatapointsState());
          dispatch(deviceHistoricalActions.resetHistoricalData());
          dispatch(alarmsDetailsActions.resetAlarmsDetails());
          dispatch(DASHBOARD_LIST_ACTIONS.resetState());
          dispatch(LOCAL_DASHBOARD_ITEMS_ACTIONS.resetState());
          dispatch(alertsListActions.resetAlertsList());
          dispatch(DEFAULT_DASHBOARD_ACTIONS.resetState());
          dispatch(AnomalyActions.anomalyListReset());
          dispatch(orgUpdateAction.orgDetailsReset());
          fetchLazyDevices({ slug: value?.slug });
          fetchLazyDeviceGroups({ slug: value?.slug });
          fetchLazyAssetDevices({ slug: value?.slug, parentGroupId: null });
          fetchLazyUsers({ slug: value?.slug });
          dispatch(roleListActions.fetchRoles(value?.slug));
          dispatch(deviceReportsActions.fetchDeviceReportsReset());
          dispatch(subOrgListActions.fetchSubOrgList(value?.slug));
          dispatch(deviceMailboxAction.fetchDeviceMailboxReset());
          dispatch(resetWaterfall());
          dispatch(resetTrendChart());
          dispatch(togglePowertrainUserGroupsFilter(true));
          resetAlarmRelatedMetaData();
          setSlug(value?.slug);
          const { pathname } = location;
          // getting current pathname and searching it in static url array below
          const foundPath = redirectPaths.find((path) => pathname.includes(path.path));
          if (foundPath) {
            let newPath = '';
            if (foundPath?.query) {
              newPath = `/${value?.slug}${foundPath?.path}?${foundPath?.query}`;
            } else {
              newPath = `/${value?.slug}${foundPath?.path}`;
            }
            navigate(newPath);
            dispatch(hideBackdrop());
          }
        }
      } else {
        setMenuPosition(undefined);
      }
    };
    return (
      <Menu
        open={Boolean(menuPosition)}
        onClose={() => setMenuPosition(undefined)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        className={classes.menu}
        style={{ zIndex: 9999 }}>
        {orgList.map((organisation: any) => {
          if (organisation?.slug && organisation.suborgs && organisation.org_type === 'org') {
            return (
              <SLNestedMenuItem
                style={{
                  whiteSpace: 'normal',
                  wordBreak: 'break-all'
                }}
                key={organisation.slug}
                label={organisation.name}
                parentMenuOpen={!!menuPosition}
                onClick={(event) => handleOrgSelect(event, organisation)}
                className={classes.submenu}>
                {organisation.suborgs &&
                  organisation.suborgs.map((suborg: any) => {
                    return (
                      <MenuItem
                        id={sidebarId.select_suborgs(organisation.name, suborg.name)}
                        key={suborg.slug}
                        onClick={(event) => handleOrgSelect(event, suborg)}
                        className={classes.suborgmenu}
                        style={{
                          zIndex: 9999,
                          backgroundColor:
                            suborg.slug === slug ? theme?.palette?.primary?.light : 'transperent'
                        }}>
                        <Typography variant="body1">{suborg.name}</Typography>
                      </MenuItem>
                    );
                  })}
                {/* <CanAccess action={SUBORG.CREATE} no={<AccessDenied />}> */}
                <MenuItem
                  id={sidebarId.create_suborg(organisation.name)}
                  onClick={(event) => {
                    event.preventDefault();
                    createSubOrg(organisation);
                  }}
                  className={classes.suborgmenu}
                  disabled={isCreateSubOrgPermission === ACCESS_PERMIT.DENY}>
                  <AddOutline />
                  &nbsp;&nbsp;
                  <Typography variant="body1">
                    {TranslateText('create_sub_org', 'Create Sub Org')}
                  </Typography>
                </MenuItem>
                {/* </CanAccess> */}
              </SLNestedMenuItem>
            );
          }
          return (
            <MenuItem
              key={organisation?.slug || organisation?.suborgs[0]?.slug}
              onClick={(event) =>
                handleOrgSelect(event, organisation?.slug ? organisation : organisation?.suborgs[0])
              }
              style={{ zIndex: 9999 }}
              className={classes.suborgmenu}>
              <Typography variant="body1">
                {organisation?.name || organisation?.suborgs[0]?.name}
              </Typography>
            </MenuItem>
          );
        })}
        <CreateOrgMenu create={createOrg} />
      </Menu>
    );
  }
);

export default OrganisationMenu;
