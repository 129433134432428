/**
 * VA Events Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { put, call, all, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
  VA_ACTIONS,
  VAactions,
  FFT_CHART_STATUS,
  seriesColorMap,
  HarmonicSeriesPointType,
  HarmonicsSeriesType
} from '../index';
import { AMPLITUDE_SELECTION } from '../va-units/vaUnit.stateType';
import {
  getSingleSeriesHarmonicsPoints,
  constructHarmonicSeries
} from '../../fft-plot/helpers/getHarmonicsPoints';
import { EventDataType, EventType, SingleTimeDataType, TimeDataType } from './EventDataType';
import { PeakDatalabelFormatter } from './PeakDatalabelFormatter';
import { findPeaks } from './findPeak';
import { GenericObject, httpGet, SafeSaga, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { getSelectedDevices, getSelectedODR, getHarmonicsCount } from '../common/states.util';
import { getEquivalentValueForSelectedFrequencyUnit } from '../narrowbands/narrowbandsHelper';
// function* getNBSpectrumData(data: any): GenericObject {
//   const spectrumData: EventDataType = yield httpGet(
//     `orgs/${data.slug}/devices/${data.device_id}/event_data`,
//     {
//       vibration_analysis: true,
//       event_id: data.event_id,
//       dpid: data.rawDpid
//     },
//     APISERVICES.VA_API
//   );
//   const spectrumOutput = {
//     deviceId: data.device_id,
//     dpid: data.dpids,
//     event_id: data.event_id,
//     device_name: spectrumData?.event_metadata?.device_name,
//     raw_dp_name: spectrumData?.event_metadata?.raw_dp_name,
//     spectrumData
//   };
//   return spectrumOutput;
// }
function* getAllNBSpectrumData(data: any): GenericObject {
  const spectrumData: EventDataType = yield httpGet(
    `orgs/${data.slug}/devices/${data.device_id}/event_data`,
    {
      // vibration_analysis: true,
      unit: data?.selectedUnit,
      event_id: data.event_id,
      dpids: data.dpids.join(',')
    },
    APISERVICES.VA_API
  );

  const constructSpectrumData: any = {};
  Object.keys(spectrumData.event_data).forEach((key: any) => {
    const singleEvent: EventType = spectrumData.event_data[key];
    singleEvent.deviceId = data.device_id;
    singleEvent.dpids = data.dpids;
    singleEvent.dpid = Number(key);
    singleEvent.event_id = data.event_id;
    singleEvent.device_name = spectrumData.device_name;
    constructSpectrumData[key] = singleEvent;
  });

  return constructSpectrumData;
}

function* getNBTimeData(data: any): GenericObject {
  // return time;
  const timeData: TimeDataType = yield httpGet(
    `orgs/${data.slug}/devices/${data.device_id}/time_domain_analysis`,
    {
      unit: data?.selectedUnit,
      event_id: data.event_id,
      dpids: data.dpids.join(',')
    },
    APISERVICES.VA_API
  );

  const constructSpectrumData: Array<SingleTimeDataType> = [];
  Object.keys(timeData.tda).forEach((key: any) => {
    const singleTimeData: any = timeData.tda[key];
    singleTimeData.deviceId = data.device_id;
    singleTimeData.dpids = data.dpids;
    singleTimeData.dpid = Number(key);
    singleTimeData.event_id = data.event_id;
    singleTimeData.device_name = timeData.device_name;
    constructSpectrumData.push(singleTimeData);
  });
  return constructSpectrumData;
}
/**
 * This function is just a temporary workaround for getting Raw DPID
 * TODO: Need to change DPID object itself so that such complecated handling
 *  won't be needed for getting raw DPID.
 * Adding this fix just to get the raw DPID for the time being.
 * @param selectedDevices
 * @param selectedODR
 * @param deviceId
 * @param dpid
 * @returns
 */
function getRowDPID(
  selectedDevices: Array<any>,
  selectedODR: number,
  deviceId: number,
  dpid: number
) {
  let rowDPID = 0;
  selectedDevices.find((selectedDevice) => {
    if (selectedDevice.device_id === deviceId) {
      const dpidObj = selectedDevice.dpids.find((_dpid: any) => {
        if (_dpid.dpid === dpid) {
          return true;
        }
        return (
          _dpid.otherDpids.findIndex((dpidElement: any) => dpidElement.dpid === dpid) > -1 // if index is greater than -1 then it is found
        );
      });
      const desiredDpid = dpidObj?.otherDpids?.find(
        (otherDpid: any) => otherDpid.odr === selectedODR
      );
      rowDPID = desiredDpid?.dpid;
      return true;
    }
  });
  return rowDPID; //|| dpid;
}

export function* getFFTSpectrumData(action: AnyAction) {
  const {
    slug,
    events,
    fftDomain,
    selectedUnit,
    peakOrRMSValue,
    hzOrCPMValue,
    fftchartStatePayload,
    speedValue
  } = action.payload;
  if (fftDomain === 'frequency') {
    const { peakSearch, harmonicsList, spectrumSeriesId } = fftchartStatePayload;

    const harmonicsCount: number = yield select(getHarmonicsCount);
    const selectedDevices: Array<any> = yield select(getSelectedDevices);
    const selectedODR: number = yield select(getSelectedODR);
    const filterKeys: Array<string> = ['device_id', 'rawDpid', 'event_id'];
    const updatedEvents = events
      .map((event: any) => {
        /*
         * We need to get Raw DPID for the selected ODR for current event
         */
        const rawDpid = getRowDPID(selectedDevices, selectedODR, event.device_id, event.dpids);
        return {
          ...event,
          slug,
          rawDpid
        };
      })
      // Remove Duplicate entries from event that contains same Device ID, Raw DPID and Event id
      // TODO: This is a hack. This needs to be fixed in backend.
      .filter(
        (
          (set: Set<any>) => (obj: any) =>
            ((k) => !set.has(k) && set.add(k))(filterKeys.map((k: string) => obj[k]).join('|'))
        )(new Set())
      );
    {
      /* BELOW creating a payload obj below which is needed for API calling
  All the information is similar so whenever we found existing obj inside payloadConstruct
  we are updating its dpids array and keeping other info as it is
  and if we don't found the obj then we are adding new obj with related fields
        */
    }
    const payloadConstruct: any = {};
    updatedEvents.forEach((event: any) => {
      if (event.rawDpid) {
        if (payloadConstruct[event.event_id]) {
          const currentEvent = payloadConstruct[event.event_id];
          currentEvent.dpids = [...currentEvent.dpids, event.rawDpid];
        } else {
          const payload: any = {};
          payload.device_id = event.device_id;
          payload.event_id = event.event_id;
          payload.dpids = [event.rawDpid];
          payload.slug = event.slug;
          payload.rawDpid = event.rawDpid;
          payloadConstruct[event.event_id] = payload;
        }
      }
    });

    {
      /*Calling all different deviceid related API calls 
    and making sure that each unique deviceid gives single API call instead of multi
    as API supports multiple dpids param
  */
    }
    const nbSpectrumData: GenericObject = yield all(
      Object.values(payloadConstruct).map((eventData: any) => {
        const payload = { ...eventData, selectedUnit };
        return call(getAllNBSpectrumData, payload);
      })
    );

    const spectrumDataMultiDeviceData: Array<EventType> = [];
    nbSpectrumData.filter(
      (data: any) => data.raw_dp_name !== undefined && data.device_name !== undefined
    );
    let spectrumCount = 0;
    const fftchartDataFrequency: any = [];

    const newSpectrumSeriesId = spectrumSeriesId;

    const allHarmonicsSeries: Array<HarmonicsSeriesType> = [];
    const seriesMapIdandColor: any = [];
    // allExitedAxis is needed to get the current axis names

    nbSpectrumData.forEach((spectrum: any) => {
      Object.keys(spectrum).forEach((key) => {
        const spectrumRow: EventType = spectrum[key];
        if (spectrumRow.raw_dp_name !== undefined && spectrumRow.device_name !== undefined) {
          spectrumDataMultiDeviceData.push(spectrumRow);
        }
        const currentDPID = key;

        if (spectrumRow.data && spectrumCount < 4) {
          const frequencyStep = spectrumRow?.frequency_step;

          if (spectrumRow.data) {
            let colorMappingId = 1;
            const foundColorSeries = seriesMapIdandColor.find(
              (s: { deviceId: any }) => s.deviceId === spectrumRow.deviceId
            );
            if (!foundColorSeries) {
              // here means next device series
              colorMappingId = seriesMapIdandColor.length + 1;
              seriesMapIdandColor.push({
                id: seriesMapIdandColor.length + 1,
                deviceId: spectrumRow.deviceId
              });
            } else {
              colorMappingId = seriesMapIdandColor.length;
            }

            let updatedData: any = [];
            updatedData = [];
            updatedData = [...spectrumRow.data];
            updatedData = updatedData.map((singledata: any) => {
              const currentdata = [];
              const mult: any =
                peakOrRMSValue === AMPLITUDE_SELECTION.RMS
                  ? 0.717
                  : peakOrRMSValue === AMPLITUDE_SELECTION.PEAK_TO_PEAK
                    ? 2
                    : 1;
              currentdata[0] = getEquivalentValueForSelectedFrequencyUnit(
                parseFloat(singledata[0]),
                hzOrCPMValue,
                speedValue
              );
              currentdata[1] = parseFloat(singledata[1]) * parseFloat(mult);
              return currentdata;
            });

            if (updatedData && updatedData.length > 0) {
              const currentSeries = {
                data: updatedData,
                findNearestPointBy: 'xy',
                cropThreshold: 100000,
                type: 'line',
                enableMouseTracking: true,
                marker: {
                  enabled: false,
                  states: {
                    hover: {
                      enabled: true
                    }
                  }
                },
                id: `${spectrumRow.deviceId}-${currentDPID}`,
                name: `${spectrumRow.device_name} - ${spectrumRow.raw_dp_name.replace(
                  /[0-9]/g,
                  ''
                )}`,

                color: seriesColorMap(
                  spectrumRow.raw_dp_name.replace(/[0-9]/g, ''),
                  colorMappingId
                ), // seriesColorArray[spectrumCount],
                custom: {
                  frequency_step: frequencyStep || 1,
                  showPeaks: !!peakSearch.enabled
                },
                showInLegend: true,
                events: {
                  hide() {
                    const self: any = this;
                    self?.chart?.get(`${self?.userOptions?.id}-peaks`)?.hide();
                  },
                  show() {
                    const self: any = this;
                    const { showPeaks } = self.chart;
                    if (showPeaks) {
                      self?.chart?.get(`${self?.userOptions?.id}-peaks`)?.show();
                    }
                  }
                }
              };
              fftchartDataFrequency.push(currentSeries);
              const currentSeriesPeak = {
                data: findPeaks(updatedData, peakSearch.count),
                type: 'scatter',
                id: `${spectrumRow.deviceId}-${currentDPID}-peaks`,
                name: `${spectrumRow.device_name} - ${spectrumRow.raw_dp_name.replace(
                  /[0-9]/g,
                  ''
                )} - Peaks`,
                color: seriesColorMap(
                  spectrumRow.raw_dp_name.replace(/[0-9]/g, ''),
                  colorMappingId
                ), // seriesColorArray[spectrumCount],
                custom: {
                  frequency_step: frequencyStep || 1
                },
                showInLegend: false,
                visible: !!peakSearch.enabled,
                marker: {
                  enabled: true
                },
                dataLabels: [
                  {
                    enabled: true,
                    formatter: PeakDatalabelFormatter
                  }
                ]
              };
              fftchartDataFrequency.push(currentSeriesPeak);

              if (harmonicsList.length > 0) {
                const harmonicData: Array<HarmonicSeriesPointType> = getSingleSeriesHarmonicsPoints(
                  harmonicsCount,
                  updatedData,
                  harmonicsList[0].x,
                  currentSeries,
                  hzOrCPMValue,
                  speedValue,
                  peakOrRMSValue
                );

                const harmonicsSeries: HarmonicsSeriesType = constructHarmonicSeries({
                  seriesName: currentSeries.name,
                  data: harmonicData
                });
                allHarmonicsSeries.push(harmonicsSeries);
                fftchartDataFrequency.push(harmonicsSeries);
              }
              spectrumCount = +1;
            }
          }
        }
      });
    });

    // We need to add default harmonic series as it is need to plot harmonics
    const defaultHarmonicsSeries: HarmonicsSeriesType = constructHarmonicSeries({
      seriesName: 'Harmonics',
      data: [],
      defaultSeries: true
    });

    fftchartDataFrequency.push(defaultHarmonicsSeries);

    const finalFFTchartDataFrequency = fftchartDataFrequency.map((s: any) => {
      const curDeviceName = s.name.split('-', 2);
      if (curDeviceName[1]?.trim() === 'Structure borne') {
        // s.yAxis = 1;
        return { ...s, yAxis: 1 };
      }
      // s.yAxis = 0;
      return { ...s, yAxis: 0 };
    });
    const finalStatePayload = {
      status: FFT_CHART_STATUS.FREQUENCY_READY,
      fftDataFrequency: spectrumDataMultiDeviceData,
      fftchartDataFrequency: finalFFTchartDataFrequency,
      spectrumSeriesId: newSpectrumSeriesId,
      harmonicsList: harmonicsList,
      allHarmonicsSeries: allHarmonicsSeries
    };
    yield put(VAactions.updateFFTFrequency(finalStatePayload));
  } else if (fftDomain === 'time') {
    const selectedDevices: Array<any> = yield select(
      (state) => state.features.workflow.va.devices.selectedDevices
    );
    const selectedODR: number = yield select(
      (state) => state.features.workflow.va.narrowbands.selectedODR
    );

    const payloadConstruct: any = {};
    const filterKeys: Array<string> = ['device_id', 'rawDpid', 'event_id'];
    const updatedEvents = events
      .map((event: any) => {
        /*
         * We need to get Raw DPID for the selected ODR for current event
         */
        const rawDpid = getRowDPID(selectedDevices, selectedODR, event.device_id, event.dpids);
        return {
          ...event,
          slug,
          rawDpid
        };
      })
      .filter(
        (
          (set: Set<any>) => (obj: any) =>
            ((k) => !set.has(k) && set.add(k))(filterKeys.map((k: string) => obj[k]).join('|'))
        )(new Set())
      );
    {
      /* BELOW creating a payload obj below which is needed for API calling
  All the information is similar so whenever we found existing obj inside payloadConstruct
  we are updating its dpids array and keeping other info as it is
  and if we don't found the obj then we are adding new obj with related fields
        */
    }
    updatedEvents.forEach((event: any) => {
      if (event.rawDpid) {
        if (payloadConstruct[event.event_id]) {
          const currentEvent = payloadConstruct[event.event_id];
          currentEvent.dpids = [...currentEvent.dpids, event.rawDpid];
        } else {
          const payload: any = {};
          payload.device_id = event.device_id;
          payload.event_id = event.event_id;
          payload.dpids = [event.rawDpid];
          payload.slug = event.slug;
          payload.rawDpid = event.rawDpid;
          payload.selectedUnit = selectedUnit;
          payloadConstruct[event.event_id] = payload;
        }
      }
    });

    const narrowbandTimeMultiDevice: GenericObject = yield all(
      Object.keys(payloadConstruct).map((nbTimeData) => {
        return call(getNBTimeData, payloadConstruct[nbTimeData]);
      })
    );
    let timeDataConstruct: Array<SingleTimeDataType> = [];
    Object.keys(narrowbandTimeMultiDevice).forEach((eventId) => {
      timeDataConstruct = [...timeDataConstruct, ...narrowbandTimeMultiDevice[eventId]];
    });
    yield put(
      VAactions.updateFFTTime({
        data: timeDataConstruct,
        selectedUnit,
        peakOrRMSValue
      })
    );
  }
}
function* handleError(error: any) {
  yield put(VAactions.fetchEventsFailure(error));
}

export function* watchVAFFTSpectrumSaga(): SagaIterator {
  yield all([
    takeLatest(
      VA_ACTIONS.FETCH_FFT_SPECTRUM,
      SafeSaga(
        getFFTSpectrumData,
        VA_ACTIONS.FETCH_FFT_SPECTRUM,
        handleError,
        null,
        'Failed to fetch Spectrum data'
      )
    )
  ]);
}
