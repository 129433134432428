/**
 * Line Chart Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more'; // module
import indicators from 'highcharts/indicators/indicators';
import { lineChartConfig } from './utils/lineChartConfig';
import { CustomConfigs, lineChartConfig_live_data } from './utils/lineChartConfig-live-data';
import { useNavigate, useLocation } from 'react-router';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import moment from 'moment';
import { handleEventMetaData } from '../../../../features/workflow/trend-analysis/store';
import { useDispatch } from 'react-redux';

HCMore(Highcharts); // init module

indicators(Highcharts);
noDataToDisplay(Highcharts);
Highcharts.setOptions({
  lang: {
    resetZoom: 'Reset',
    resetZoomTitle: '',
    noData: 'No data to display'
  }
});

interface Props {
  data: Array<any>;
  unit: string;
  fetchData?: Function;
  isLive?: boolean;
  dpid?: string | number;
  newLiveData?: boolean;
  sensorType?: string;
  customChartConfig?: CustomConfigs;
  height?: string;
}
/**
 *
 * @param param0
 */
const LineChartComponent = React.forwardRef(
  (
    { data, unit = 'Values', isLive = true, dpid, sensorType, customChartConfig, height }: Props,
    ref: any
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    // const MAX_POINTS = 250;

    /**
     * This method gets called when using zoom feature on chart
     */

    unit = unit || 'Values';
    if (unit === 'null') {
      unit = 'Values';
    }

    const handleZoomForHistoricalData = async (e: any) => {
      if (e.trigger != undefined) {
        // const { chart } = e.target;
        try {
          // chart.showLoading('Loading data ...');
          // const startDate = Math.round(e.min / 1000);
          // const endDate = Math.round(e.max / 1000);
          // const response = await httpGet(
          //   `orgs/${slug}/devices/${deviceId}/datapoints/historical_data`,
          //   {
          //     start: startDate,
          //     end: endDate,
          //     maxPoints: 5000,
          //     dpids: [dpid],
          //   }
          // );
          // const responseData = response.data;
          // const responseArray = responseData[dpid];
          // chart.series[0].setData(responseArray.reverse());
          // // chart.series[0].update({ data: responseArray });
          // chart.hideLoading();
          // const data = {
          //   dpid,
          //   startDateNum: e.min,
          //   endDateNum: e.max,
          //   zoomLiveOrHist: 'Hist'
          // };
          // dispatch(getZoomData(data));
        } catch (error: any) {
          // chart.hideLoading();
        }
      }
    };

    const handleZoomForLiveData = async (e: any) => {
      if (e.trigger != undefined) {
        // const data = {
        //   dpid,
        //   startDateNum: e.min,
        //   endDateNum: e.max,
        //   zoomLiveOrHist: 'Live'
        // };
        // dispatch(getZoomData(data));
      }
    };
    // const handleMarkerPointData = async (e: any) => {
    //   const { chart } = e.target;
    //   // const { series } = this;
    //   const markerOptions = chart.series[0].options.marker;

    //   if (chart.series[0].data.length < MAX_POINTS) {
    //     chart.series[0].update({
    //       marker: {
    //         enabled: true,
    //       },
    //     });
    //   } else if (chart.series[0].data.length <= 1000) {
    //     markerOptions.enabled = true;
    //   } else {
    //     markerOptions.enabled = false;
    //   }
    // };

    const [chartOptionsHistoricalData, setChartOptionsHistoricalData] = useState(
      lineChartConfig(unit, handleZoomForHistoricalData, data)
    );

    const getFormattedValueTime = (value?: number, ts?: number) => {
      if (value !== undefined && ts !== undefined) {
        const currentTime = new Date(ts / 1000);
        const time = moment(currentTime).format(`MMM DD, YYYY hh:mm:ss.SSS A`);
        dispatch(handleEventMetaData({ value, ts: time }));
      }
    };

    const [chartOptionsLiveData, setChartOptionsLiveData] = useState(
      lineChartConfig_live_data(
        unit,
        handleZoomForLiveData,
        //handleMarkerPointData,
        data,
        getFormattedValueTime,
        navigate,
        location,
        customChartConfig
      )
    );

    useEffect(() => {
      if (isLive) {
        setChartOptionsLiveData(
          lineChartConfig_live_data(
            unit,
            handleZoomForLiveData,
            //handleMarkerPointData,
            data,
            getFormattedValueTime,
            navigate,
            location,
            customChartConfig
          )
        );
      } else {
        setChartOptionsHistoricalData(lineChartConfig(unit, handleZoomForHistoricalData, data));
      }
    }, [data, navigate, location, customChartConfig?.initialSelectionDone]);

    useEffect(() => {
      const chart = ref?.current?.chart;
      if (chart) {
        chart.sensor_type = sensorType;
      }
    });

    let chartOptions = chartOptionsHistoricalData;
    if (isLive) {
      chartOptions = chartOptionsLiveData;
    }
    return (
      <HighchartsReact
        containerProps={{
          style: { height: height || '250px', overflow: 'auto', width: '100%' }
        }}
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate
        ref={ref}
      />
    );
  }
);
export default LineChartComponent;
