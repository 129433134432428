/**
 * Solid Gauge Settings
 * @author mahesh.kedari@shorelineiot.com
 */
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge.js';
/**
 *
 */
interface Stop {
  value: number;
  color: string;
}

highchartsMore(Highcharts);
solidGauge(Highcharts);
/**
 *
 */
export interface SolidGaugeSettings {
  min: number;
  max: number;
  decimalPointResolution: number;
  unit: string;
  range: Array<Stop>;
}

export function parseSolidGaugeSettings(settings: SolidGaugeSettings): Highcharts.Options {
  const { min = 0, max = 100, unit, decimalPointResolution } = settings;

  return {
    chart: {
      type: 'solidgauge'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          backgroundColor: Highcharts?.defaultOptions?.legend?.backgroundColor || '#CCCCCC99',
          innerRadius: '70%',
          outerRadius: '100%',
          borderWidth: 0,
          shape: 'arc'
        }
      ]
    },
    exporting: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    // the value axis
    yAxis: {
      stops: [
        [0.5, '#DF5353'], // red
        [0.52, '#DDDF0D'], // yellow
        [0.7, '#DDDF0D'], // yellow
        [0.75, '#55BF3B'] // green
      ],
      lineWidth: 0,
      tickWidth: 2,
      tickAmount: 5,
      title: {
        y: -70
      },
      labels: {
        distance: -56
      },
      min,
      max
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    },
    series: [
      {
        type: 'solidgauge',
        name: 'Availability',
        dataLabels: {
          format:
            `${
              '<div style="text-align:center">' + '<span style="font-size:25px"><b>{y:.'
            }${decimalPointResolution}f}</b></span><br/>` +
            `<span style="font-size:12px;opacity:0.4">${unit || '%'}</span>` +
            `</div>`
        },
        tooltip: {
          valueSuffix: ' %'
        }
      }
    ]
  };
}
