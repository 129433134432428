/**
 * Submenu Link
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ListItemText } from '@mui/material';
import { useStyles } from '../sidebarItems.styles';
import { LightTooltip } from '../../../tooltip/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import TranslateText from '../../../../../i18n/TranslateText';
import { resetBreadcrumb } from '../../../breadcrumb';
import { useDispatch } from 'react-redux';
/**
 *
 */
interface BaseProps {
  route: string;
  defaultText: string;
  onClick?: () => void;
  icon?: JSX.Element;
  isActive?: () => boolean;
  testID?: string;
}
/**
 * When Label is present, textKey should not be passed
 */
interface PropsWithLabel extends BaseProps {
  label: string;
  textKey?: never;
}
/**
 * When TextKey is present, Label should not be passed
 */
interface PropsWithTextKey extends BaseProps {
  label?: string;
  textKey: string;
}

type Props = PropsWithLabel | PropsWithTextKey;
/**
 *
 * @param param0
 */
export default function SubmenuLink({
  route,
  textKey,
  label,
  defaultText,
  onClick,
  icon,
  isActive,
  testID
}: Props): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const defaultTextRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  useLayoutEffect(() => {
    if (defaultTextRef.current !== null) {
      const node = defaultTextRef.current;
      if (node?.scrollWidth > node?.clientWidth) {
        setOpen(true);
      }
    }
  });

  const isSelected = () => {
    if (isActive !== undefined) {
      return isActive();
    }
    const currentURLWithParams = `${pathname}${search}`;
    return route === currentURLWithParams;
  };

  return (
    <Link to={route} onClick={() => dispatch(resetBreadcrumb())} className={classes.routeLinks}>
      <ListItemButton
        id={testID}
        className={classes.subMenu}
        selected={isSelected()}
        disableRipple
        onClick={onClick}>
        <LightTooltip
          disableHoverListener={!open}
          title={TranslateText(textKey, defaultText)}
          placement="right">
          <ListItemText className={classes.sublistText}>
            <div className={classes.marker}>&nbsp;</div>
            {icon ? (
              <h2 className={classes.customIcon}>{icon}</h2>
            ) : (
              <h2 className={classes.bullet}>&bull;</h2>
            )}
            <div ref={defaultTextRef} className={classes.subMenuDefaultText}>
              {label || TranslateText(textKey, defaultText)}
            </div>
          </ListItemText>
        </LightTooltip>
      </ListItemButton>
    </Link>
  );
}
