/**
 * Battery Indicator
 * @author mahesh.kedari@shorelineiot.com
 */
import { Typography } from '@mui/material';
/**
 *
 */
interface Props {
  level: number;
  showCharging?: boolean;
  showLabel?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  chargingColor?: string;
  opacity?: number;
  fontSize?: number;
  fontColor?: string;
  height?: number;
  width?: number;
}

const DEFAULT_BACKGROUND = '#a0a0a0';
const DEFAULT_FOREGROUND = '#37F53B';
const DEFAULT_CHARGING = 'yellow';
const DEFAULT_OPACITY = 0.3;
const DEFAULT_FONT_SIZE = 11;
const DEFAULT_FONT_COLOR = '#333';
/**
 *
 * @param param0
 * @returns
 */
export default function BatteryIndicator({
  level,
  showLabel = true,
  showCharging = true,
  backgroundColor = DEFAULT_BACKGROUND,
  foregroundColor = DEFAULT_FOREGROUND,
  chargingColor = DEFAULT_CHARGING,
  opacity = DEFAULT_OPACITY,
  fontSize = DEFAULT_FONT_SIZE,
  fontColor = DEFAULT_FONT_COLOR,
  height = 300,
  width = 300
}: Props) {
  if (level > 1 || level < 0) {
    return (
      <>
        <Typography variant="h3">Invalid Level {level} specified</Typography>
        <Typography variant="body2">Should be between 0 to 1</Typography>
      </>
    );
  }
  return (
    <svg viewBox="0 0 34 98" height={height} width={width}>
      <defs>
        <linearGradient id="progress" x1="0" y1="1" x2="0" y2="0">
          <stop id="stop1" offset={level} stopColor={foregroundColor} />
          <stop id="stop2" offset={level} stopColor={backgroundColor} stopOpacity={opacity} />
        </linearGradient>
      </defs>
      <path
        fill="url(#progress)"
        d="M32.016,4.813 L24.102,4.813 L24.102,1.127 C24.102,0.689 23.746,0.333 23.307,0.333 L11.142,0.333 C10.703,0.333 10.347,0.69 10.347,1.127 L10.347,4.813 L2.432,4.813 C1.364,4.813 0.498,5.677 0.498,6.745 L0.498,96.066 C0.498,97.131 1.364,98 2.432,98 L32.015,98 C33.082,98 33.949,97.136 33.949,96.066 L33.949,6.745 C33.949,5.677 33.084,4.813 32.016,4.813 Z"
      />
      {showCharging && (
        <polygon
          fill={chargingColor}
          points="16.96 75.677 16.96 55.544 13.156 58.984 16.96 30.246 16.96 50.953 21.291 48.207"
        />
      )}
      {showLabel && (
        <text
          x="17"
          y="88"
          alignmentBaseline="middle"
          fontSize={fontSize}
          fill={fontColor}
          textAnchor="middle"
          style={{
            fontFamily:
              "-apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
          }}>
          {level * 100}%
        </text>
      )}
    </svg>
  );
}
