/**
 * Analytics Cartesian Chart Editor
 * @author mahesh.kedari@shorelineiot.com
 *
 */
import { useCubeQuery } from '@cubejs-client/react';
import { Grid } from '@mui/material';
import WidgetSettingAccordion from '../../../common/WidgetAccordion';
import SLSelect from '../../../../form/select/SLSelect';
import { CartesianChartsList } from '../cartesianChartsList';
import SLTextField from '../../../../form/SLTextField';
import SLColorPicker from '../../../../color-picker/SLColorPicker';
import YAxisConfigEditor from '../../../common/YAxisConfigEditor';
import RedirectionURLEditor from '../../../common/RedirectionURLEditor';
import { getUnique } from '../../../pie-chart/settings/AnalyticsPieChartEditor';
/**
 *
 */
interface SeriesConfigurationsProps {
  series: any;
  chartSettings: any;
  handleUpdate: () => void;
}

/**
 *
 * @param param0
 * @returns
 */
const SeriesConfigurations = ({
  series,
  chartSettings,
  handleUpdate
}: SeriesConfigurationsProps) => {
  const seriesKey = series.key.replace(/[.,]/g, '_');
  const path = `dataSettings.${seriesKey}`;
  return (
    <WidgetSettingAccordion title={series?.title}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SLSelect
            name={`${path}.type`}
            label="Type"
            translationId="type"
            options={CartesianChartsList}
            onSelectChange={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          {/* Spacer */}
        </Grid>
        <Grid item xs={6}>
          <SLTextField
            name={`${path}.name`}
            label="Title"
            labelTranslationId="title"
            onBlur={handleUpdate}
          />
        </Grid>
        <Grid item xs={6}>
          <SLColorPicker
            name={`${path}.color`}
            label="Color"
            value={{
              hex: chartSettings?.dataSettings?.[seriesKey]?.color
            }}
            handleColorChange={handleUpdate}
          />
        </Grid>
      </Grid>
      <YAxisConfigEditor
        path={path}
        currentValue={chartSettings?.dataSettings?.[seriesKey]?.yAxis}
        handleUpdate={handleUpdate}
      />
      <RedirectionURLEditor
        path={path}
        handleUpdate={handleUpdate}
        currentValue={chartSettings?.dataSettings?.[seriesKey]}
      />
    </WidgetSettingAccordion>
  );
};
/**
 *
 */
interface EditorProps {
  query: any;
  chartSettings: any;
  handleUpdate: () => void;
}

/**
 * This function will combine the existing data settings which user has
 * configures & new query response. It will unionize the resultSet while
 * skipping the duplicate items.
 * @param dataSettings This is the existing data settings object,
 * which is stored in the database by user.
 * @param incomingData: This is the new incoming data array coming
 * from the result of query from cubejs.
 * @returns Array
 */
export const combineExistingAndNewIncomingWidgetsData = (
  dataSettings: any = {},
  incomingData: Array<any> = []
) => {
  const newArray: Array<any> = [];

  if (Object.entries(dataSettings)?.length) {
    // Add all keys from dataSettings object into the newArray.
    Object.entries(dataSettings)?.forEach(([key]: [any, any]) => {
      const titleWithoutUnderscore: string = key?.replace(/[.,_]/g, ' ');
      const duplicateData = incomingData?.find((data: any) => {
        const seriesKey = data?.key?.replace(/[.,]/g, '_');
        return seriesKey === key;
      });
      if (duplicateData) {
        // Before adding the key from dataSettings object, check if
        // this key is already present in incomingData array.
        // If it is present (duplicate), then add that key from incomingData
        // object, so that we will get proper title.
        const seriesKey = duplicateData?.key?.replace(/[.,]/g, '_');
        const newObject: any = {
          key: seriesKey,
          title: duplicateData?.title
        };
        newArray?.push(newObject);
      } else {
        const newObject: any = {
          key,
          title: titleWithoutUnderscore
        };
        newArray?.push(newObject);
      }
    });
  }

  if (incomingData?.length) {
    incomingData?.forEach((data: any) => {
      const seriesKey = data?.key?.replace(/[.,]/g, '_');
      const newObject: any = {
        key: seriesKey,
        title: data?.title
      };
      newArray?.push(newObject);
    });
  }

  return getUnique(newArray, 'key');
};

/**
 *
 * @param param0
 * @returns
 */
const AnalyticsCartesianChartEditor = ({ query, chartSettings, handleUpdate }: EditorProps) => {
  const { resultSet } = useCubeQuery<any>(query);
  const resultSeries: any = resultSet?.series();

  const newResultSeries: any = combineExistingAndNewIncomingWidgetsData(
    chartSettings?.dataSettings,
    resultSeries
  );

  return (
    <>
      {newResultSeries?.map((series: any) => (
        <SeriesConfigurations
          key={series.key}
          series={series}
          handleUpdate={handleUpdate}
          chartSettings={chartSettings}
        />
      ))}
    </>
  );
};
export default AnalyticsCartesianChartEditor;
