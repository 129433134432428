/**
 * Firmware Sidebar Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import { ACCESS_PERMIT, SidebarMenu, usePermissions, useSlugContext } from '../../framework';
import { FirmwareIcon } from '../../framework/icons';
import { FIRMWARE_PERMISSIONS } from './firmware.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function FirmwareMenu() {
  const { slug } = useSlugContext();
  const hasViewPermission: ACCESS_PERMIT = usePermissions(FIRMWARE_PERMISSIONS.VIEW);
  if (hasViewPermission === ACCESS_PERMIT.ALLOW) {
    return (
      <SidebarMenu
        testID={sidebarId.menuItems('firmware')}
        route={`/${slug}/app/firmware`}
        textKey="firmware"
        defaultText="Firmware"
        icon={<FirmwareIcon className="MuiSvgIcon-root" />}
      />
    );
  }
  return null;
}
