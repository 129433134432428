import { APISERVICES, showToast } from '../../../../framework';

import { BucketConfigArgs, ScheduleConfigArgs, DATA_EXPORT_TAG } from './dataExport.types';
import { buildQuery } from '../../../../framework/lib/rtk/RTK.utils';

import { HTTP_METHOD } from '../../../../framework/services/auth.service';

export function fetchBucketConfigQuery({ slug }: { slug: string }) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/s3/credentials`,
    method: HTTP_METHOD.GET,
    service: APISERVICES.EXPORT_API,
    actionKey: DATA_EXPORT_TAG.DATA_EXPORT
  });
}
export function fetchScheduleConfigQuery({ slug }: { slug: string }) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/configure`,
    method: HTTP_METHOD.GET,
    service: APISERVICES.EXPORT_API,
    actionKey: DATA_EXPORT_TAG.DATA_EXPORT
  });
}
export function testBucketConfigQuery({ slug, formData }: BucketConfigArgs) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/s3/test `,
    method: HTTP_METHOD.POST,
    service: APISERVICES.EXPORT_API,
    actionKey: DATA_EXPORT_TAG.DATA_EXPORT,
    body: formData
  });
}
export function saveBucketConfigQuery({ slug, formData }: BucketConfigArgs) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/s3/save`,
    method: HTTP_METHOD.POST,
    service: APISERVICES.EXPORT_API,
    actionKey: DATA_EXPORT_TAG.DATA_EXPORT,
    body: formData
  });
}

export function saveScheduleConfigQuery({ slug, formData }: ScheduleConfigArgs) {
  return buildQuery({
    apiEndpoint: `orgs/${slug}/configure`,
    method: HTTP_METHOD.POST,
    service: APISERVICES.EXPORT_API,
    actionKey: DATA_EXPORT_TAG.DATA_EXPORT,
    body: formData,
    onSuccess: (dispatch) => {
      dispatch(showToast('Export job configuration created successfully.', 'success'));
    }
  });
}
