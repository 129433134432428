/**
 * VA units Saga
 * @author ashwini.gawade@shorelineiot.com
 */

import { put, takeEvery, call, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { VA_ACTIONS, VAactions } from '../index';
import {
  httpGet,
  httpPut,
  removeProgressFor,
  APISERVICES,
  showProgressFor
} from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { UnitInfoType, UnitsPayload } from './vaUnit.stateType';

function changeMotionUnitCall(data: any) {
  return httpPut(
    `orgs/${data.slug}/users/units`,
    {
      user_unit: data.value
    },
    APISERVICES.HOST_API
  );
}

function getMotionUnits(data: any) {
  return httpGet(`orgs/${data.slug}/users/units`, null, APISERVICES.HOST_API);
}

function* getMotionUnitsData(action: AnyAction): any {
  yield put(showProgressFor('VA_UNIT'));
  try {
    const response: UnitInfoType = yield call(getMotionUnits, action.payload);
    yield put(VAactions.fetchMotionUnitsSuccess(response));
  } catch (error: any) {
    yield put(VAactions.fetchMotionUnitsFailure(error));
  } finally {
    yield put(removeProgressFor('VA_UNIT'));
  }
}

function* getFFTSpectrumPayload(slug: string): any {
  const events = yield select((state: any) => state.features.workflow.va.events.deviceEvents);

  const fftDomain = yield select((state: any) => state.features.workflow.va.fftChart.domain);

  const selectedUnitType = yield select(
    (state: any) => state.features.workflow.va.units.motionUnit.selectedMotionUnitType
  );

  const peakOrRMSValue = yield select(
    (state: any) => state.features.workflow.va.units.amplitudeSelection
  );

  const hzOrCPMValue = yield select((state: any) => state.features.workflow.va.units.frequencyUnit);

  const harmonicsList = yield select(
    (state: any) => state.features.workflow.va.fftChart.harmonicsList
  );

  const harmonicsOriginalList = yield select(
    (state: any) => state.features.workflow.va.fftChart.harmonicsOriginalList
  );

  const spectrumSeriesId = yield select(
    (state: any) => state.features.workflow.va.fftChart.spectrumSeriesId
  );

  const peakSearch = yield select((state: any) => state.features.workflow.va.fftChart.peakSearch);
  const selectedEvent = yield select(
    (state: any) => state.features.workflow.va.events.selectedEvent
  );

  const fftPayload = {
    slug,
    events,
    fftDomain,
    selectedUnit: selectedUnitType,
    hzOrCPMValue,
    peakOrRMSValue,
    speedValue: selectedEvent?.speed_hz,
    fftchartStatePayload: {
      peakSearch,
      harmonicsList,
      harmonicsOriginalList,
      spectrumSeriesId
    }
  };
  return fftPayload;
}
function* getNarrowBandsPayload(slug: string): any {
  const frequencyUnit = yield select(
    (state: any) => state.features.workflow.va.units.frequencyUnit
  );
  const amplitudeSelection = yield select(
    (state: any) => state.features.workflow.va.units.amplitudeSelection
  );
  const motionUnitType = yield select(
    (state: any) => state.features.workflow.va.units.motionUnit.selectedMotionUnitType
  );
  const selectedDevices: Array<any> = yield select(
    (state: any) => state.features.workflow.va.devices.selectedDevices
  );
  const deviceEvents: Array<any> = yield select(
    (state: any) => state.features.workflow.va.events.deviceEvents
  );

  const params = new URL(window.location.href).searchParams; //window.location; //.searchParams;

  const currentParamDpid = params.get('nbDpid');
  const paramNBDpid: any = currentParamDpid
    ? {
        dpid: Number(currentParamDpid?.split('_')[1] || 0),
        deviceId: Number(currentParamDpid?.split('_')[0] || 0)
      }
    : null;

  const checkednb = params.get('checkednb') !== 'false';
  const units: UnitsPayload = {
    frequencyUnit,
    motionUnitType,
    amplitudeSelection
  };
  return { slug, units, selectedDevices, deviceEvents, paramNBDpid, checkednb };
}
function* changeMotionUnit(action: AnyAction): any {
  yield put(showProgressFor('VA_UNIT_CHANGE'));
  try {
    yield call(changeMotionUnitCall, action.payload);
    yield put(VAactions.updateSelectedMotionUnitSuccess(action.payload));

    const fftPayload = yield call(getFFTSpectrumPayload, action.payload.slug);
    yield put(VAactions.fetchFFTSpectrum(fftPayload));

    const narrowBandPayload = yield call(getNarrowBandsPayload, action.payload.slug);
    yield put(
      VAactions.fetchNarrowbands(
        narrowBandPayload.slug || '',
        narrowBandPayload.units,
        narrowBandPayload.selectedDevices,
        narrowBandPayload.deviceEvents,
        narrowBandPayload.paramNBDpid,
        narrowBandPayload.checkednb
      )
    );
  } catch (error: any) {
    yield put(VAactions.fetchMotionUnitsFailure(error));
  } finally {
    yield put(removeProgressFor('VA_UNIT_CHANGE'));
  }
}
export function* watchMotionUnitsSaga(): SagaIterator {
  yield takeEvery(VA_ACTIONS.FETCH_MOTION_UNITS, getMotionUnitsData);
  yield takeEvery(VA_ACTIONS.UPDATE_SELECTED_MOTION_UNIT, changeMotionUnit);
}
