import { APISERVICES, showToast } from '../../../../framework';
import {
  ApiToken,
  ApiTokenDeletePayload,
  APITOKENS_TAG_TYPES,
  ApiTokenUpdatePayload,
  Slug
} from './apiToken.types';
import { featureApi } from '../../../feature.api';
import { invalidateTagsListId } from '../../../../framework/lib/rtk/RTK.constants';

export const apiTokensApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchApiTokens: build.query<ApiToken[], Slug>({
      query: (slug: string) => ({
        apiEndpoint: `orgs/${slug}/apikeys`,
        params: null,
        service: APISERVICES.HOST_API,
        method: 'GET',
        actionKey: 'FETCH_API_TOKENS_LIST'
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ api_key_id }) => ({
                type: APITOKENS_TAG_TYPES.APITOKEN,
                id: api_key_id
              })),
              { type: APITOKENS_TAG_TYPES.APITOKEN, id: invalidateTagsListId }
            ]
          : [{ type: APITOKENS_TAG_TYPES.APITOKEN, id: invalidateTagsListId }]
    }),
    createApiToken: build.mutation<ApiToken, Slug>({
      query: (slug) => ({
        apiEndpoint: `orgs/${slug}/apikey`,
        params: null,
        service: APISERVICES.HOST_API,
        method: 'POST',
        actionKey: 'CREATE_API_TOKENS_PROGRESS',
        onSuccess: (dispatch) => {
          dispatch(showToast('API Token Created Successfully!', 'success'));
        }
      }),
      invalidatesTags: () => {
        return [{ type: APITOKENS_TAG_TYPES.APITOKEN, id: invalidateTagsListId }];
      }
    }),
    updateApiToken: build.mutation<ApiToken, ApiTokenUpdatePayload>({
      query: (data) => ({
        apiEndpoint: `orgs/${data.slug}/apikey`,
        body: {
          api_key_id: data.api_key_id,
          label: data.label
        },
        service: APISERVICES.HOST_API,
        method: 'PATCH',
        actionKey: 'UPDATE_API_TOKENS_PROGRESS',
        onSuccess: (dispatch) => {
          dispatch(showToast('API Token Updated Successfully!', 'success'));
        }
      }),
      invalidatesTags: (data) => {
        return [{ type: APITOKENS_TAG_TYPES.APITOKEN, id: data?.api_key_id }];
      }
    }),
    deleteApiToken: build.mutation<void, ApiTokenDeletePayload>({
      query: (data) => ({
        apiEndpoint: `orgs/${data.slug}/apikey`,
        body: {
          api_key_id: data.api_key_id
        },
        service: APISERVICES.HOST_URL,
        method: 'DELETE',
        actionKey: 'DELETE_API_TOKENS_PROGRESS',
        onSuccess: (dispatch) => {
          dispatch(showToast('API Token Deleted Successfully!', 'success'));
        }
      }),
      invalidatesTags: () => {
        return [{ type: APITOKENS_TAG_TYPES.APITOKEN, id: invalidateTagsListId }];
      }
    })
  })
});

export const {
  useFetchApiTokensQuery,
  useCreateApiTokenMutation,
  useDeleteApiTokenMutation,
  useUpdateApiTokenMutation
} = apiTokensApi;
