import Highcharts from 'highcharts';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
export const pieChartConfig = (): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      animation: false,
      zooming: { type: 'x' },
      plotBorderWidth: 0,
      spacingLeft: 0,
      spacingRight: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter() {
        return `<b>${this.key} : ${this.y} </b>`;
      },
      style: {
        color: 'white'
      },
      backgroundColor: 'rgba(77, 77, 77, 0.7)'
    },
    rangeSelector: {
      enabled: true,
      buttonPosition: {
        align: 'right',
        x: -25
      },
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        style: {
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: 'none'
          },
          select: {
            fill: 'none',
            style: {
              color: 'white'
            }
          }
          // disabled: { ... }
        }
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#00b1c1',
        fontWeight: 'bold'
      },
      buttons: [
        {
          type: 'all',
          text: 'Reset'
        }
      ],
      inputEnabled: false,
      labelStyle: {
        display: 'none'
      }
    },
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      enabled: true,
      adaptToUpdatedData: false,
      xAxis: {
        type: 'linear',
        labels: {}
      }
    },
    credits: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        size: '100%',
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          // distance: '0%',
          distance: -50,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            fontSize: '13px'
          }
        }
      }
    },
    series: [
      {
        type: 'pie'
      }
    ]
  };
};
