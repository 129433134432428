/**
 * Reset Password Saga
 * @author swapnil.kshirsagar@shorelineiot.com
 */
import { put, takeEvery, call, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from '../actions/acceptInvite.actions';
import * as ACTIONS from '../actions/acceptInvite.actionTypes';
import * as loginActions from '../actions/login.actions';
import { orgListActions } from '../../../../organization/store';
import { ACCEPT_INVITE_STATUS } from '../types';
import {
  httpGet,
  httpPost,
  removeProgressFor,
  showProgressFor,
  showToast,
  APISERVICES,
  SafeSaga
} from '../../../../../framework';
import { AuthService } from '../../../../../framework/services/auth.service';
import { AuthSession } from 'aws-amplify/auth';

function inviteAccept(obj: any) {
  return httpPost(
    `orgs/${obj.slug}/users/invite/accept`,
    {
      slugs: obj.subOrgSlugs,
      role: obj.role
    },
    APISERVICES.HOST_API
  );
}
interface ValidatePasswordResponse {
  invite_accepted: true;
  user_active: true;
}
/**
 *
 * @param password
 * @returns
 */
function passwordValidation(password: string): Promise<ValidatePasswordResponse> {
  return httpGet(`orgs/user-invite?passcode=${password}`, null, APISERVICES.HOST_API);
}
/**
 *
 * @param action
 */
function* acceptInvitation(action: AnyAction) {
  try {
    yield put(showProgressFor('ACCEPT_INVITE'));
    const response: {
      message: string;
    } = yield call(inviteAccept, action.payload);
    if (response.message) {
      if (response.message === 'ROLE-UPDATED') {
        yield put(
          showToast('Invite accepted successfully and your role has been updated', 'success')
        );
      } else {
        // If user is deleted from perticular org
        yield put(showToast(response.message, 'warning'));
      }
    } else {
      yield put(showToast('Invite accepted successfully', 'success'));
    }
    yield put(actions.acceptInviteSuccess({ response }));
    yield put(orgListActions.fetchOrgList());
  } catch (error: any) {
    const errorBody = error?.response?.body;
    const parsedError = typeof errorBody === 'string' ? JSON.parse(errorBody) : errorBody;
    const errCode = parsedError?.err_code;
    if (errCode === 'ORG-SERVICE__USER-INVITE-ALREADY-ACCEPTED') {
      yield put(showToast('User invite for email already has been accepted', 'warning', true));
      yield put(actions.acceptInviteFailure(ACCEPT_INVITE_STATUS.ALREADY_ACCEPTED));
    } else {
      yield put(showToast('Failed to accept invite', 'error', true));
      yield put(actions.acceptInviteFailure(ACCEPT_INVITE_STATUS.ERROR));
    }
  } finally {
    yield put(removeProgressFor('ACCEPT_INVITE'));
  }
}

function* resetPassword(action: AnyAction) {
  try {
    yield put(showProgressFor('RESET_PASSWORD'));
    const authService = new AuthService();
    yield authService.updatePassword(action.payload);
    const session: AuthSession = yield authService.getCurrentSession();
    const sessionObject = {
      email: action.email,
      // username: response.username,
      accessToken: session.tokens?.accessToken.toString(),
      idToken: session.tokens?.idToken?.toString()
      // refreshToken: response.signInUserSession.refreshToken
    };

    yield put(actions.resetPasswordSuccess(sessionObject));
    yield call(acceptInvitation, action);
  } catch (error: any) {
    yield put(showToast('Failed to reset password', 'error', true));
    yield put(actions.resetPasswordFailure(error.message));
  } finally {
    yield put(removeProgressFor('RESET_PASSWORD'));
  }
}

function* validatePassword(action: AnyAction) {
  try {
    yield put(showProgressFor('VALIDATE_PASSWORD'));
    const response: ValidatePasswordResponse = yield call(passwordValidation, action.payload);
    if (response.invite_accepted) {
      yield put(showToast('Invite has already been accepted', 'warning', true));
      yield put(actions.validatePasswordFailure(ACCEPT_INVITE_STATUS.ALREADY_ACCEPTED));
    } else if (!response.user_active) {
      yield put(showToast('This user is blocked. Please contact Org Administrator', 'error', true));
      yield put(actions.validatePasswordFailure(ACCEPT_INVITE_STATUS.ALREADY_ACCEPTED));
    } else {
      yield put(actions.validatePasswordSuccess(ACCEPT_INVITE_STATUS.PASSWORD_VALIDATED));
    }
  } catch (error: any) {
    actions.validatePasswordFailure(ACCEPT_INVITE_STATUS.ERROR);
  } finally {
    yield put(removeProgressFor('VALIDATE_PASSWORD'));
  }
}

function acceptInviteInProgress(data: any) {
  return httpPost(
    `orgs/${data.slug}/users/invite/accept-in-progress`,
    {
      email: data.email,
      slugs: data.subOrgSlugs,
      role: data.role
    },
    APISERVICES.HOST_API
  );
}

function* markAcceptInviteInProgress(action: AnyAction) {
  yield call(acceptInviteInProgress, action.payload);
  const authService = new AuthService();
  authService.navigateToFederatedIdentityProvider(
    action.payload.provider,
    'federated_invite_accept'
  );
}

function acceptFederatedInvite() {
  return httpPost('orgs/users/federated/invite/accept', null, APISERVICES.HOST_API);
}

function* acceptInProgressInvites(action: AnyAction) {
  yield put(showProgressFor('ACCEPT_INVITE'));
  try {
    yield call(acceptFederatedInvite);
    yield put(actions.acceptInviteSuccess());
    yield put(loginActions.loginSuccess(action.payload));
  } catch (error: any) {
    yield put(showToast(`Failed to accept invite \n${error}`, 'error', true));
  } finally {
    yield put(removeProgressFor('ACCEPT_INVITE'));
  }
}
export function* acceptInviteSaga() {
  yield all([
    takeEvery(ACTIONS.VALIDATE_PASSWORD, validatePassword),
    takeEvery(ACTIONS.RESET_PASSWORD_PROGRESS, resetPassword),
    takeEvery(ACTIONS.ACCEPT_INVITE, acceptInvitation),
    takeEvery(
      ACTIONS.MARK_ACCEPT_INVITE_IN_PROGRESS,
      SafeSaga(markAcceptInviteInProgress, ACTIONS.MARK_ACCEPT_INVITE_IN_PROGRESS)
    ),
    takeEvery(ACTIONS.ACCEPT_IN_PROGRESS_INVITES, acceptInProgressInvites)
  ]);
}
