import { Typography, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TranslateText from '../../../../i18n/TranslateText';

export const BubblePointInfo = () => {
  const infoText = TranslateText(
    'bubble_makrer_info_text',
    'Bubble-marker - Bubble size increases based on the number of days the alarm(s) have been active. This is intended to draw attention to those alarms that may have been left open/unattended-to for a long time.'
  );
  const infoPrompt = TranslateText('what_is_bubble_marker', 'What is a Bubble Marker?');
  return (
    <Typography variant="body2">
      <Tooltip title={infoText}>
        <Box>
          <InfoIcon />
          <span style={{ marginLeft: '5px' }}>{infoPrompt}</span>
        </Box>
      </Tooltip>
    </Typography>
  );
};
