/**
 * Leak Detection Columns
 * @author mahesh.kedari@shorelineiot.com
 */
import { ReactElement } from 'react';
import { SLTableColumn, capitalize, useShowTableRowTooltip } from '../../../../framework';
import { Palette, useTheme } from '@mui/material';
import { LEAK_ALERT_LEVEL, LeakDetectionAlertLevel } from '../utils/LeakDetectionAlarmType';

interface Props {
  cellValue: string;
  rowObj?: any;
}

const getTextColor = (alarmSeverity: LeakDetectionAlertLevel, palette: Palette) => {
  switch (alarmSeverity) {
    case LEAK_ALERT_LEVEL.ALERT_FATAL:
      return palette.error.main;
    case LEAK_ALERT_LEVEL.ALERT_ERROR:
      return palette.warning.main;
    case LEAK_ALERT_LEVEL.ALERT_WARNING:
    default:
      return 'inherit';
  }
};

function formattedText(text: string) {
  switch (text) {
    case 'ALERT_ERROR':
      return 'High';
    case 'ALERT_FATAL':
      return 'Critical';
    case 'ALERT_WARNING':
      return 'Moderate';
    case 'ALERT_LOW':
      return 'Low ';
    case 'All':
      return 'All';
    default:
      break;
  }
  return 'None';
}
function TableColumn({ cellValue, rowObj }: Props): ReactElement {
  const { palette } = useTheme();
  return useShowTableRowTooltip(cellValue, {
    textColor: getTextColor(rowObj.severity, palette)
  });
}

export const LeakActiveAlarmsColumns: Array<SLTableColumn<any>> = [
  {
    accessorKey: 'name',
    header: 'Name',
    CellRenderer: ({ cellValue, rowObj }: any) => (
      <TableColumn cellValue={cellValue} rowObj={rowObj} />
    ),
    minSize: 30,
    size: 190,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.name?.toLowerCase();
      const rowBValue = rowB.original?.name?.toLowerCase();
      return rowAValue > rowBValue ? 1 : -1;
    }
  },
  {
    accessorKey: 'severity',
    header: 'Severity',
    CellRenderer: ({ cellValue, rowObj }: any) => (
      <TableColumn cellValue={formattedText(cellValue)} rowObj={rowObj} />
    ),
    minSize: 30,
    size: 190,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.severity;
      const rowBValue = rowB.original?.severity;
      if (rowAValue === rowBValue) {
        return 0;
      }
      const severityOrder = [
        LEAK_ALERT_LEVEL.ALERT_FATAL,
        LEAK_ALERT_LEVEL.ALERT_ERROR,
        LEAK_ALERT_LEVEL.ALERT_WARNING,
        LEAK_ALERT_LEVEL.ALERT_LOW
      ];
      return severityOrder.indexOf(rowAValue) - severityOrder.indexOf(rowBValue);
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    CellRenderer: ({ cellValue, rowObj }: any) => (
      <TableColumn cellValue={capitalize(cellValue)} rowObj={rowObj} />
    ),
    minSize: 30,
    size: 190,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.status;
      const rowBValue = rowB.original?.status;
      if (rowAValue === rowBValue) {
        return 0;
      }
      const AlarmStatusType = ['REVIEW', 'NEW', 'ACK', 'IN_PROGRESS', 'FIXED', 'FALSE'];
      return AlarmStatusType.indexOf(rowAValue) - AlarmStatusType.indexOf(rowBValue);
    }
  },
  {
    accessorKey: 'created',
    header: 'Age (Days)',
    CellRenderer: ({ cellValue, rowObj }: any) => {
      const currentTime = new Date().getTime();
      return (
        <TableColumn
          cellValue={((currentTime - cellValue) / (1000 * 3600 * 24)).toFixed(2) + ' ago'}
          rowObj={rowObj}
        />
      );
    },
    minSize: 30,
    size: 190,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.created;
      const rowBValue = rowB.original?.created;
      return rowAValue - rowBValue;
    }
  }
];
