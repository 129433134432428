import { IconButton } from '@mui/material';
import { DeleteIcon } from '../../icons';
import { LightTooltip } from '../tooltip/Tooltip';
import { ACTION_ICON_CLASS_NAME } from './actionsIcon.utils';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface ActionsDeleteIconProp {
  onClick?: React.MouseEventHandler<any> | undefined;
  /**
   * Make this `true` if you want IconButton & want to disable
   * the button conditionally.
   * @default false
   */
  isButton?: boolean | undefined;
  /**
   * Make this `true` if you want to disable the button.
   * @default false
   */
  disabled?: boolean | undefined;
  /**
   * Custom tooltip title.
   * @default 'Delete'
   */
  tooltipTitle?: string | undefined;
  /**
   * Make this `true` if you don't want to show the tooltip
   * & only want to show the icon.
   * @default false
   */
  noTooltip?: boolean | undefined;
  iconButtonClassName?: any;
  iconFillOpacity?: string | number | undefined;
  disableHoverListener?: boolean | undefined;
  //TODO: ideally this hould be controlled via class or icon variant
  /**
   * If you to overide the icon color, must avoid overriding this attribute.
   * @default 'black'
   */
  iconColor?: string;
  testID?: string;
}

const ActionsDeleteIcon = ({
  onClick,
  isButton = true,
  disabled,
  tooltipTitle,
  noTooltip,
  iconButtonClassName,
  disableHoverListener,
  iconColor = 'black',
  testID
}: ActionsDeleteIconProp) => {
  if (noTooltip) {
    <DeleteIcon color="black" className={ACTION_ICON_CLASS_NAME} onClick={onClick} />;
  }

  if (isButton) {
    return (
      <LightTooltip
        title={tooltipTitle || 'Delete'}
        disableHoverListener={disableHoverListener}
        placement="bottom-end"
        translationId={toSnakeCase(tooltipTitle) || 'delete'}>
        <span>
          <IconButton
            id={testID}
            aria-label="delete"
            className={iconButtonClassName}
            size="small"
            disabled={disabled}
            onClick={onClick}>
            <DeleteIcon
              color={iconColor}
              className={ACTION_ICON_CLASS_NAME}
              fillOpacity={disabled ? '0.3' : ''}
            />
          </IconButton>
        </span>
      </LightTooltip>
    );
  }

  return (
    <LightTooltip
      title={tooltipTitle || 'Delete'}
      placement="bottom-end"
      translationId={toSnakeCase(tooltipTitle) || 'delete'}>
      <DeleteIcon color={iconColor} className={ACTION_ICON_CLASS_NAME} onClick={onClick} />
    </LightTooltip>
  );
};

export default ActionsDeleteIcon;
