import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertLevel } from '../../../alarms/store';

export enum NEW_ASSET_DASHBOARD_URL_PARAMS {
  SELECTED_POWERTRAIN_PATH = 'selectedPowertrainPath',
  SELECTED_POWERTRAIN_NAME = 'selectedPowertrainName',
  TRENDS_DATAPOINTS = 'trendsDatapoints',
  SELECTED_SEVERITY = 'selectedSeverity',
  TREND_CHART_HEIGHT = 'trendChartHeight',
  TREND_DATERANGE = 'trendDaterange',
  SELECTED_GROUP_PATH = 'fq_dg_path',
  USER_GROUP_FILTER = 'userGroupFilter',
  IS_FULL_SCREEN = 'isFullScreen',
  FULL_SCREEN_WIDGET = 'fullScreenWidget'
}

export enum TREND_DATE_RANGE {
  ONE_DAY = '1day',
  ONE_WEEK = '1week',
  ONE_MONTH = '1month',
  THREE_MONTHS = '3months',
  SIX_MONTHS = '6months'
}

export enum UserGroupFilterType {
  TRUE = 'true',
  FALSE = 'false'
}

export enum FULL_SCREEN_WIDGETS {
  SENSOR_LIST = 'sensorList',
  IMAGES = 'images',
  TRENDS = 'trends',
  ALARMS = 'alarms',
  ANOMALY_DEFINITIONS = 'anomalyDefinitions'
}

export type TrendDateRangeType = `${TREND_DATE_RANGE}`;
export type UserGroupFilter = `${UserGroupFilterType}`;
export type FullScreenWidget = `${FULL_SCREEN_WIDGETS}`;

type NewAssetDashboardUrlParams = {
  selectedPowertrainPath: string | null;
  selectedPowertrainName: string | null;
  trendsDatapoints: string | null;
  selectedSeverity: AlertLevel | '';
  trendChartHeight: string | null;
  trendDateRange: TrendDateRangeType | '';
  selectedGroupPath: string | null;
  userGroupFilter: UserGroupFilter | null;
  /**
   * The widget that is opened in full screen.
   */
  fullScreenWidget: FullScreenWidget | null;
};

const useGetNewAssetDashboardUrlParams = (): NewAssetDashboardUrlParams => {
  const location = useLocation();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedPowertrainPath = searchParams.get(
      NEW_ASSET_DASHBOARD_URL_PARAMS.SELECTED_POWERTRAIN_PATH
    );
    const selectedPowertrainName = searchParams.get(
      NEW_ASSET_DASHBOARD_URL_PARAMS.SELECTED_POWERTRAIN_NAME
    );
    const trendsDatapoints = searchParams.get(NEW_ASSET_DASHBOARD_URL_PARAMS.TRENDS_DATAPOINTS);
    const selectedSeverity = searchParams.get(NEW_ASSET_DASHBOARD_URL_PARAMS.SELECTED_SEVERITY) as
      | AlertLevel
      | '';
    const trendChartHeight = searchParams.get(NEW_ASSET_DASHBOARD_URL_PARAMS.TREND_CHART_HEIGHT);
    const trendDateRange = searchParams.get(NEW_ASSET_DASHBOARD_URL_PARAMS.TREND_DATERANGE) as
      | TrendDateRangeType
      | '';
    const selectedGroupPath = searchParams.get(NEW_ASSET_DASHBOARD_URL_PARAMS.SELECTED_GROUP_PATH);
    const userGroupFilter = searchParams.get(
      NEW_ASSET_DASHBOARD_URL_PARAMS.USER_GROUP_FILTER
    ) as UserGroupFilter | null;
    const fullScreenWidget = searchParams.get(
      NEW_ASSET_DASHBOARD_URL_PARAMS.FULL_SCREEN_WIDGET
    ) as FullScreenWidget | null;

    return {
      selectedPowertrainPath,
      selectedPowertrainName,
      trendsDatapoints,
      selectedSeverity,
      trendChartHeight,
      trendDateRange,
      selectedGroupPath,
      userGroupFilter,
      fullScreenWidget
    };
  }, [location.search]);

  return params;
};

export default useGetNewAssetDashboardUrlParams;
