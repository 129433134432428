/**
 * Verification Content
 * @author mahesh.kedari@shorelineiot.com
 * Updated by Swapnil
 */
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useNavigate, Navigate } from 'react-router';
import { SLSubmit, SLLinkButton } from '../../../../framework';
import { useStyles } from './verification.style';
import { VerificationLinkIcon } from '../../../../framework/icons';
import { registerActions, verificationActions, useSignupState } from '../store';
import TranslateText from '../../../../i18n/TranslateText';

function VerificationContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectToLogin = () => {
    dispatch(registerActions.resetToRegister());
    return <Navigate to="/auth/login" replace />;
  };

  // dispatch(registerActions.onVerificationPageLoad());

  let email = '';
  try {
    const signupState = useSignupState();
    email = signupState.signup;
    if (!email || email.length === 0) {
      throw new Error('Email Not Available');
    }
  } catch (error: any) {
    navigate('/auth/login');
    email = '';
  }
  const handleResend = () => {
    dispatch(verificationActions.sendLink(email));
  };

  return (
    <div className={classes.root}>
      <Box textAlign="center">
        <Typography variant="subtitle1">
          We have sent you the verification mail to{' '}
          <a href={`mailto:${email}`} target="_top">
            {email}
          </a>
          {'. '}
          Please activate your account with the link in this mail. If you cannot find the mail,
          please also check the junk/spam folder.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={5}>
        <VerificationLinkIcon className={classes.verificationIcon} />
      </Box>
      <Box textAlign="center" className={classes.boxStyle}>
        <Typography variant="h3">If you have not received the verification email</Typography>
        <SLSubmit
          dataTestId="resend-verification-button"
          type="button"
          className={classes.centerButton}
          onClick={handleResend}>
          Resend
        </SLSubmit>
      </Box>

      <Box display="flex" justifyContent="space-between" className={classes.formStyle}>
        <Typography variant="body1">
          If registered ,
          <SLLinkButton
            type="button"
            variant="text"
            color="secondary"
            onClick={() => redirectToLogin()}>
            <Typography variant="button"> {TranslateText('log_in', 'Log In')}</Typography>
          </SLLinkButton>
        </Typography>
      </Box>
    </div>
  );
}

export default VerificationContent;
