import { AlertLevel } from '../../../../alarms/store';
import { Device } from '../../../../device';

/**
 * VA Utilities Function
 * @author mahesh.kedari@shorelineiot.com
 */
export const COLOR_GOOD = '#009E01'; // Green
export const COLOR_ERROR = '#DE0102'; // RED
export const COLOR_WARNING = '#FFAC00'; // Orange
export const COLOR_FATAL = '#FF00FF'; // Pink
export const COLOR_LOW = '#FFFF00'; // Yellow
export const COLOR_BLUE = '#3134D7'; // Blue

export const getAlertTypesColors = (alertType: string) => {
  switch (alertType) {
    case 'alert_error':
      return COLOR_ERROR;
    case 'alert_fatal':
      return COLOR_FATAL;
    case 'alert_low':
      return COLOR_LOW;
    case 'alert_warn':
      return COLOR_WARNING;
    case 'good':
      return COLOR_GOOD;
    default:
      return COLOR_BLUE;
  }
};

export const getColorForAlertBasedOnState = (narrowband: any) => {
  const alertState: AlertLevel = narrowband?.alert_state;
  switch (alertState) {
    case 'ALERT_WARNING':
      return COLOR_WARNING;
    case 'ALERT_LOW':
      return COLOR_LOW;
    case 'ALERT_ERROR':
      return COLOR_ERROR;
    case 'ALERT_FATAL':
      return COLOR_FATAL;
    default:
      return COLOR_GOOD;
  }
};

export const getColorForAlertBasedOnStatus = (narrowband: any) => {
  const alertStatus = narrowband?.alert_status;
  if (alertStatus === 'TRUE_POSITIVE' || alertStatus === 'ALERT_NULLIFIED') {
    return getColorForAlertBasedOnState(narrowband);
  }
  return COLOR_GOOD;
};
export const showAnomaly = (alertState?: AlertLevel) => {
  switch (alertState) {
    case 'ALERT_WARNING':
    case 'ALERT_ERROR':
    case 'ALERT_LOW':
    case 'ALERT_FATAL':
      return true;
    default:
      return false;
  }
};

export const scientificToDecimal = (inputNum: any) => {
  let num = inputNum;
  const nsign = Math.sign(num);
  // remove the sign
  num = Math.abs(num);
  // if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(num)) {
    const zero = '0';
    const parts = String(num).toLowerCase().split('e'); // split into coeff and exponent
    const e = parseInt(parts.pop() || '0', 10); // store the exponential part
    let l = Math.abs(e); // get the number of zeros
    const sign = e / l;
    const coeffArray = parts[0].split('.');
    if (sign === -1) {
      l -= coeffArray[0].length;
      if (l < 0) {
        num = `${coeffArray[0].slice(0, l)}.${coeffArray[0].slice(l)}${
          coeffArray.length === 2 ? coeffArray[1] : ''
        }`;
      } else {
        num = `${zero}.${new Array(l + 1).join(zero)}${coeffArray.join('')}`;
      }
    } else {
      const dec = coeffArray[1];
      if (dec) l -= dec.length;
      if (l < 0) {
        num = `${coeffArray[0] + dec.slice(0, l)}.${dec.slice(l)}`;
      } else {
        num = coeffArray.join('') + new Array(l + 1).join(zero);
      }
    }
  }

  return nsign < 0 ? `-${num}` : num;
};

export const getMeasurementSystem = (unitsList: string[]) => {
  return unitsList?.some((unit) => unit?.toLowerCase()?.includes('inches')) ? 'imperial' : 'metric';
};
/**
 * Get datapoints list and device list and prepare a map for displaying in Tree dropdown structure
 * @param devicesWithDatapoints - All the datapoints of all the devices in current organization
 * @param devices - List of available devices
 * @param type - True for only vibration analysis related datapoints. False for all the datapoints
 * @returns
 */
export function getDataPointsParsedList(
  devicesWithDatapoints: any,
  devices: Device[],
  type = false // checking if va=false or true
) {
  const datapointsParsedList: Array<any> = [];
  devicesWithDatapoints.forEach(
    (deviceWithDatapoints: { device_id: number | string; datapoints: Array<any> }) => {
      // only getting provisioned devices
      const currentDevice = devices.find(
        (device) =>
          device.id === deviceWithDatapoints.device_id &&
          (device.provisioning_status === 'PROVISIONED' ||
            device.provisioning_status === 'DE-PROVISIONED')
      );
      if (currentDevice) {
        const deviceRecord = {
          raw_dpid: '',
          raw_dpname: '',
          deviceId: currentDevice.id,
          deviceName: currentDevice.name,
          key: currentDevice.id,
          displayName: currentDevice.name,
          dropdownName: currentDevice.name
        };
        datapointsParsedList.push(deviceRecord);
        const parsedDatapoints: Array<any> = [];
        const { datapoints } = deviceWithDatapoints;
        datapoints.forEach((datapoint: any) => {
          const displayName =
            type === false ? datapoint.dp_name : datapoint.dp_name.replace(/[0-9]/g, '');
          const alreadyAddedIndex = parsedDatapoints.findIndex(
            (dp: any) => dp.displayName === displayName
          );
          if (~alreadyAddedIndex) {
            const otherDpids = [
              ...parsedDatapoints[alreadyAddedIndex].otherDpids,
              {
                dpid: datapoint.dpid,
                odr: datapoint.odr,
                sensorName: datapoint.dp_name
              }
            ];
            parsedDatapoints[alreadyAddedIndex] = {
              ...parsedDatapoints[alreadyAddedIndex],
              otherDpids
            };
          } else {
            const sensorObj = {
              raw_dpid: datapoint.dpid,
              raw_dpname:
                type === false ? datapoint.dp_name : datapoint.dp_name.replace(/[0-9]/g, ''),
              deviceId: currentDevice.id,
              deviceName: currentDevice.name,
              key: `${currentDevice.id}_${datapoint.dpid}`,
              unit: datapoint.unit,
              parentId: currentDevice.id,
              displayName:
                type === false ? datapoint.dp_name : datapoint.dp_name.replace(/[0-9]/g, ''),
              dropdownName: `${currentDevice.name} ${datapoint.dp_name.replace(/[0-9]/g, '')}`,
              datapoint_type: datapoint.datapoint_type,
              sensor_type: datapoint.sensor_type,
              otherDpids: [
                {
                  dpid: datapoint.dpid,
                  odr: datapoint.odr,
                  sensorName: datapoint.dp_name
                }
              ],
              datatype: datapoint.datatype
            };
            parsedDatapoints.push(sensorObj);
          }
        });
        datapointsParsedList.push(...parsedDatapoints);
      }
    }
  );
  return datapointsParsedList;
}
