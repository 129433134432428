/**
 * New Item Card Component
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactElement, PropsWithChildren, useImperativeHandle } from 'react';
import { Card, CardContent, Box, CardProps } from '@mui/material';
import addProfileIcon from '../../icons/addProfile.svg';
import { useStyles } from './card.styles';
import SLRightDrawer from '../right-drawer/RightDrawer';
/**
 *
 */
interface Props {
  label: string;
  drawerTitle?: string;
}
/**
 *
 * @param param0
 */
const SLDrawerCard = React.forwardRef<any, PropsWithChildren<Props>>(
  ({ label, drawerTitle, children }: PropsWithChildren<Props>, ref: any): ReactElement => {
    const classes = useStyles();
    const drawerRef = React.useRef();

    /**
     * Allow closing of drawer from parent component
     */

    useImperativeHandle(ref, () => ({
      handleClose() {
        const instance: any = drawerRef.current;
        instance?.handleCloseDrawer();
      }
    }));
    const cardComponent = (props: CardProps) => (
      <Card className={classes.card} {...props}>
        <CardContent /* {...getRootProps()} */>
          {/* <input {...getInputProps()} /> */}
          <Box display="flex" alignItems="center">
            <img alt={label} src={addProfileIcon} className={classes.image} />
            <Box>{label}</Box>
          </Box>
        </CardContent>
      </Card>
    );

    return (
      <SLRightDrawer
        component={cardComponent}
        label={label}
        drawerTitle={drawerTitle || label}
        ref={drawerRef}>
        {children}
      </SLRightDrawer>
    );
  }
);

export default SLDrawerCard;
