const TermsAndConditions = () => {
  return (
    <div style={{ padding: 20 }}>
      <span>Shoreline AI</span>
      <br />
      <span>Terms and Conditions of Use</span>
      <br />
      <span>Last updated: June 21, 2024</span>
      <br />
      <p>
        Please read these Terms &amp; Conditions for use of the Services (the “Agreement”)
        carefully. Your use of the Services (as defined below) constitutes your consent to this
        Agreement. This Agreement is between you and Shoreline AI (the “Company”) concerning your
        use of (including any access to) (a) the Company’s site currently located at&nbsp;
        <a href="https://www.shorelineai.us/" target="_blank" rel="noreferrer">
          https://www.shorelineai.us/
        </a>
        &nbsp; (together with any materials and services available therein, and any successor
        site(s) thereto, the “Web App”), (b) the Company’s mobile software applications (together
        with any materials and services available therein, and any successor application(s) thereto,
        the “Mobile Apps”), (c) the Company’s iCast Product Family (the “Device”) and (d) the
        software embedded in the Device (together with any updates thereto, the “Device Software”).
        The Web App and the Mobile Apps may be referred to individually as an “App” and collectively
        as the “Apps”. The Apps, the Device, and the Device Software may be collectively referred to
        as the “Services”. This Agreement hereby incorporates by this reference any additional terms
        and conditions posted by the Company through the Apps, or otherwise made available to you by
        the Company. Your use of the Services is governed by this Agreement regardless of how you
        access or use the Services, including through the Internet, through Wireless Access Protocol
        (commonly referred to as “WAP”), through a mobile network or otherwise. By using the
        Services, you affirm that you are of legal age to enter into this Agreement. If you elect
        not to accept the terms of this Agreement, you should not use the Services.
      </p>

      <ol type="1">
        <li>
          Changes. The Company may change this Agreement by notifying you of such changes by any
          reasonable means, including by posting a revised Agreement through the Apps. Any such
          changes will not apply to any dispute between you and us arising prior to the date on
          which the Company posted the revised Agreement incorporating such changes, or otherwise
          notified you of such changes.
          <br />
          Your use of the Services following any changes to this Agreement will constitute your
          acceptance of such changes. The “Last Updated” legend above indicates when this Agreement
          was last changed. The Company may, at any time and without liability, modify or
          discontinue all or part of the Services (including access to the Apps via any third-party
          links); charge, modify or waive any fees required to use the Apps; or offer opportunities
          to some or all of the Services’ users.
          <br />
          To improve the performance of the Services, the Company may develop patches, bug fixes,
          updates, upgrades, releases, additional functionalities and other modifications
          (“Updates”). Any future Update (if any) will be subject to the terms of this Agreement.
          You may be required to install Updates to use the Services and you agree to promptly
          install any Updates the Company provides. The Updates may also be automatically installed
          without providing any additional notice or receiving any additional consent or action from
          you, and you consent to the automatic update of the Apps and Device Software. If you do
          not want such Updates, your sole remedy is to cease using the Company’s Apps and Device
          Software altogether.
        </li>

        <li>
          Information &amp; Data collected in Connection with the Services. Your submission of
          information in connection with the Services is governed by the Company’s Privacy Policy,
          located at&nbsp;
          <a href="https://www.shorelineai.us/privacy-policy" target="_blank" rel="noreferrer">
            https://www.shorelineai.us/privacy-policy
          </a>
          &nbsp; (the “Privacy Policy”). You represent and warrant that any information you provide
          in connection with the Services is and will remain accurate and complete, and that you
          will maintain and update such information as needed.
          <ol type="i">
            <li>
              Use of Data. As between you and Company, you have sole control over Data provided or
              transmitted to Company and shall be the exclusive owner of such Data. You grant to
              Company a worldwide, non-exclusive, royalty-free, fully paid up, and irrevocable right
              and license to use, store, process, modify, reproduce, distribute and display the
              Data, and to transfer Data to third parties, for the purpose of providing the Services
              or enhancements thereto, performing its obligations hereunder and complying with
              applicable law. Company may distribute, disclose or otherwise use anonymized Data.
              Anonymized Data means any Data obtained directly or indirectly by Company, which: (i)
              does not identify an individual; and (ii) is not attributed to you.
            </li>

            <li>
              Consents. You represent and warrant that: (a) you have complied with applicable law,
              including privacy and data protection laws of the territories from which any Data is
              obtained, in providing and transmitting any Data to Company; and (b) prior to
              transmitting any Data, you have obtained all applicable consents and approvals
              required for the collection, transmission, use and processing of Data.
            </li>

            <li>
              Report of any Security Breach. If either party becomes aware of any unauthorized or
              unlawful access to, or acquisition, alteration, use, disclosure, or destruction of
              Data, such party will notify the other party without undue delay. You will immediately
              notify Company of any such incident or vulnerability through&nbsp;
              <a href="mailto:support@shorelineai.us">support@shorelineai.us</a>. Each party will
              also reasonably cooperate with the other party with respect to any investigations and
              with preparing any required notices, use reasonable efforts to identify and remediate
              the cause of the Data breach and provide any information reasonably requested by the
              other party in relation to the unauthorized access.
            </li>

            <li>
              Information Security Programs. Each party will maintain a written information security
              program that contains administrative, technical and physical safeguards to protect
              against anticipated threats or hazards to the security, confidentiality or integrity
              of, the unauthorized or accidental destruction, loss, alteration of, and the
              unauthorized access to or acquisition of Data. Each such information security program
              will be consistent with applicable laws and regulations. Company&apos;s certifications
              pertaining to data security are available upon written request.
            </li>
          </ol>
        </li>

        <li>
          Jurisdictional Issues. The Apps and Device Software are controlled or operated (or both)
          from the United States, and are not intended to subject the Company to any non-U.S.
          jurisdiction or law. The Services may not be appropriate or available for use in some
          non-U.S. jurisdictions. Any use of the Services is at your own risk, and you must comply
          with all applicable laws, rules and regulations in doing so. The Company may limit the
          availability of the Apps and Device Software at any time, in whole or in part, to any
          person, geographic area or jurisdiction that the Company chooses.
        </li>

        <li>
          Notifications and Uptime Guarantee. The Company cannot and does not guarantee that you
          will receive notifications from the Service within a certain time or at all. While the
          Company aims for the Services to be highly reliable and available, the Company does not
          offer any specific uptime guarantee for the Services and the Services are not intended to
          be reliable or available 100% of the time. The Apps and Device Software may be suspended
          temporarily without notice for security reasons, system failure, maintenance and repair,
          or other circumstances. The Services are subject to sporadic interruptions and failures
          for a variety of reasons beyond the Company’s control, including issues related to
          electrical or battery failure, wireless connectivity and service provider and carrier
          uptime. You will not be entitled to any refund or rebate for such suspensions,
          interruptions or failures. The Company is not responsible for (and you are responsible
          for) any damages allegedly caused by the failure or delay of the Apps or Device Software
          to reflect current status or notifications.
          <br />
          <br />
          The Apps and Device Software will not be accessible without: (i) always-on broadband
          Internet access and a working Wi-Fi network &/or a cellular connectivity in your premises
          that communicates reliably with the Company’s products; (ii) mobile clients such as a
          supported phone or tablet; and (iii) other hardware or software. It is your responsibility
          to ensure that you have all required hardware and software and that such hardware and
          software are compatible and properly configured. The Services may not work as described
          when the requirements and compatibility have not been met. You are responsible for all
          fees charged by the providers of such hardware or software in connection with your use of
          the Services.
        </li>

        <li>
          Rules of Conduct. In connection with the Services, you must not:
          <br />
          <ul>
            <li>
              Post, transmit or otherwise make available through or in connection with the Services
              any materials that are or may be: (a) threatening, harassing, degrading, hateful or
              intimidating, or otherwise fail to respect the rights and dignity of others; (b)
              defamatory, libellous, fraudulent or otherwise tortious; (c) obscene, indecent,
              pornographic or otherwise objectionable; or (d) protected by copyright, trademark,
              trade secret, right of publicity or privacy or any other proprietary right, without
              the express prior written consent of the applicable owner.
            </li>

            <li>
              Post, transmit or otherwise make available through or in connection with the Services
              any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code,
              file or program that is or is potentially harmful or invasive or intended to damage or
              hijack the operation of, or to monitor the use of, any hardware, software or
              equipment.
            </li>

            <li>
              Use the Services for any purpose that is fraudulent or otherwise tortious or unlawful.
            </li>

            <li>Harvest or collect information about users of the Services.</li>

            <li>
              Interfere with or disrupt the operation of the Services or the servers or networks
              used to make the Services available, including by hacking or defacing any portion of
              the Services; or violate any requirement, procedure or policy of such servers or
              networks.
            </li>

            <li>Restrict or inhibit any other person from using the Services.</li>

            <li>
              Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease,
              loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the
              Services except as expressly authorized herein, without the Company’s express prior
              written consent.
            </li>

            <li>
              Reverse engineer, decompile or disassemble any portion of the Services, except where
              such restriction is expressly prohibited by applicable law.
            </li>

            <li>
              Remove any copyright, trademark or other proprietary rights notice from the Services.
            </li>

            <li>
              Frame or mirror any portion of the Services, or otherwise incorporate any portion of
              the Services into any product or service, without the Company’s express prior written
              consent.
            </li>

            <li>Systematically download and store the Apps’ content.</li>

            <li>
              Use any robot, spider, site search/retrieval application or other manual or automatic
              device to retrieve, index, &quot;scrape,&quot; &quot;data mine&quot; or otherwise
              gather the Apps’ content, or reproduce or circumvent the navigational structure or
              presentation of the Apps, without the Company’s express prior written consent.
              Notwithstanding the foregoing, and subject to compliance with any instructions posted
              in the robots.txt file located in the Web App’s root directory, the Company grants to
              the operators of public search engines permission to use spiders to copy materials
              from the Web App for the sole purpose of (and solely to the extent necessary for)
              creating publicly available, searchable indices of such materials, but not caches or
              archives of such materials. The Company reserves the right to revoke such permission
              either generally or in specific cases, at any time and without notice.
            </li>
          </ul>
          You are solely responsible for restricting access to your computer(s) or mobile device(s)
          that can monitor and control the Device in your own property. You are responsible for
          obtaining, maintaining and paying for all hardware and all telecommunications and other
          services needed to use the Services.
        </li>

        <li>
          Products. It is your responsibility to ascertain and obey all applicable local, state,
          federal and foreign laws (including minimum age requirements) regarding the possession,
          use and sale of any Product.
        </li>

        <li>
          Transactions. The Company may make available the ability to subscribe to certain Products
          through the Apps (a “Transaction”). If you wish to make a Transaction, you may be asked to
          supply certain relevant information, such as your credit card number and its expiration
          date and your billing address. You represent and warrant that you have the right to use
          any credit card that you submit in connection with a Transaction. By submitting such
          information, you grant to us the right to provide such information to third parties for
          purposes of facilitating Transactions. Verification of information may be required prior
          to the acknowledgment or completion of any Transaction. By making a Transaction, you
          represent that the applicable Products will be used only in a lawful manner. <br />
          The Company reserves the right, including without prior notice, to discontinue making
          available any Product; to impose conditions on the honoring of any discount or similar
          promotion; to bar any user from making any Transaction; and to refuse to provide any user
          with any Product. You agree to pay all charges incurred by you or on your behalf through
          the Apps, at the prices in effect when such charges are incurred, including all shipping
          and handling charges. In addition, you are responsible for any taxes applicable to your
          Transactions.
        </li>

        <li>
          Registration; User Names and Passwords. You may need to register to use all or part of the
          Apps and Device Software. The Company may reject, or require that you change, any user
          name, password or other information that you provide to us in registering. Your user name
          and password are for your personal use only and should be kept confidential; you, and not
          the Company, are responsible for any use or misuse of your user name or password, and you
          must promptly notify us of any confidentiality breach or unauthorized use of your user
          name or password, or your App account. The Company reserves the right to limit the number
          of App accounts that can be created for use with a Device and the number of Devices that
          can be associated with an App account.
        </li>

        <li>
          Profiles and Forums. App visitors may make available certain materials (each, a
          “Submission”) through or in connection with the Services, including on profile pages or on
          the Apps’ interactive services, such as message boards and other forums, and chatting,
          commenting and other messaging functionality. The Company has no control over and is not
          responsible for any use or misuse (including any distribution) by any third party of
          Submissions. If you choose to make any of your personally identifiable or other
          information publicly available through or in connection with the Services, you do so at
          your own risk.
        </li>

        <li>
          License. For purposes of clarity, you retain ownership of your Submissions. For each
          Submission, you hereby grant to us a worldwide, royalty-free, fully paid-up,
          non-exclusive, perpetual, irrevocable, transferable and fully sub-licensable (through
          multiple tiers) license, without additional consideration to you or any third party, to
          reproduce, distribute, perform and display (publicly or otherwise), create derivative
          works of, adapt, modify and otherwise use and exploit such Submission, in any format or
          media now known or hereafter developed, and for any purpose (including promotional
          purposes, such as testimonials). <br />
          <br />
          In addition, if you provide to us any ideas, proposals, suggestions or other materials
          (“Feedback”), whether related to the Services or otherwise, such Feedback will be deemed a
          Submission, and you hereby acknowledge and agree that such Feedback is not confidential,
          and that your provision of such Feedback is gratuitous, unsolicited and without
          restriction, and does not place the Company under any fiduciary or other obligation. You
          represent and warrant that you have all rights necessary to grant the licenses granted in
          this section, and that your Submissions, and your provision thereof through and in
          connection with the Services, are complete and accurate, and are not fraudulent, tortious
          or otherwise in violation of any applicable law or any right of any third party. You
          further irrevocably waive any “moral rights” or other rights with respect to attribution
          of authorship or integrity of materials regarding each Submission that you may have under
          any applicable law under any legal theory.
        </li>

        <li>
          Monitoring. The Company may (but has no obligation to) monitor, evaluate, alter or remove
          Submissions before or after they appear on the Apps. The Company may disclose any
          Submissions and the circumstances surrounding their transmission to anyone for any reason
          or purpose.
        </li>

        <li>
          Proprietary Rights. The Company and its suppliers own the Apps and the Device Software,
          which are protected by proprietary rights and laws. Subject to your compliance with this
          Agreement, and solely for so long as you are permitted by the Company to use the Web App,
          you may view one (1) copy of any portion of the Web App to which the Company provides you
          access hereunder, on any single device, solely for your personal, non-commercial use.
          <br />
          <br />
          The Mobile App and the Device Software are licensed (not sold) to end users. Subject to
          your compliance with this Agreement, and solely for so long as you are permitted by the
          Company to use the Mobile App, the Company hereby permits you, on a limited,
          non-exclusive, revocable, non-transferable, non-sub-licensable basis, to (a) install and
          use the Mobile App on a mobile device that you own or control and (b) use the Device
          Software for monitoring and controlling the Device installed in your own property. If you
          fail to comply with any of the terms or conditions of this Agreement, you must immediately
          cease using the Apps and Device Software and remove (that is, uninstall and delete) the
          Mobile App from your mobile device.
          <br />
          <br />
          The Apps and Device Software may contain certain open source and third-party software
          components, including without limitation, the components listed at (collectively,
          “Third-Party Components”). Each Third-Party Component is licensed either under the terms
          of this Agreement, or if specified in the software documentation under the Third-Party
          Component’s applicable license agreement (“Third-Party License”). Nothing in the Agreement
          is intended to modify the user’s rights or obligations with respect to any Third-Party
          Component set forth in the applicable Third Party License. <br />
          <br />
          Any fees charged by the Company in connection with the Apps or Device Software do not
          apply to any Third-Party Components for which fees may not be charged under the applicable
          Third-Party License. To the extent that this Agreement contains any warranty, support,
          indemnity or liability terms or any other terms and conditions not included in the
          applicable Third-Party License, these terms and conditions are not offered by or made on
          behalf of any third party author, developer, or contributor of the applicable Third-Party
          Components. Where the terms of any specific Third-Party License entitle a licensee to the
          source code of the applicable Third Party Component, that source code is available from
          the Company upon request at&nbsp;
          <a href="mailto:info@shorelineai.us">info@shorelineai.us</a>. A nominal fee may be charged
          by the Company for processing such request.
          <br />
          <br />
          Our trade names, trademarks and service marks include Shoreline AI, iCast1and any
          associated logos. All trade names, trademarks, service marks and logos on the Services not
          owned by us are the property of their respective owners. You may not use the Company’s
          trade names, trademarks, service marks or logos in connection with any product or service
          that is not ours, or in any manner that is likely to cause confusion. Nothing contained in
          this Agreement or elsewhere in the Services should be construed as granting any right to
          use any trade names, trademarks, service marks or logos without the express prior written
          consent of the owner.
        </li>

        <li>
          Third Party Materials; Links. Certain Services’ functionality may make available access
          to, interface with or rely upon applications, products, services, information or materials
          made available by third parties, including Submissions (“Third Party Materials”), or allow
          for the routing or transmission of such Third Party Materials, including via links. By
          using such functionality, you are directing us to access, route and transmit to you the
          applicable Third Party Materials. <br />
          <br />
          The Company neither controls nor endorses, nor is the Company responsible for, any Third
          Party Materials, including the accuracy, integrity, quality, legality, usefulness or
          safety of Third Party Materials, or any intellectual property rights therein. Certain
          Third Party Materials may, among other things, be inaccurate, misleading or deceptive.
          Nothing in this Agreement shall be deemed to be a representation or warranty by the
          Company with respect to any Third Party Materials. The Company no obligation to monitor
          Third Party Materials, and the Company may block or disable access to any Third Party
          Materials (in whole or part) through the Services at any time. In addition, the
          availability of any Third Party Materials through the Services does not imply the
          Company’s endorsement of, or the Company’s affiliation with, any provider of such Third
          Party Materials, nor does such availability create any legal relationship between you and
          any such provider.
          <br />
          <br />
          Your use of Third Party Materials is at your own risk and is subject to any additional
          terms, conditions and policies applicable to such Third Party Materials (such as terms of
          service or privacy policies of the providers of such Third Party Materials).
        </li>

        <li>
          Promotions. Any sweepstakes, contests, raffles, surveys, games or similar promotions
          (collectively, “Promotions”) made available through or in connection with the Services may
          be governed by rules that are separate from this Agreement. If you participate in any
          Promotions, please review the applicable rules as well as the Company’s Privacy Policy. If
          the rules for a Promotion conflict with this Agreement, the Promotion rules will govern.
        </li>

        <li>
          Disclaimer of Warranties. The Apps, Device Software, Products and Third Party Materials
          are made available to you “AS IS” without any warranties of any kind, whether express,
          implied or statutory. The Company disclaims all warranties with respect to the Apps,
          Device Software, Products and Third Party Materials to the fullest extent permissible
          under applicable law, including the warranties of merchantability, fitness for a
          particular purpose, non-infringement and title. All disclaimers of any kind (including in
          this section and elsewhere in this Agreement) are made on behalf of both the Company and
          its affiliates and their respective shareholders, directors, officers, employees,
          affiliates, agents, representatives, licensors, suppliers and service providers
          (collectively, the “Affiliated Entities”). While the Company tries to maintain the
          timeliness, integrity and security of the Apps and Device Software, the Company does not
          guarantee that the Apps or Device Software are or will remain updated, complete, correct
          or secure, or that access to the Apps or Device Software will be uninterrupted. The Apps
          or Device Software may include inaccuracies, errors and materials that violate or conflict
          with this Agreement. Additionally, third parties may make unauthorized alterations to the
          Apps or Device Software. If you become aware of any such alteration, contact us at&nbsp;
          <a href="mailto:legal@shorelineai.us">legal@shorelineai.us</a> &nbsp;with a description of
          such alteration and its location in the Apps or Device Software.
        </li>

        <li>
          Limitation of Liability. The Company will not be liable for any indirect, incidental,
          consequential, special, exemplary or punitive damages of any kind, under any contract,
          tort (including negligence), strict liability or other theory, including damages for loss
          of profits, use or data, loss of other intangibles, loss of security of Submissions
          (including unauthorized interception by third parties of any Submissions), even if advised
          in advance of the possibility of such damages or losses. Without limiting the foregoing,
          the Company will not be liable for damages of any kind resulting from your use of or
          inability to use the Services or from any Products or Third Party Materials. Your sole and
          exclusive remedy for dissatisfaction with the Services, Products or Third Party Materials
          is to stop using such Services, Products or Third Party Materials. The maximum aggregate
          liability of the Company for all damages, losses and causes of action, whether in
          contract, tort (including negligence) or otherwise, shall be the greater of the total
          amount, if any, paid by you to the Company to use the Services and ten U.S. dollars
          ($10.00). All limitations of liability of any kind (including in this section and
          elsewhere in this Agreement) are made on behalf of both the Company and the Affiliated
          Entities. <br />
          <br />
          Applicable law may not allow for limitations on certain implied warranties, or exclusions
          or limitations of certain damages; solely to the extent that such law applies to you, some
          or all of the above disclaimers, exclusions or limitations may not apply to you, and you
          may have certain additional rights.
        </li>

        <li>
          Indemnity. Except to the extent prohibited under applicable law, you agree to defend,
          indemnify and hold harmless the Company and the Affiliated Entities from and against all
          claims, losses, costs and expenses (including attorneys’ fees) arising out of (a) your use
          of, or activities in connection with, the Services (including all Submissions) or any
          personal injury, death, property damage (including to your home), or other harm or losses
          arising from or relating to your use of the Services; and (b) any violation or alleged
          violation of this Agreement by you.
          <ul>
            <li>
              <b>By Company</b>. Subject to the procedural obligations specified below, Company
              shall: (a) defend any claim brought against you by an unaffiliated third party
              alleging that your authorized use and access of the Service infringes or
              misappropriates such third party&apos;s trade secrets, copyrights, U.S. patents, or
              trademarks in the cloud service location(s) where you acquired the Product; and (b)
              shall indemnify you from any liability, costs and damages finally awarded for such
              claim by a court of competent jurisdiction, or settled by the relevant parties. If
              such a claim occurs or is likely to occur, Company may, at its option and expense: (x)
              procure the right to allow you to continue using the Service (or infringing portions
              thereof); or (y) modify or replace the Service (or infringing portions thereof) to
              become non-infringing; or (z) if neither of the foregoing options is commercially
              feasible, terminate your right to use the affected portion of the Service and refund,
              on a pro-rata basis, any unused portion of the fees paid by you for such Service.
              Company shall have no obligations under this section or otherwise with respect to any
              infringement claim to the extent such claim is based upon or arises out of: (i) any
              modification or alteration of the Service, including any changes in the source code
              unless such changes were made by Company; (ii) any unauthorized access or use of the
              Service or any breach hereunder by you; (iii) any combination or use of the Service
              with other software, products, equipment, methods or services not provided by Company
              that results in such infringement; (iv) the Third Party Materials; (v) Your failure to
              use or implement corrected or replacement Service (or portions thereof) that would
              have avoided or mitigated the alleged infringement; (vi) Your continuance of allegedly
              infringing activity after being notified thereof; or (vii) any specifications or
              requirements supplied by you.
            </li>

            <li>
              <b>By You</b>. Subject to the procedural obligations specified below, You shall: (a)
              defend any claim brought against Company by an unaffiliated third party alleging that
              any device, service or product made available, sold or distributed by you, your
              resellers or distributors, or used by you in connection with the Service caused or
              contributed to bodily injury, death or property damage or infringes or misappropriates
              such third party&apos;s trade secrets, copyrights, patents, trademarks or other
              intellectual property rights; and (b) indemnify Company from liability, costs and
              damages finally awarded for such claim by a court of competent jurisdiction, or
              settled by the relevant parties. In addition, you shall indemnify Company from any
              liabilities, costs and damages arising from your breach of any representation,
              warranty, covenant or agreement hereunder, including without limitation any breach of
              relevant Sections.
            </li>

            <li>
              <b>Procedure</b>. The party seeking indemnification will: (a) provide notice of the
              claim to the indemnifying party promptly after becoming aware of such claim; (b)
              provide reasonable assistance to the indemnifying party; and (c) tender control of the
              defence to the indemnifying party.
            </li>
          </ul>
        </li>

        <li>
          Termination. This Agreement is effective until terminated. The Company may terminate or
          suspend your use of the Apps or Device Software at any time and without prior notice,
          including if the Company believes that you have violated or acted inconsistently with the
          letter or spirit of this Agreement. Upon any such termination or suspension, your right to
          use the Apps or Device Software will immediately cease, and the Company may, without
          liability to you or any third party, immediately deactivate or delete your user name,
          password and account, and all associated materials, without any obligation to provide any
          further access to such materials. Sections 2–6, 8–11 and 13–25 shall survive any
          expiration or termination of this Agreement.
        </li>

        <li>
          Governing Law; Arbitration. The terms of this Agreement are governed by the laws of the
          State of California, U.S.A., without regard to its principles of conflicts of law, and
          regardless of your location. Except for disputes that qualify for small claims court, all
          disputes arising out of or related to this Agreement or any aspect of the relationship
          between you and the Company, whether based in contract, tort, statute, fraud,
          misrepresentation or any other legal theory, will be resolved through final and binding
          arbitration before a neutral arbitrator instead of in a court by a judge or jury and you
          agree that the Company and you are each waiving the right to trial by a jury. You agree
          that any arbitration under this Agreement will take place on an individual basis; class
          arbitrations and class actions are not permitted and you are agreeing to give up the
          ability to participate in a class action. The arbitration will be administered by the
          American Arbitration Association (“AAA”) under its Commercial Arbitration Rules and
          Supplementary Procedures for Consumer-Related Disputes (“Supplementary Procedures”),
          as&nbsp; amended by this Agreement. The Supplementary Procedures are available online
          at&nbsp;
          <a href="http://www.adr.org/" target="_blank" rel="noreferrer">
            http://www.adr.org
          </a>
          . The arbitrator will conduct hearings, if any, by teleconference or videoconference,
          rather than by personal appearances, unless the arbitrator determines upon request by you
          or by us that an in-person hearing is appropriate. Any in-person appearances will be held
          at a location which is reasonably convenient to both parties with due consideration of
          their ability to travel and other pertinent circumstances. If the parties are unable to
          agree on a location, such determination should be made by the AAA or by the arbitrator.
          The arbitrator’s decision will follow the terms of this Agreement and will be final and
          binding. The arbitrator will have authority to award temporary, interim or permanent
          injunctive relief or relief providing for specific performance of this Agreement, but only
          to the extent necessary to provide relief warranted by the individual claim before the
          arbitrator. The award rendered by the arbitrator may be confirmed and enforced in any
          court having jurisdiction thereof. Notwithstanding any of the foregoing, nothing in this
          Agreement will preclude you from bringing issues to the attention of federal, state or
          local agencies and, if the law allows, they can seek relief against us for you.
        </li>

        <li>
          Information or Complaints. If you have a question or complaint regarding the Services,
          please send an e-mail to info@shorelineai.us. You may also contact us by writing to
          Shoreline AI, 15750 Winchester Blvd, Suite 206, Los Gatos, CA 95030. Please note that
          e-mail communications will not necessarily be secure; accordingly you should not include
          credit card information or other sensitive information in your e-mail correspondence with
          us.
        </li>

        <li>
          Copyright Infringement Claims. The Digital Millennium Copyright Act of 1998 (the “DMCA”)
          provides recourse for copyright owners who believe that material appearing on the Internet
          infringes their rights under U.S. copyright law. If you believe in good faith that
          materials available on the Apps infringe your copyright, you (or your agent) may send to
          the Company a written notice by mail, e-mail or fax, requesting that the Company remove
          such material or block access to it. If you believe in good faith that someone has wrongly
          filed a notice of copyright infringement against you, the DMCA permits you to send to the
          Company a counter-notice. Notices and counter-notices must meet the then-current statutory
          requirements imposed by the DMCA. See&nbsp;
          <a href="http://www.copyright.gov/" target="_blank" rel="noreferrer">
            http://www.copyright.gov/
          </a>
          &nbsp; for details. Notices and counter-notices must be sent in writing to the Company as
          follows: By mail to LEGAL, Shoreline AI, 15750 Winchester Blvd, Suite 206, Los Gatos, CA
          95030; by e-mail to&nbsp;
          <a href="mailto:legal@shorelineai.us">legal@shorelineai.us</a>. <br />
          The Company suggests that you consult your legal advisor before filing a DMCA notice or
          counter-notice.
        </li>

        <li>
          Export Controls. You are responsible for complying with United States export controls and
          for any violation of such controls, including any United States embargoes or other federal
          rules and regulations restricting exports. You represent, warrant and covenant that you
          are not (a) located in, or a resident or a national of, any country subject to a U.S.
          government embargo or other restriction, or that has been designated by the U.S.
          government as a “terrorist supporting” country; (b) on any of the U.S. government lists of
          restricted end users.
        </li>

        <li>
          Miscellaneous. This Agreement does not, and shall not be construed to; create any
          partnership, joint venture, employer-employee, agency or franchisor-franchisee
          relationship between you and the Company. If any provision of this Agreement is found to
          be unlawful, void or for any reason unenforceable, that provision will be deemed severable
          from this Agreement and will not affect the validity and enforceability of any remaining
          provision. You may not assign, transfer or sublicense any or all of your rights or
          obligations under this Agreement without the Company are express prior written consent.
          The Company may assign, transfer or sublicense any or all of the Company’s rights or
          obligations under this Agreement without restriction. No waiver by either party of any
          breach or default hereunder will be deemed to be a waiver of any preceding or subsequent
          breach or default. Any heading, caption or section title contained herein is for
          convenience only, and in no way defines or explains any section or provision. All terms
          defined in the singular shall have the same meanings when used in the plural, where
          appropriate and unless otherwise specified. Any use of the term “including” or variations
          thereof in this Agreement shall be construed as if followed by the phrase “without
          limitation.” This Agreement, including any terms and conditions incorporated herein, is
          the entire agreement between you and the Company relating to the subject matter hereof,
          and supersedes any and all prior or contemporaneous written or oral agreements or
          understandings between you and the Company relating to such subject matter. Notices to you
          (including notices of changes to this Agreement) may be made via posting to the Apps or by
          e-mail (including in each case via links), or by regular mail. Without limitation, a
          printed version of this Agreement and of any notice given in electronic form shall be
          admissible in judicial or administrative proceedings based upon or relating to this
          Agreement to the same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed form. The Company
          will not be responsible for any failure to fulfil any obligation due to any cause beyond
          its control.
        </li>

        <li>
          Apple-Specific Terms. In addition to your agreement with the foregoing terms and
          conditions, and notwithstanding anything to the contrary herein, the following provisions
          apply with respect to your use of any version of the Mobile Apps compatible with the iOS
          operating system of Apple Inc. (“Apple”). Apple is not a party to this Agreement and does
          not own and is not responsible for the Mobile Apps. Apple is not providing any warranty
          for the Mobile Apps except, if applicable, to refund the purchase price for it. Apple is
          not responsible for maintenance or other support services for the Mobile Apps and shall
          not be responsible for any other claims, losses, liabilities, damages, costs or expenses
          with respect to the Mobile Apps, including any third-party product liability claims,
          claims that the Mobile Apps fail to conform to any applicable legal or regulatory
          requirement, claims arising under consumer protection or similar legislation, and claims
          with respect to intellectual property infringement. Any inquiries or complaints relating
          to the use of the Mobile Apps, including those pertaining to intellectual property rights,
          must be directed to Shoreline AI in accordance with the “Information or Complaints”
          section (Section 20) above. The license you have been granted herein is limited to a
          non-transferable license to use the Mobile Apps on an Apple-branded product that runs
          Apple’s iOS operating system and is owned or controlled by you, or as otherwise permitted
          by the Usage Rules set forth in Apple’s App Store Terms of Service. In addition, you must
          comply with the terms of any third-party agreement applicable to you when using the Mobile
          Apps, such as your wireless data service agreement. Apple and Apple’s subsidiaries are
          third-party beneficiaries of this Agreement and, upon your acceptance of the terms and
          conditions of this Agreement, will have the right (and will be deemed to have accepted the
          right) to enforce this Agreement against you as a third-party beneficiary thereof;
          notwithstanding the foregoing, the Company’s right to enter into, rescind or terminate any
          variation, waiver or settlement under this Agreement is not subject to the consent of any
          third party.
        </li>

        <li>
          <b>Additional Policies and Information about the Service and Service Features.</b> This
          section contains additional policies and information about the Service and Service
          features including geographic coverage information, billing policies, and pricing and
          payment terms.
          <ol type="A">
            <li>
              Geographic Coverage.
              <br />
              The Service is initially targeted for premises within the U.S.A.
            </li>

            <li>
              Billing Policies.
              <br />
              You have to elect to use features of the Service that have charges or subscriptions
              (“Subscription Features”). By doing so, you agree to the pricing and payment terms and
              that we may update them from time to time. Company may add new features for additional
              fees and charges, or amend fees and charges for existing services, at any time in its
              sole discretion. Any change to our pricing or payment terms shall become effective in
              the billing cycle following notice of such change to you as provided in this
              Agreement.
            </li>

            <li>
              Closing Your Account
              <br />
              You may cancel your Subscription Features at any time. To cancel your Subscription
              Features, email support@shorelineai.us. If you send an email, include your name, the
              email address you registered with, and a phone number where you can be reached. In
              general, your account will be canceled within 5 business days of your cancelation
              request.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};
export default TermsAndConditions;
