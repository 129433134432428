import { skipToken } from '@reduxjs/toolkit/query';
import {
  useFetchDevicesByFirmwareIdQuery,
  useLazyFetchDeviceDatapointsQuery,
  useFetchStandaloneDevicesQuery,
  useFetchDeviceGroupsQuery,
  useFetchDevicesQuery,
  useLazyFetchDeviceEventsTrendQuery,
  useFetchDeviceEventsTrendQuery,
  useFetchOrbitPlotDataQuery,
  useLazyFetchOrbitPlotDataQuery,
  useFetchDefaultScheduleConfigQuery,
  useLazyFetchDefaultScheduleConfigQuery,
  useLazyFetchDevicesQuery,
  useLazyFetchDeviceGroupsQuery,
  useLazyFetchStandaloneDevicesQuery,
  useFetchHistoricalDataWithoutSamplingQuery,
  useLazyFetchHistoricalDataWithoutSamplingQuery,
  useLazyFetchDeviceWidgetSettingQuery,
  useFetchDeviceWidgetSettingQuery,
  useFetchDeviceQuery,
  useLazyFetchDeviceQuery,
  useFetchDeviceTypesQuery,
  useFetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery,
  useFetchDeviceProfilesDetailsByDeviceProfileIdQuery,
  useCreateDeviceMutation,
  useFetchPowertrainComponentsQuery,
  useFetchPowertrainDetailQuery,
  useLazyFetchPowertrainDetailQuery,
  useUpdatePowertrainMutation,
  useCreatePowertrainMutation,
  useDeletePowertrainMutation,
  useCreateGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
  useMoveGroupToGroupMutation,
  useMoveDeviceToGroupMutation,
  useFetchGenericDashboardQuery,
  useFetchAssetWidgetDataQuery,
  useFetchImageWidgetDataQuery,
  useFetchDeviceDatapointsQuery,
  useFetchSensorWidgetDataQuery,
  useUpdateSensorWidgetDataMutation,
  useFetchMultipleDevicePhotosQuery,
  useLazyFetchMultipleDevicePhotosQuery,
  useFetchDeviceProfilesQuery,
  useFetchDeviceSensorTemplatesQuery,
  useUpdateDeviceProfileMutation,
  useDeleteDevicePhotoMutation,
  useReplaceDevicePhotoMutation,
  useUploadDevicePhotoMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceSimSettingsMutation,
  useUpdateDeviceSettingsMutation,
  useUpdateDeviceFirmwareMutation,
  useRebootDeviceMutation,
  useFetchCategoriesQuery,
  useFetchAdditionalSensorsQuery,
  useUpdateDeviceWidgetSettingMutation,
  useCreateDeviceWidgetsSettingMutation,
  useLazyFetchMultipleDeviceDatapointsQuery,
  useFetchRawDatapointsByDeviceIdsQuery,
  useFetchHistoricalTrendDataQuery,
  useLazyFetchHistoricalTrendDataQuery,
  useLazyFetchImageWidgetDataQuery,
  useFetchBatteryStatusQuery,
  useFetchAnomalyListQuery,
  useFetchLatestPowetrainConfigQuery,
  useFetchDeviceMailboxQuery,
  useFetchAlertsQuery
} from './device.api';
import {
  FetchAdditionalSensorsArgs,
  FetchAlertsArgs,
  FetchAnomalyListArgs,
  FetchBatteryStatusArgs,
  FetchCategoriesArgs,
  FetchDefaultScheduleConfigArgs,
  FetchDeviceArgs,
  FetchDeviceDatapointsArgs,
  FetchDeviceEventsTrendArgs,
  FetchDeviceGroupsArgs,
  FetchDeviceHistoricalDataWithoutSamplingArgs,
  FetchDeviceMailboxArgs,
  FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs,
  FetchDeviceProfilesDetailsByDeviceProfileIdArgs,
  FetchDeviceSensorTemplatesArgs,
  FetchDeviceWidgetSettingArgs,
  FetchHistoricalTrendDataArgs,
  FetchLatestPowetrainConfigArgs,
  FetchMultipleDevicePhotosArgs,
  FetchOrbitPlotDataArgs,
  FetchPowertrainComponentsArgs,
  FetchPowertrainDetailArgs,
  FetchRawDatapointsByDeviceIdsArgs,
  FetchStandaloneDevicesArgs,
  GetDeviceProfilesArgs,
  GetDevicesArgs
} from './device.types';
import {
  MergedFetchGenericDashboardArgs,
  GenericDashboardType,
  FetchAssetWidgetDataArgs,
  FetchImageWidgetDataArgs,
  FetchSensorWidgetDataArgs
} from './types/genericDashboard.types';
import { Group } from '.';
import React, { useMemo } from 'react';
import { USER_SELF_STATUS, useUserSelf } from '../../user-settings';
import useIsSuperAdmin from '../../org-settings/user-management/custom-hooks/useIsSuperAdmin';

interface UseFetchDevicesOptions {
  /**
   * Prevents a query from automatically running.
   */
  skip?: boolean;
}

export interface MutationQueryHookOptions {
  /**
   * Use this property to specify a fixed cache key.
   * @description RTK Query provides an option to share results across mutation hook
   * instances using the fixedCacheKey option.
   */
  fixedCacheKey?: string;
}

export const useFetchDevicesByFirmwareId = (data: any) => {
  const {
    data: devicesByFirmwareId,
    isFetching: isFetchingDevicesByFirmwareId,
    isError: isDevicesFetchByFirmwareIdError,
    error: devicesFetchByFirmwareIdError,
    isSuccess: isDevicesByFirmwareIdFetchSuccess
  } = useFetchDevicesByFirmwareIdQuery(data);

  return {
    devicesByFirmwareId,
    isFetchingDevicesByFirmwareId,
    isDevicesFetchByFirmwareIdError,
    devicesFetchByFirmwareIdError,
    isDevicesByFirmwareIdFetchSuccess
  };
};

export const useFetchDevices = (
  args: GetDevicesArgs | typeof skipToken,
  options?: UseFetchDevicesOptions
) => {
  const {
    data,
    isFetching: isFetchingDevices,
    isError: isDevicesError,
    error: devicesError,
    isSuccess: isFetchingDevicesSuccess,
    isUninitialized: isDevicesUninitialized,
    refetch: refetchDevices
  } = useFetchDevicesQuery(args, { skip: options?.skip });

  const devices = data || [];

  return {
    devices,
    isFetchingDevices,
    isDevicesError,
    devicesError,
    isFetchingDevicesSuccess,
    isDevicesUninitialized,
    refetchDevices
  };
};

export const useLazyFetchDevices = () => {
  const [
    fetchLazyDevices,
    {
      isFetching: isLazyFetchingDevices,
      isError: isLazyDevicesError,
      error: lazyDevicesError,
      isSuccess: isLazyFetchingDevicesSuccess,
      data
    }
  ] = useLazyFetchDevicesQuery();

  const devices = data || [];

  return {
    devices,
    isLazyFetchingDevices,
    isLazyDevicesError,
    lazyDevicesError,
    isLazyFetchingDevicesSuccess,
    fetchLazyDevices
  };
};

export const useFetchDeviceGroups = (args: FetchDeviceGroupsArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingDeviceGroups,
    isError: isDeviceGroupsError,
    error: deviceGroupsError,
    isSuccess: isFetchingDeviceGroupsSuccess,
    isUninitialized: isDeviceGroupsUninitialized,
    refetch: refetchDeviceGroups
  } = useFetchDeviceGroupsQuery(
    typeof args !== 'symbol' && args?.slug && args?.slug !== 'org' ? args : skipToken
  );

  const deviceGroups = data || [];

  const deviceGroupsMap = React.useMemo(() => {
    return data?.reduce(
      (acc, group) => {
        acc[Number(group?.group_id)] = group;
        return acc;
      },
      {} as Record<number, Group>
    );
  }, [data]);

  return {
    deviceGroups,
    /**
     * This is a map of device groups with their group ID as the key.
     */
    deviceGroupsMap,
    isFetchingDeviceGroups,
    isDeviceGroupsError,
    deviceGroupsError,
    isFetchingDeviceGroupsSuccess,
    isDeviceGroupsUninitialized,
    refetchDeviceGroups
  };
};

export const useLazyFetchDeviceGroups = () => {
  const [
    fetchLazyDeviceGroups,
    {
      isFetching: isLazyFetchingDeviceGroups,
      isError: isLazyDeviceGroupsError,
      error: lazyDeviceGroupsError,
      isSuccess: isLazyFetchingDeviceGroupsSuccess,
      data
    }
  ] = useLazyFetchDeviceGroupsQuery();

  const groups = data || [];

  return {
    groups,
    isLazyFetchingDeviceGroups,
    isLazyDeviceGroupsError,
    lazyDeviceGroupsError,
    isLazyFetchingDeviceGroupsSuccess,
    fetchLazyDeviceGroups
  };
};

/**
 * This hook will fetch the stand-alone devices for a particular group ID.
 * @param args FetchStandaloneDevicesArgs
 */
export const useFetchAssetDevice = (args: FetchStandaloneDevicesArgs | typeof skipToken) => {
  const {
    data,
    isFetching: isFetchingAssetDevices,
    isError: isAssetDevicesError,
    error: assetDevicesError,
    isSuccess: isFetchingAssetDevicesSuccess,
    refetch: refetchAssetDevices
  } = useFetchStandaloneDevicesQuery(args);

  const assetDevices = data || [];

  return {
    assetDevices,
    isFetchingAssetDevices,
    isAssetDevicesError,
    assetDevicesError,
    isFetchingAssetDevicesSuccess,
    refetchAssetDevices
  };
};

export const useLazyFetchAssetDevices = () => {
  const [
    fetchLazyAssetDevices,
    {
      isFetching: isLazyFetchingAssetDevices,
      isError: isLazyAssetDevicesError,
      error: lazyAssetDevicesError,
      isSuccess: isLazyFetchingAssetDevicesSuccess,
      data
    }
  ] = useLazyFetchStandaloneDevicesQuery();

  const assetDevices = data || [];

  return {
    assetDevices,
    isLazyFetchingAssetDevices,
    isLazyAssetDevicesError,
    lazyAssetDevicesError,
    isLazyFetchingAssetDevicesSuccess,
    fetchLazyAssetDevices
  };
};

export const useFetchDeviceTypes = (args: GetDevicesArgs) => {
  const queryResult = useFetchDeviceTypesQuery(args);

  const deviceTypes = queryResult?.data || [];

  return {
    deviceTypes,
    isFetchingDeviceTypes: queryResult.isFetching,
    isFetchingDeviceTypesError: queryResult.isError,
    fetchingDeviceTypesError: queryResult.error,
    isFetchingDeviceTypesSuccess: queryResult.isSuccess
  };
};

export const useFetchPowertrainComponents = (args: FetchPowertrainComponentsArgs) => {
  const queryResult = useFetchPowertrainComponentsQuery(args);

  const powertrainComponents = queryResult?.data || [];

  return {
    powertrainComponents,
    isFetchingPowertrainComponents: queryResult.isFetching,
    isPowertrainComponentsError: queryResult.isError,
    powertrainComponentsError: queryResult.error,
    isPowertrainComponentsSuccess: queryResult.isSuccess
  };
};

export const useFetchPowertrainDetail = (
  args: FetchPowertrainDetailArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchPowertrainDetailQuery(args, { skip: options?.skip });

  const powertrainDetail = queryResult?.data;

  return {
    powertrainDetail,
    isFetchingPowertrainDetail: queryResult.isFetching,
    isPowertrainDetailError: queryResult.isError,
    powertrainDetailError: queryResult.error,
    isPowertrainDetailSuccess: queryResult.isSuccess
  };
};

export const useLazyFetchPowertrainDetail = () => {
  const [fetchLazyPowertrainDetail, queryResult] = useLazyFetchPowertrainDetailQuery();

  return {
    fetchLazyPowertrainDetail,
    isFetchingLazyPowertrainDetail: queryResult.isFetching,
    isLazyPowertrainDetailError: queryResult.isError,
    lazyPowertrainDetailError: queryResult.error,
    isLazyPowertrainDetailSuccess: queryResult.isSuccess
  };
};

export const useFetchLatestPowetrainConfig = (
  args: FetchLatestPowetrainConfigArgs | typeof skipToken
) => {
  const { data, isFetching, isError, isSuccess } = useFetchLatestPowetrainConfigQuery(args);

  return {
    latestPowetrainConfig: data,
    isFetchingLatestPowertrainConfig: isFetching,
    isLatestPowertrainConfigSuccess: isSuccess,
    isLatestPowertrainConfigError: isError
  };
};

export const useCreatePowertrain = () => {
  const [createPowertrain, { data, isLoading, isSuccess, isError }] = useCreatePowertrainMutation();

  return {
    createPowertrain,
    createPowertrainData: data,
    isCreatePowertrainLoading: isLoading,
    isCreatePowertrainSuccess: isSuccess,
    isCreatePowertrainError: isError
  };
};

export const useDeletePowertrain = () => {
  const [deletePowertrain, { data, isLoading, isSuccess, isError }] = useDeletePowertrainMutation();

  return {
    deletePowertrain,
    deletePowertrainData: data,
    isDeletePowertrainLoading: isLoading,
    isDeletePowertrainSuccess: isSuccess,
    isDeletePowertrainError: isError
  };
};

export const useCreateGroup = () => {
  const [createGroup, { data, isLoading, isSuccess, isError }] = useCreateGroupMutation();

  return {
    createGroup,
    createGroupData: data,
    isCreateGroupLoading: isLoading,
    isCreateGroupSuccess: isSuccess,
    isCreateGroupError: isError
  };
};

export const useEditGroup = () => {
  const [editGroup, { data, isLoading, isSuccess, isError }] = useEditGroupMutation();

  return {
    editGroup,
    editGroupData: data,
    isEditGroupLoading: isLoading,
    isEditGroupSuccess: isSuccess,
    isEditGroupError: isError
  };
};

export const useDeleteGroup = () => {
  const [deleteGroup, { data, isLoading, isSuccess, isError }] = useDeleteGroupMutation();

  return {
    deleteGroup,
    deleteGroupData: data,
    isDeleteGroupLoading: isLoading,
    isDeleteGroupSuccess: isSuccess,
    isDeleteGroupError: isError
  };
};

export const useMoveGroupToGroup = () => {
  const [moveGroupToGroup, { data, isLoading, isSuccess, isError }] = useMoveGroupToGroupMutation();

  return {
    moveGroupToGroup,
    moveGroupToGroupData: data,
    isMoveGroupToGroupLoading: isLoading,
    isMoveGroupToGroupSuccess: isSuccess,
    isMoveGroupToGroupError: isError
  };
};

export const useMoveDeviceToGroup = () => {
  const [moveDeviceToGroup, { data, isLoading, isSuccess, isError }] =
    useMoveDeviceToGroupMutation();

  return {
    moveDeviceToGroup,
    moveDeviceToGroupData: data,
    isMoveDeviceToGroupLoading: isLoading,
    isMoveDeviceToGroupSuccess: isSuccess,
    isMoveDeviceToGroupError: isError
  };
};

export const useUpdatePowertrain = () => {
  const [updatePowertrain, { data, isLoading, isSuccess, isError }] = useUpdatePowertrainMutation();

  return {
    updatePowertrain,
    updatePowertrainData: data,
    isUpdatePowertrainLoading: isLoading,
    isUpdatePowertrainSuccess: isSuccess,
    isUpdatePowertrainError: isError
  };
};

interface UseQueryOptions {
  /**
   * Prevents a query from automatically running.
   * @default `false`
   */
  skip?: boolean;
}

/**
 * @description This will be used on the create-device page.
 * @param args `FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs`
 * @param options - `{ skip?: boolean }`
 */
export const useFetchDeviceProfilesDetailsByCompatibleDeviceType = (
  args: FetchDeviceProfilesDetailsByCompatibleDeviceTypeArgs,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchDeviceProfilesDetailsByCompatibleDeviceTypeQuery(args, {
    skip: options?.skip
  });

  return {
    deviceProfilesDetailsByCompatibleDeviceType: queryResult?.data,
    isFetchingDeviceProfilesDetailsByCompatibleDeviceType: queryResult.isFetching,
    isFetchingDeviceProfilesDetailsByCompatibleDeviceTypeError: queryResult.isError,
    fetchingDeviceProfilesDetailsByCompatibleDeviceTypeError: queryResult.error,
    isFetchingDeviceProfilesDetailsByCompatibleDeviceTypeSuccess: queryResult.isSuccess
  };
};

export const useFetchGenericDashboard = (
  args: MergedFetchGenericDashboardArgs<GenericDashboardType> | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchGenericDashboardQuery(args, {
    skip: options?.skip
  });

  return {
    genericDashboard: queryResult?.data,
    isFetchingGenericDashboard: queryResult.isFetching,
    isFetchingGenericDashboardError: queryResult.isError,
    fetchingGenericDashboardError: queryResult.error,
    isFetchingGenericDashboardSuccess: queryResult.isSuccess,
    refetchGenericDashboard: queryResult.refetch
  };
};

export const useFetchAssetWidgetData = (
  args: FetchAssetWidgetDataArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchAssetWidgetDataQuery(args, {
    skip: options?.skip
  });

  return {
    assetWidgetData: queryResult?.data,
    isFetchingAssetWidgetData: queryResult.isFetching,
    isFetchingAssetWidgetDataError: queryResult.isError,
    fetchingAssetWidgetDataError: queryResult.error,
    isFetchingAssetWidgetDataSuccess: queryResult.isSuccess
  };
};

export const useUpdateSensorWidget = () => {
  const [updateSensorWidget, { data, isLoading, isSuccess, isError }] =
    useUpdateSensorWidgetDataMutation();

  return {
    updateSensorWidget,
    updateSensorWidgetData: data,
    isUpdateSensorWidgetLoading: isLoading,
    isUpdateSensorWidgetSuccess: isSuccess,
    isUpdateSensorWidgetError: isError
  };
};

export const useFetchSensorWidgetData = (
  args: FetchSensorWidgetDataArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchSensorWidgetDataQuery(args, {
    skip: options?.skip
  });

  return {
    sensorWidgetData: queryResult?.data,
    isFetchingSensorWidgetData: queryResult.isFetching,
    isFetchingSensorWidgetDataError: queryResult.isError,
    fetchingSensorWidgetDataError: queryResult.error,
    isFetchingSensorWidgetDataSuccess: queryResult.isSuccess
  };
};

export const useFetchImageWidgetData = (
  args: FetchImageWidgetDataArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchImageWidgetDataQuery(args, {
    skip: options?.skip
  });

  return {
    imageWidgetData: queryResult?.data,
    isFetchingImageWidgetData: queryResult.isFetching,
    isFetchingImageWidgetDataError: queryResult.isError,
    fetchingImageWidgetDataError: queryResult.error,
    isFetchingImageWidgetDataSuccess: queryResult.isSuccess,
    refetchImageWidgetData: queryResult.refetch
  };
};

export const useLazyFetchImageWidgetData = () => {
  const [fetchImageWidgetData, queryResult] = useLazyFetchImageWidgetDataQuery();

  return {
    fetchLazyImageWidgetData: fetchImageWidgetData,
    isFetchingLazyImageWidgetData: queryResult.isFetching,
    isFetchingLazyImageWidgetDataError: queryResult.isError,
    fetchingLazyImageWidgetDataError: queryResult.error,
    isFetchingLazyImageWidgetDataSuccess: queryResult.isSuccess,
    lazyImageWidgetData: queryResult.data
  };
};

/**
 * @description This will be used on the device-settings page.
 * @param args `FetchDeviceProfilesDetailsByDeviceProfileIdArgs | typeof skipToken`
 * @param options `{ skip?: boolean }`
 */
export const useFetchDeviceProfilesDetailsByDeviceProfileId = (
  args: FetchDeviceProfilesDetailsByDeviceProfileIdArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchDeviceProfilesDetailsByDeviceProfileIdQuery(args, {
    skip: options?.skip
  });

  return {
    deviceProfilesDetailsByDeviceProfileId: queryResult?.data,
    isFetchingDeviceProfilesDetailsByDeviceProfileId: queryResult.isFetching,
    isFetchingDeviceProfilesDetailsByDeviceProfileIdError: queryResult.isError,
    fetchingDeviceProfilesDetailsByDeviceProfileIdError: queryResult.error,
    isFetchingDeviceProfilesDetailsByDeviceProfileIdSuccess: queryResult.isSuccess
  };
};

export const useUpdateDeviceProfile = (options?: MutationQueryHookOptions) => {
  const [updateDeviceProfile, { data, isLoading, isSuccess, isError }] =
    useUpdateDeviceProfileMutation({
      fixedCacheKey: options?.fixedCacheKey
    });

  return {
    updateDeviceProfile,
    updateDeviceProfileData: data,
    isUpdateDeviceProfileLoading: isLoading,
    isUpdateDeviceProfileSuccess: isSuccess,
    isUpdateDeviceProfileError: isError
  };
};

/**
 * @description With this hook you can either update `device-name` & `description` or `location`.
 * @param options `{ skip?: boolean }`
 */
export const useUpdateDeviceSettings = (options?: MutationQueryHookOptions) => {
  const [updateDeviceSettings, { data, isLoading, isSuccess, isError }] =
    useUpdateDeviceSettingsMutation({
      fixedCacheKey: options?.fixedCacheKey
    });

  return {
    updateDeviceSettings,
    updateDeviceSettingsData: data,
    isUpdateDeviceSettingsLoading: isLoading,
    isUpdateDeviceSettingsSuccess: isSuccess,
    isUpdateDeviceSettingsError: isError
  };
};

export const useUpdateDeviceFirmware = (options?: MutationQueryHookOptions) => {
  const [updateDeviceFirmware, { data, isLoading, isSuccess, isError }] =
    useUpdateDeviceFirmwareMutation({
      fixedCacheKey: options?.fixedCacheKey
    });

  return {
    updateDeviceFirmware,
    updateDeviceFirmwareData: data,
    isUpdateDeviceFirmwareLoading: isLoading,
    isUpdateDeviceFirmwareSuccess: isSuccess,
    isUpdateDeviceFirmwareError: isError
  };
};

export const useRebootDevice = (options?: MutationQueryHookOptions) => {
  const [rebootDevice, { data, isLoading, isSuccess, isError }] = useRebootDeviceMutation({
    fixedCacheKey: options?.fixedCacheKey
  });

  return {
    rebootDevice,
    rebootDeviceData: data,
    isRebootDeviceLoading: isLoading,
    isRebootDeviceSuccess: isSuccess,
    isRebootDeviceError: isError
  };
};

export const useFetchDeviceProfiles = (
  args: GetDeviceProfilesArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchDeviceProfilesQuery(args, {
    skip: options?.skip
  });

  return {
    deviceProfiles: queryResult?.data,
    isFetchingDeviceProfiles: queryResult.isFetching,
    isFetchingDeviceProfilesError: queryResult.isError,
    fetchingDeviceProfilesError: queryResult.error,
    isFetchingDeviceProfilesSuccess: queryResult.isSuccess
  };
};

export const useUpdateDeviceSimSettings = (options?: MutationQueryHookOptions) => {
  const [updateDeviceSimSettings, { data, isLoading, isSuccess, isError }] =
    useUpdateDeviceSimSettingsMutation({
      fixedCacheKey: options?.fixedCacheKey
    });

  return {
    updateDeviceSimSettings,
    updateDeviceSimSettingsData: data,
    isUpdateDeviceSimSettingsLoading: isLoading,
    isUpdateDeviceSimSettingsSuccess: isSuccess,
    isUpdateDeviceSimSettingsError: isError
  };
};

export const useCreateDevice = () => {
  const [createDevice, { data, isLoading, isSuccess, isError }] = useCreateDeviceMutation();

  return {
    createDevice,
    createDeviceData: data,
    isCreateDeviceLoading: isLoading,
    isCreateDeviceSuccess: isSuccess,
    isCreateDeviceError: isError
  };
};

export const useDeleteDevice = (options?: MutationQueryHookOptions) => {
  const [deleteDevice, { data, isLoading, isSuccess, isError }] = useDeleteDeviceMutation({
    fixedCacheKey: options?.fixedCacheKey
  });

  return {
    deleteDevice,
    deleteDeviceData: data,
    isDeleteDeviceLoading: isLoading,
    isDeleteDeviceSuccess: isSuccess,
    isDeleteDeviceError: isError
  };
};

export const useFetchDeviceDatapoints = (
  args: FetchDeviceDatapointsArgs | typeof skipToken,
  otherOptions?: UseQueryOptions
) => {
  const { isFetching, data, isError, error, isSuccess } = useFetchDeviceDatapointsQuery(args, {
    ...otherOptions
  });
  return useMemo(
    () => ({
      isFetchingDeviceDatapoints: isFetching,
      isDeviceDatapointsError: isError,
      deviceDatapointsError: error,
      isFetchingDeviceDatapointsSuccess: isSuccess,
      deviceDatapoints: data || []
    }),
    [isFetching, isError, error, isSuccess, data]
  );
};

export const useLazyFetchDeviceDatapoints = () => {
  const [fetchLazyDeviceDatapoints, queryResult] = useLazyFetchDeviceDatapointsQuery();

  return {
    fetchLazyDeviceDatapoints,
    lazyDeviceDatapoints: queryResult?.data || [],
    isFetchingLazyDeviceDatapoints: queryResult?.isFetching,
    isLazyDeviceDatapointsSuccess: queryResult?.isSuccess,
    isLazyDeviceDatapointsError: queryResult?.isError,
    lazyDeviceDatapointsError: queryResult?.error
  };
};

export const useLazyFetchMultiDeviceDatapoints = () => {
  const [fetchMultipleDeviceDatapoints, queryResult] = useLazyFetchMultipleDeviceDatapointsQuery();

  return {
    fetchMultipleDeviceDatapoints,
    lazyDeviceDatapoints: queryResult?.data || [],
    isFetchingLazyDeviceDatapoints: queryResult?.isFetching,
    isLazyDeviceDatapointsSuccess: queryResult?.isSuccess,
    isLazyDeviceDatapointsError: queryResult?.isError,
    lazyDeviceDatapointsError: queryResult?.error
  };
};

export const useLazyFetchDeviceEventsTrend = () => {
  const [
    fetchLazyDeviceEventsTrend,
    {
      isFetching: isFetchingDeviceEventsTrend,
      isError: isDeviceEventsTrendError,
      error: deviceEventsTrendError,
      isSuccess: isFetchingDeviceEventsTrendSuccess,
      data: deviceEventsTrend
    }
  ] = useLazyFetchDeviceEventsTrendQuery();

  return {
    fetchLazyDeviceEventsTrend,
    deviceEventsTrend,
    isFetchingDeviceEventsTrend,
    isDeviceEventsTrendError,
    deviceEventsTrendError,
    isFetchingDeviceEventsTrendSuccess
  };
};

export const useFetchDeviceEventsTrend = (
  args: FetchDeviceEventsTrendArgs | typeof skipToken,
  otherOptions?: UseQueryOptions
) => {
  const {
    isFetching: isFetchingDeviceEventsTrend,
    isError: isDeviceEventsTrendError,
    error: deviceEventsTrendError,
    isSuccess: isFetchingDeviceEventsTrendSuccess,
    data: deviceEventsTrend
  } = useFetchDeviceEventsTrendQuery(args, { skip: otherOptions?.skip });

  return {
    deviceEventsTrend,
    isFetchingDeviceEventsTrend,
    isDeviceEventsTrendError,
    deviceEventsTrendError,
    isFetchingDeviceEventsTrendSuccess
  };
};

export const useFetchOrbitPlotData = (args: FetchOrbitPlotDataArgs) => {
  const {
    isFetching: isFetchingOrbitPlotData,
    isError: isOrbitPlotDataError,
    error: orbitPlotDataError,
    isSuccess: isFetchingOrbitPlotDataSuccess,
    data: orbitPlotData
  } = useFetchOrbitPlotDataQuery(args);

  return {
    orbitPlotData,
    isFetchingOrbitPlotData,
    isOrbitPlotDataError,
    orbitPlotDataError,
    isFetchingOrbitPlotDataSuccess
  };
};

export const useLazyFetchOrbitPlotData = () => {
  const [
    fetchLazyFetchOrbitPlotData,
    {
      isFetching: isFetchingOrbitPlotData,
      isError: isOrbitPlotDataError,
      error: orbitPlotDataError,
      isSuccess: isFetchingOrbitPlotDataSuccess,
      data: orbitPlotData
    }
  ] = useLazyFetchOrbitPlotDataQuery();

  return {
    fetchLazyFetchOrbitPlotData,
    orbitPlotData,
    isFetchingOrbitPlotData,
    isOrbitPlotDataError,
    orbitPlotDataError,
    isFetchingOrbitPlotDataSuccess
  };
};

export const useFetchDefaultScheduleConfig = (args: FetchDefaultScheduleConfigArgs) => {
  const {
    isFetching: isFetchingDefaultScheduleConfig,
    isError: isDefaultScheduleConfigError,
    error: defaultScheduleConfigError,
    isSuccess: isFetchingDefaultScheduleConfigSuccess,
    data: defaultScheduleConfig,
    refetch: refetchDefaultScheduleConfig
  } = useFetchDefaultScheduleConfigQuery(args);

  return {
    defaultScheduleConfig,
    isFetchingDefaultScheduleConfig,
    isDefaultScheduleConfigError,
    defaultScheduleConfigError,
    isFetchingDefaultScheduleConfigSuccess,
    refetchDefaultScheduleConfig
  };
};

export const useLazyFetchDefaultScheduleConfig = () => {
  const [
    fetchLazyDefaultScheduleConfig,
    {
      isFetching: isFetchingDefaultScheduleConfig,
      isError: isDefaultScheduleConfigError,
      error: defaultScheduleConfigError,
      isSuccess: isFetchingDefaultScheduleConfigSuccess,
      data: defaultScheduleConfig
    }
  ] = useLazyFetchDefaultScheduleConfigQuery();

  return {
    fetchLazyDefaultScheduleConfig,
    defaultScheduleConfig,
    isFetchingDefaultScheduleConfig,
    isDefaultScheduleConfigError,
    defaultScheduleConfigError,
    isFetchingDefaultScheduleConfigSuccess
  };
};

export const useFetchHistoricalDataWithoutSampling = (
  args: FetchDeviceHistoricalDataWithoutSamplingArgs
) => {
  const queryResult = useFetchHistoricalDataWithoutSamplingQuery(args);

  return {
    isFetchingHistoricalDataWithoutSampling: queryResult.isFetching,
    isHistoricalDataWithoutSamplingError: queryResult.isError,
    historicalDataWithoutSamplingError: queryResult.error,
    isHistoricalDataWithoutSamplingSuccess: queryResult.isSuccess,
    historicalDataWithoutSampling: queryResult.data
  };
};

export const useLazyFetchHistoricalDataWithoutSampling = () => {
  const [fetchHistoricalDataWithoutSamplingQuery, { isFetching, isError, error, isSuccess, data }] =
    useLazyFetchHistoricalDataWithoutSamplingQuery();

  return {
    fetchHistoricalDataWithoutSamplingQuery,
    historicalDataWithoutSampling: data,
    isFetchingHistoricalDataWithoutSampling: isFetching,
    isHistoricalDataWithoutSamplingError: isError,
    historicalDataWithoutSamplingError: error,
    isHistoricalDataWithoutSamplingSuccess: isSuccess
  };
};

export const useFetchDeviceWidgetSetting = (args: FetchDeviceWidgetSettingArgs) => {
  const queryResult = useFetchDeviceWidgetSettingQuery(args);

  return {
    isFetchingDeviceWidgetSetting: queryResult.isFetching,
    isDeviceWidgetSettingError: queryResult.isError,
    deviceWidgetSettingError: queryResult.error,
    isFetchingDeviceWidgetSettingSuccess: queryResult.isSuccess,
    deviceWidgetSetting: queryResult.data
  };
};

export const useLazyFetchDeviceWidgetSetting = () => {
  const [fetchDeviceWidgetSetting, { isFetching, isError, error, isSuccess, data }] =
    useLazyFetchDeviceWidgetSettingQuery();

  return {
    fetchDeviceWidgetSetting,
    deviceWidgetSetting: data,
    isFetchingDeviceWidgetSetting: isFetching,
    isDeviceWidgetSettingError: isError,
    deviceWidgetSettingError: error,
    isFetchingDeviceWidgetSettingSuccess: isSuccess
  };
};
export const useUpdatedDeviceWidgetSetting = () => {
  const [updateDeviceWidgetSetting, { isLoading, isSuccess, isError }] =
    useUpdateDeviceWidgetSettingMutation();

  return {
    updateDeviceWidgetSetting,

    isUpdateDeviceWidgetSettingLoading: isLoading,

    isUpdateDeviceWidgetSettingSuccess: isSuccess,

    isUpdateDeviceWidgetSettingError: isError
  };
};

export const useCreateDeviceWidgetsSetting = () => {
  const [createDeviceWidgetsSetting, { isLoading, isSuccess, isError, data }] =
    useCreateDeviceWidgetsSettingMutation();

  return {
    createDeviceWidgetsSetting,

    createDeviceWidgetsSettingResponse: data,

    isCreateDeviceWidgetsSettingLoading: isLoading,

    isCreateDeviceWidgetsSettingSuccess: isSuccess,

    isCreateDeviceWidgetsSettingError: isError
  };
};

export const useFetchDevice = (
  args: FetchDeviceArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchDeviceQuery(args, {
    skip: options?.skip
  });

  return {
    isFetchingDevice: queryResult.isFetching,
    isDeviceError: queryResult.isError,
    deviceError: queryResult.error,
    isFetchingDeviceSuccess: queryResult.isSuccess,
    device: queryResult.data
  };
};

export const useLazyFetchDevice = () => {
  const [fetchLazyDevice, { isFetching, isError, error, isSuccess, data }] =
    useLazyFetchDeviceQuery();

  return {
    fetchLazyDevice,
    device: data,
    isFetchingDevice: isFetching,
    isDeviceError: isError,
    deviceError: error,
    isFetchingDeviceSuccess: isSuccess
  };
};

export const useFetchMultipleDevicePhotos = (
  args: FetchMultipleDevicePhotosArgs | typeof skipToken
) => {
  const queryResult = useFetchMultipleDevicePhotosQuery(args);

  return {
    devicePhotos: queryResult.data || [],
    isFetchingDevicePhotos: queryResult.isFetching,
    isDevicePhotosError: queryResult.isError,
    devicePhotosError: queryResult.error,
    isDevicePhotosSuccess: queryResult.isSuccess,
    refetchDevicePhotos: queryResult.refetch
  };
};

export const useLazyFetchMultipleDevicePhotos = () => {
  const [
    fetchMultipleDevicePhotos,
    {
      data,
      isFetching: isFetchingDevicePhotos,
      isError: isDevicePhotosError,
      error: devicePhotosError,
      isSuccess: isDevicePhotosSuccess
    }
  ] = useLazyFetchMultipleDevicePhotosQuery();

  const allDevicePhotos = data || [];

  return {
    fetchMultipleDevicePhotos,
    allDevicePhotos,
    isFetchingDevicePhotos,
    isDevicePhotosError,
    devicePhotosError,
    isDevicePhotosSuccess
  };
};

export const useDeleteDevicePhoto = (options?: MutationQueryHookOptions) => {
  const [deleteDevicePhoto, queryResult] = useDeleteDevicePhotoMutation(options);

  return {
    deleteDevicePhoto,
    deleteDevicePhotoData: queryResult.data,
    isDeleteDevicePhotoError: queryResult.isError,
    deleteDevicePhotoError: queryResult.error,
    isDeleteDevicePhotoSuccess: queryResult.isSuccess,
    isDeleteDevicePhotoLoading: queryResult.isLoading
  };
};

export const useReplaceDevicePhoto = (options?: MutationQueryHookOptions) => {
  const [replaceDevicePhoto, queryResult] = useReplaceDevicePhotoMutation({
    fixedCacheKey: options?.fixedCacheKey
  });

  return {
    replaceDevicePhoto,
    replaceDevicePhotoData: queryResult.data,
    isReplaceDevicePhotoError: queryResult.isError,
    replaceDevicePhotoError: queryResult.error,
    isReplaceDevicePhotoSuccess: queryResult.isSuccess,
    isReplaceDevicePhotoLoading: queryResult.isLoading
  };
};

export const useUploadDevicePhoto = (options?: MutationQueryHookOptions) => {
  const [uploadDevicePhoto, queryResult] = useUploadDevicePhotoMutation({
    fixedCacheKey: options?.fixedCacheKey
  });

  return {
    uploadDevicePhoto,
    uploadDevicePhotoData: queryResult.data,
    isUploadDevicePhotoError: queryResult.isError,
    uploadDevicePhotoError: queryResult.error,
    isUploadDevicePhotoSuccess: queryResult.isSuccess,
    isUploadDevicePhotoLoading: queryResult.isLoading
  };
};

export const useFetchDeviceSensorTemplates = (
  args: FetchDeviceSensorTemplatesArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchDeviceSensorTemplatesQuery(args, {
    skip: options?.skip
  });

  return {
    deviceSensorTemplates: queryResult.data,
    isFetchingDeviceSensorTemplates: queryResult.isFetching,
    isDeviceSensorTemplatesError: queryResult.isError,
    deviceSensorTemplatesError: queryResult.error,
    isFetchingDeviceSensorTemplatesSuccess: queryResult.isSuccess
  };
};

export const useFetchCategories = (
  args: FetchCategoriesArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchCategoriesQuery(args, {
    skip: options?.skip
  });

  return {
    categories: queryResult.data || [],
    isFetchingCategories: queryResult.isFetching,
    isCategoriesError: queryResult.isError,
    categoriesError: queryResult.error,
    isFetchingCategoriesSuccess: queryResult.isSuccess
  };
};

export const useFetchAdditionalSensors = (
  args: FetchAdditionalSensorsArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchAdditionalSensorsQuery(args, {
    skip: options?.skip
  });

  return {
    additionalSensors: queryResult.data || [],
    isFetchingAdditionalSensors: queryResult.isFetching,
    isAdditionalSensorsError: queryResult.isError,
    additionalSensorsError: queryResult.error,
    isFetchingAdditionalSensorsSuccess: queryResult.isSuccess,
    isAdditionalSensorsUninitialized: queryResult.isUninitialized
  };
};

export const useFetchRawDatapointsByDeviceIds = (
  args: FetchRawDatapointsByDeviceIdsArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchRawDatapointsByDeviceIdsQuery(args, {
    skip: options?.skip
  });

  return {
    rawDatapointsByDeviceIds: queryResult.data,
    isFetchingRawDatapointsByDeviceIds: queryResult.isFetching,
    isRawDatapointsByDeviceIdsError: queryResult.isError,
    rawDatapointsByDeviceIdsError: queryResult.error,
    isFetchingRawDatapointsByDeviceIdsSuccess: queryResult.isSuccess,
    refetchRawDatapointsByDeviceIds: queryResult.refetch
  };
};

export const useFetchHistoricalTrendData = (
  args: FetchHistoricalTrendDataArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchHistoricalTrendDataQuery(args, {
    skip: options?.skip
  });

  return {
    historicalTrendData: queryResult.data,
    isFetchingHistoricalTrendData: queryResult.isFetching,
    isHistoricalTrendDataError: queryResult.isError,
    historicalTrendDataError: queryResult.error
  };
};

export const useLazyFetchHistoricalTrendData = () => {
  const [fetchLazyHistoricalTrendData, queryResult] = useLazyFetchHistoricalTrendDataQuery();

  return {
    fetchLazyHistoricalTrendData,
    lazyHistoricalTrendData: queryResult.data,
    isFetchingLazyHistoricalTrendData: queryResult.isFetching,
    isLazyHistoricalTrendDataError: queryResult.isError,
    lazyHistoricalTrendDataError: queryResult.error
  };
};

export const useFetchBatteryStatus = (
  args: FetchBatteryStatusArgs | typeof skipToken,
  options?: UseQueryOptions
) => {
  const queryResult = useFetchBatteryStatusQuery(args, {
    skip: options?.skip
  });

  return {
    batteryStatus: queryResult.data || [],
    isFetchingBatteryStatus: queryResult.isFetching,
    isBatteryStatusError: queryResult.isError,
    batteryStatusError: queryResult.error,
    isFetchingBatteryStatusSuccess: queryResult.isSuccess,
    refetchBatteryStatus: queryResult.refetch
  };
};

export const useFetchAnomalyList = (args: FetchAnomalyListArgs, options?: UseQueryOptions) => {
  const userSelf = useUserSelf();
  const isSuperAdmin = useIsSuperAdmin();

  const queryResult = useFetchAnomalyListQuery(
    userSelf.status === USER_SELF_STATUS.READY
      ? {
          slug: args.slug,
          payload: {
            ...args.payload,
            ...(isSuperAdmin && { hide_anomaly: 'true' })
          }
        }
      : skipToken,
    { skip: options?.skip }
  );

  return {
    anomalyList: queryResult.data?.derived_expressions || [],
    isFetchingAnomalyList: queryResult.isFetching,
    isAnomalyListError: queryResult.isError,
    anomalyListError: queryResult.error,
    isFetchingAnomalyListSuccess: queryResult.isSuccess,
    refetchAnomalyList: queryResult.refetch
  };
};

export const useFetchDeviceMailbox = (args: FetchDeviceMailboxArgs | typeof skipToken) => {
  const queryResult = useFetchDeviceMailboxQuery(args);

  return {
    deviceMailboxMessages: queryResult.data || [],
    isFetchingDeviceMailbox: queryResult.isFetching,
    isDeviceMailboxError: queryResult.isError,
    deviceMailboxError: queryResult.error,
    isFetchingDeviceMailboxSuccess: queryResult.isSuccess
  };
};

export const useFetchAlerts = (args: FetchAlertsArgs | typeof skipToken) => {
  const queryResult = useFetchAlertsQuery(args);

  return {
    alerts: queryResult.data,
    isFetchingAlerts: queryResult.isFetching,
    isAlertsError: queryResult.isError,
    alertsError: queryResult.error,
    isFetchingAlertsSuccess: queryResult.isSuccess
  };
};
