/**
 * Raw Data Table Editor
 * @author mahesh.kedari@shorelineiot.com
 */

interface Props {
  query: any;
  handleUpdate: (data: any) => void;
}
/**
 *
 */

const RawDataTableEditor = ({ query }: Props) => {
  // Commented below code to avoid crash

  // if (query) {
  //   return <div>{query}</div>;
  // }
  return (
    <div>
      <span>No query available</span>
    </div>
  );
};
export default RawDataTableEditor;
