/**
 * Sidebar Header component
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box } from '@mui/material';
import OrgList from '../../organization/org-list/OrgMenuList';
import { useStyles } from './sidebarHeader.styles';
import UserRole from '../../user-settings/user-role/UserRole';
/**
 * list of organization sidebar
 * @author ashwini.gawade@shorelineiot.com
 */

/**
 *
 */
const SidebarHeader = () => {
  const classes = useStyles();
  return (
    <Box p={2} className={classes.sidebarHeader}>
      <OrgList />
      <UserRole />
    </Box>
  );
};

export default SidebarHeader;
