import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Series, Info } from '../common/map-chart/mapChartUtils';
import { MapChartLegends } from './MapChartLegends';
import { useStyles } from './legendAndButton.styles';
import { GoogleMapComponent } from './GoogleMapComponent';
import { Box, Grid, Typography } from '@mui/material';
import {
  MapChartSettingsType,
  MAP_CHART_DEVICES_SETTINGS_TYPES,
  MAP_CHART_MARKER_TYPES
} from './MapChartDefaultViewConfig';
import { BubblePointInfo } from './BubblePointInfo';

export interface ResultSet {
  critical_series: Series[];
  high_series: Series[];
  other_series: Series[];
  moderate_series: Series[];
  critical_info: Info[];
  high_info: Info[];
  other_info: Info[];
  moderate_info: Info[];
  devices: number;
  alarms: number;
}
interface MapChartViewerProps {
  resultSet: ResultSet;
  chartSettings: MapChartSettingsType;
}

const MapChartViewer: React.FC<MapChartViewerProps> = ({ resultSet, chartSettings }) => {
  const location = useLocation();
  const classes = useStyles();

  const [showCritical, setShowCritical] = useState(true);
  const [showHigh, setShowHigh] = useState(true);
  const [showModerate, setShowModerate] = useState(true);
  const [showOther, setShowOther] = useState(true);
  const [, setContainerRefReady] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const subtitleRef = useRef<HTMLDivElement | null>(null);
  const legendsRef = useRef<HTMLDivElement | null>(null);
  let mapHeight = 400;

  const url = location.pathname;
  const editMode = url.includes('/edit/');

  // Whenever the height of the resizeable card changes, a new height will be assigned to the map component.
  if (containerRef && containerRef.current) {
    const resizeableCardHeight =
      containerRef.current.parentElement?.parentElement?.parentElement?.clientHeight;
    const headersHeight = containerRef.current.parentElement?.parentElement?.clientHeight;
    const gridParentHeight = containerRef.current.parentElement?.clientHeight;
    const legendsHeight = legendsRef.current?.clientHeight;
    const subtitleHeight = subtitleRef.current?.clientHeight;
    if (
      resizeableCardHeight &&
      headersHeight &&
      gridParentHeight &&
      legendsHeight &&
      subtitleHeight
    ) {
      const topMargin = headersHeight - gridParentHeight;
      mapHeight = resizeableCardHeight - (topMargin + legendsHeight + subtitleHeight);
    }
  }

  /**
   * Trigger a re-render whenever the ref gets assigned to the mounted component,
   * so that the map height gets recomputed
   * */
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerRefReady(true);
    } else {
      setContainerRefReady(false);
    }
  }, [containerRef]);

  return (
    <Grid container ref={containerRef}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.title}
        ref={subtitleRef}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems="center"
          justifyContent="space-between">
          <Typography fontWeight={500} variant="inherit">
            {' '}
            Devices: {resultSet.devices}, Alarms: {resultSet.alarms}{' '}
          </Typography>
          {!editMode && chartSettings.markerType === MAP_CHART_MARKER_TYPES.BUBBLE && (
            <Box style={{ marginRight: '2%' }}>
              <BubblePointInfo />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.legends}
        ref={legendsRef}>
        <MapChartLegends
          setShowCritical={setShowCritical}
          showCritical={showCritical}
          setShowHigh={setShowHigh}
          showHigh={showHigh}
          setShowOther={setShowOther}
          showOther={showOther}
          setShowModerate={setShowModerate}
          showModerate={showModerate}
          deviceSettings={chartSettings.deviceSettings}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ height: editMode ? 400 : mapHeight }}>
        <GoogleMapComponent
          resultSet={resultSet}
          editMode={editMode}
          showCritical={showCritical}
          showHigh={showHigh}
          showModerate={showModerate}
          showOther={
            showOther && chartSettings.deviceSettings === MAP_CHART_DEVICES_SETTINGS_TYPES.ALL
          }
          markerType={chartSettings.markerType}
        />
      </Grid>
    </Grid>
  );
};

export default MapChartViewer;
