/**
 *
 * @param param0
 * @returns
 */
import { useRawDatapoints } from '../../../../../../features/dashboard/utils/useRawDatapoints';
import { getDetapointSeries } from '../../../common/getDatapointSeries';
import WidgetSettingAccordion from '../../../common/WidgetAccordion';
import TranslateText from '../../../../../../i18n/TranslateText';
/**
 *
 */
interface EditorProps {
  query: any;
  handleUpdate: () => void;
}
/**
 *
 * @param param0
 * @returns
 */
const RawDataCartesianChartEditor = ({ query }: EditorProps) => {
  const { datapointsList } = useRawDatapoints();
  const datapointSeries: Array<any> = getDetapointSeries(query?.data?.devices, datapointsList);
  return (
    <>
      {datapointSeries.map((datapoint, index: number) => (
        <WidgetSettingAccordion title={datapoint.title} key={index}>
          {TranslateText('configurations_for', 'Configurations for')} {datapoint.title}
        </WidgetSettingAccordion>
      ))}
    </>
  );
};
export default RawDataCartesianChartEditor;
