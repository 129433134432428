/**
 * Authentication Layout
 * @author mahesh.kedari@shorelineiot.com
 */
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import UnauthenticatedHeader from '../common/header/unauthenticatedHeader/UnauthenticatedHeader';
import { useStyles } from './authLayout.styles';
import { Filler, SLFooter } from '../../framework';
import AuthRoutes from './authRoutes';
/**
 *
 */
function AuthLayout() {
  const classes = useStyles();
  return (
    <Box className={classes.authContainer}>
      <UnauthenticatedHeader />
      <AuthRoutes />
      <Filler />
      <Hidden mdDown>
        <SLFooter />
      </Hidden>
    </Box>
  );
}
export default AuthLayout;
