/**
 * Organisation Sidebar Menu
 * @author mahesh.kedari@shorelineiot.com
 */
import {
  SidebarMenu,
  useSlugContext,
  SLSubmenuLink,
  CanAccess,
  usePermissions,
  ACCESS_PERMIT,
  useIsMainOrg
} from '../../framework';
import { OrgSettingsIcon } from '../../framework/icons';
import { ORGANISATION_PERMISSIONS } from '../organization/organisation.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';
import useIsSuperAdmin from './user-management/custom-hooks/useIsSuperAdmin';

export default function OrgSettingsMenu() {
  const { slug } = useSlugContext();
  const isMainOrg = useIsMainOrg();
  const isSuperAdmin = useIsSuperAdmin();
  const orgSettingsMenuId: string = sidebarId.menuItems('org_settings');
  const show = false;
  const hasViewPermission: ACCESS_PERMIT = usePermissions(ORGANISATION_PERMISSIONS.VIEW_DETAILS);
  if (hasViewPermission === ACCESS_PERMIT.ALLOW) {
    return (
      <SidebarMenu
        testID={orgSettingsMenuId}
        route={`/${slug}/app/org-settings`}
        textKey="organization"
        defaultText="Org Settings"
        icon={<OrgSettingsIcon className="MuiSvgIcon-root" />}>
        <CanAccess action={ORGANISATION_PERMISSIONS.VIEW_DETAILS}>
          <SLSubmenuLink
            testID={`${orgSettingsMenuId}-users`}
            route={`/${slug}/app/org-settings/user-management`}
            textKey="user_management"
            defaultText="Users"
          />
          <SLSubmenuLink
            testID={`${orgSettingsMenuId}-roles`}
            route={`/${slug}/app/org-settings/roles`}
            textKey="roles"
            defaultText="Roles"
          />
          <SLSubmenuLink
            testID={`${orgSettingsMenuId}-groups`}
            route={`/${slug}/app/org-settings/groups`}
            textKey="groups"
            defaultText="Groups"
          />
          {show && (
            <SLSubmenuLink
              testID={`${orgSettingsMenuId}-subscriptions`}
              route={`/${slug}/app/org-settings/subscriptions`}
              textKey="subscriptions"
              defaultText="Subscriptions"
            />
          )}
          <SLSubmenuLink
            testID={`${orgSettingsMenuId}-customization`}
            route={`/${slug}/app/org-settings/settings`}
            textKey="settings"
            defaultText="Customization"
          />
          {show && (
            <SLSubmenuLink
              testID={`${orgSettingsMenuId}-payment`}
              route={`/${slug}/app/org-settings/payment`}
              textKey="payment"
              defaultText="Payment"
            />
          )}
          <SLSubmenuLink
            testID={`${orgSettingsMenuId}-api_tokens`}
            route={`/${slug}/app/org-settings/api-tokens`}
            textKey="api_tokens"
            defaultText="API Tokens"
          />

          {isMainOrg && isSuperAdmin && (
            <SLSubmenuLink
              testID={`${orgSettingsMenuId}-feature_configurations`}
              route={`/${slug}/app/org-settings/feature-configurations`}
              textKey="feature_configurations"
              defaultText="Feature config"
            />
          )}
          {isSuperAdmin && (
            <SLSubmenuLink
              testID={`${orgSettingsMenuId}-data-_export`}
              route={`/${slug}/app/org-settings/data-export`}
              textKey="data_export"
              defaultText="Data Export"
            />
          )}
          {/* <SLSubmenuLink
            route={`/${slug}/app/org-settings/customer-survey`}
            textKey="survey"
            defaultText="Survey"
          /> */}
        </CanAccess>
      </SidebarMenu>
    );
  }
  return null;
}
