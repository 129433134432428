import RefreshIcon from '@mui/icons-material/Refresh';
import { LightTooltip } from '../tooltip/Tooltip';
import { SLButton } from '../button';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface ReloadButtonProps {
  disabled: boolean;
  onReload: () => void;
  tooltipText?: string;
  buttonText?: string;
  variant?: 'text' | 'outlined' | 'contained';
}

export const SLReloadPageButton = ({
  disabled,
  onReload,
  tooltipText = 'Reload Page',
  buttonText = '',
  variant = 'contained'
}: ReloadButtonProps) => {
  return (
    <LightTooltip
      title={tooltipText}
      placement="bottom-start"
      translationId={toSnakeCase(tooltipText)}>
      <SLButton variant={variant} disabled={disabled} onClick={onReload}>
        <RefreshIcon />
        {buttonText}
      </SLButton>
    </LightTooltip>
  );
};
