import { APISERVICES } from '../../../../../framework';
import { featureApi } from '../../../../feature.api';
import { sortByDateTime } from '../../waterfall/header/waterfall.helper';
import { MAX_EVENTS_COUNT } from './waterfall.constant';
import { handleWaterfallDpidChange, handleWaterfallYUnitChange } from './waterfall.slice';
import {
  DeviceEventData,
  DeviceEvents,
  EventType,
  GetDeviceEventsApiArgs,
  GetDeviceEventsDataArgs,
  Y_AXIS_UNIT
} from './waterfall.types';

export const waterfallApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchDeviceEvents: build.query<DeviceEvents, GetDeviceEventsApiArgs>({
      query: ({ slug, deviceId, payload }: GetDeviceEventsApiArgs) => ({
        apiEndpoint: `orgs/${slug}/devices/${deviceId}/events`,
        params: payload,
        service: APISERVICES.DEVICE_API,
        method: 'GET',
        actionKey: 'DEVICE_EVENTS'
      }),
      transformResponse: (response: EventType[]) => {
        const accelerometerEvents: EventType[] = sortByDateTime(
          response?.filter((event: EventType) => event.event_name === 'accelerometer')
        );
        const structureborneEvents: EventType[] = sortByDateTime(
          response?.filter((event: EventType) => event.event_name === 'Structure borne')
        );
        const accelerometerEventsWithCPMValue = accelerometerEvents?.filter(
          (event: EventType) => event.event_speed_hz !== null && event.event_speed_hz !== 0
        );
        const structureborneEventsWithCPMValue = structureborneEvents?.filter(
          (event: EventType) => event.event_speed_hz !== null && event.event_speed_hz !== 0
        );

        const selectedAccelerometerEvents: string[] = accelerometerEvents
          ?.slice(Math.max(accelerometerEvents?.length - MAX_EVENTS_COUNT, 0))
          ?.map((event: EventType) => event.event_id);

        const selectedStructurebornEvents: string[] = structureborneEvents
          ?.slice(Math.max(structureborneEvents?.length - MAX_EVENTS_COUNT, 0))
          ?.map((event: EventType) => event.event_id);

        return {
          accelerometerEvents,
          structureborneEvents,
          accelerometerEventsWithCPMValue,
          structureborneEventsWithCPMValue,
          selectedAccelerometerEvents,
          selectedStructurebornEvents
        };
      }
    }),
    fetchDeviceEventsData: build.query<DeviceEventData, GetDeviceEventsDataArgs>({
      query: ({ slug, deviceId, eventId, dpid, unit }) => ({
        apiEndpoint: `orgs/${slug}/devices/${deviceId}/event_data?event_id=${eventId}&dpids=${dpid}&unit=${
          unit === Y_AXIS_UNIT.G ? Y_AXIS_UNIT.G : 'inches/second'
        }`,
        params: null,
        service: APISERVICES.VA_API,
        method: 'GET',
        actionKey: 'DEVICE_EVENTS',
        onSuccess: (dispatch) => {
          dispatch(handleWaterfallDpidChange(dpid));
          dispatch(handleWaterfallYUnitChange(unit));
        }
      })
    })
  })
});

export const {
  useFetchDeviceEventsQuery,
  useLazyFetchDeviceEventsQuery,
  useLazyFetchDeviceEventsDataQuery,
  useFetchDeviceEventsDataQuery
} = waterfallApi;
