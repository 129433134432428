import { generateTags } from '../../../../framework/lib/rtk/RTK.utils';
import { featureApi } from '../../../feature.api';

import {
  DATA_EXPORT_TAG,
  BucketConfigArgs,
  ScheduleConfigArgs,
  fetchConfig,
  BucketConfig,
  ConfiguredSchedulesResponse,
  S3TestValidationResponse
} from './dataExport.types';
import {
  fetchBucketConfigQuery,
  fetchScheduleConfigQuery,
  testBucketConfigQuery,
  saveBucketConfigQuery,
  saveScheduleConfigQuery
} from './dataExportQuery.utils';

export const dataExportApi = featureApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBucketConfig: build.query<BucketConfig, fetchConfig>({
      query: fetchBucketConfigQuery,
      providesTags: (result) => generateTags(result, DATA_EXPORT_TAG.DATA_EXPORT)
    }),
    fetchScheduleConfig: build.query<ConfiguredSchedulesResponse, fetchConfig>({
      query: fetchScheduleConfigQuery,
      providesTags: (result) => generateTags(result, DATA_EXPORT_TAG.DATA_EXPORT)
    }),
    testBucketConfig: build.mutation<S3TestValidationResponse, BucketConfigArgs>({
      query: testBucketConfigQuery
    }),
    saveBucketConfig: build.mutation<void, BucketConfigArgs>({
      query: saveBucketConfigQuery,
      invalidatesTags: () => [DATA_EXPORT_TAG.DATA_EXPORT]
    }),
    saveScheduleConfig: build.mutation<void, ScheduleConfigArgs>({
      query: saveScheduleConfigQuery,
      invalidatesTags: () => [DATA_EXPORT_TAG.DATA_EXPORT]
    })
  })
});

export const {
  useFetchBucketConfigQuery,
  useFetchScheduleConfigQuery,
  useSaveBucketConfigMutation,
  useSaveScheduleConfigMutation,
  useTestBucketConfigMutation
} = dataExportApi;
