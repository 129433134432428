import Highcharts from 'highcharts/highstock';
import { chartXAxisFormatter, chartXAxisLabelFormatter } from '../../../../helpers';

import { TooltipFormatter } from './TooltipFormatter';
import TranslateText from '../../../../../i18n/TranslateText';

/**
 *
 */
export const lineChartConfig = (
  unit: string,
  setExtremes: Highcharts.AxisSetExtremesEventCallbackFunction | any,
  data:
    | (number | [string | number, number | null] | Highcharts.PointOptionsObject | null)[]
    | undefined
): Highcharts.Options => {
  const MAX_POINTS = 50;
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      animation: false,
      zooming: { type: 'x' },
      events: {
        load(event: any) {
          const { series } = this;
          if (series[0]?.points.length < MAX_POINTS) {
            series[0]?.update({
              marker: {
                enabled: true
              },
              type: 'line'
            });
          }
        }
      },
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      }
    },
    // boost: {
    //   useGPUTranslations: true,
    //   seriesThreshold: 1,
    //   debug: {
    //     timeSetup: true,
    //     timeSeriesProcessing: true,
    //     timeKDTree: true,
    //     timeBufferCopy: true,
    //   },
    // },
    title: {
      text: ''
    },
    xAxis: {
      type: 'linear',
      // tickInterval: 24 * 3600,
      crosshair: true,
      labels: {
        autoRotation: [0],
        formatter: chartXAxisFormatter,
        ...chartXAxisLabelFormatter
      },
      events: {
        // @ts-ignore
        selection(event: any) {
          // @ts-ignore
          const { series } = this;
          const markerOptions = series[0].options.marker;
          if (!event.resetSelection) {
            markerOptions.enabled = true;
          } else {
            markerOptions.enabled = false;
          }
        },
        setExtremes
      },
      minRange: 1
    },
    tooltip: {
      // shared: true,
      useHTML: true,
      formatter: TooltipFormatter,
      style: {
        color: 'white'
      },
      backgroundColor: 'rgba(77, 77, 77, 0.7)'
    },
    time: {
      useUTC: false
    },
    yAxis: [
      {
        title: {
          text: unit
        },
        height: '75%',
        labels: {
          align: 'left',
          x: -3,
          formatter(this) {
            if (Number.isInteger(this?.value)) {
              return `${this?.value}`;
            }
            return typeof this?.value === 'number' ? this?.value?.toPrecision(4) : this.value;
          }
        },
        plotLines: [
          /* {
            color: 'red',
            dashStyle: 'Dash',
            value: 60,
            zIndex: 2,
          }, */
        ]
      }
    ],
    rangeSelector: {
      enabled: true,
      buttonPosition: {
        align: 'right',
        x: -25
      },
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        style: {
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: 'none'
          },
          select: {
            fill: 'none',
            style: {
              color: 'white'
            }
          }
          // disabled: { ... }
        }
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#00b1c1',
        fontWeight: 'bold'
      },
      buttons: [
        {
          type: 'all',
          text: 'Reset'
        }
      ],
      inputEnabled: false,
      labelStyle: {
        display: 'none'
      }
    },
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      enabled: true,
      adaptToUpdatedData: false,
      xAxis: {
        type: 'linear',
        labels: {
          formatter: chartXAxisFormatter,
          ...chartXAxisLabelFormatter
        }
      }
    },
    credits: {
      enabled: false
    },
    // plotOptions: {
    //   // series: {
    //   //   marker: {
    //   //     enabled: false,
    //   //   },
    //   // },
    // },
    series: [
      {
        data,
        // boostThreshold: 1,
        pointStart: 1329163200000,
        pointInterval: 1000 * 3600, // * 24,
        type: 'line',
        name: 'AI1',
        showInLegend: false,
        cropThreshold: 1000,
        tooltip: {
          valueDecimals: 2
        },
        dataGrouping: {
          enabled: false
        }
      }
    ]
  };
};
