/**
 * Number View
 * @author mahesh.kedari@shorelineiot.com
 */
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChartViewProps } from '../common/ChartViewProps';
/**
 *
 * @param param0
 * @returns
 */

interface NumberStyleProps {
  color?: string;
  backgroundColor?: string;
}

const useStyles = makeStyles({
  numberStyle: {
    // color: (props: NumberStyleProps) => props.color,
    marginTop: '25px',
    textAlign: 'center'
  }
});

const SLNumberView: React.FC<ChartViewProps> = ({ chartSettings, resultSet }: ChartViewProps) => {
  const props: NumberStyleProps = {
    backgroundColor: chartSettings?.backgroundColor,
    color: chartSettings?.color
  };
  const classes = useStyles(props);

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <hr
            style={{
              borderTop: `4px solid ${chartSettings?.color || '#000'}`,
              width: '80%'
            }}
          />
          <Typography variant="h1" className={classes.numberStyle} gutterBottom>
            {resultSet.seriesNames().map((s: any) => resultSet.totalRow()[s.key] || 0)}
          </Typography>
        </Grid>
        {!chartSettings?.isHideLabel && chartSettings?.label && (
          <Grid item xs={12}>
            <p style={{ textAlign: 'center' }}>{chartSettings?.label}</p>
          </Grid>
        )}
        {!chartSettings?.label && !chartSettings?.isHideLabel && (
          <Grid item xs={12}>
            <p style={{ textAlign: 'center' }}>Label</p>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SLNumberView;
