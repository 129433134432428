/**
 * Default Chart configurations for Leak Detection report
 * @author mahesh.kedari@shorelineiot.com
 */
import Highcharts from 'highcharts';
import TranslateText from '../../../../i18n/TranslateText';
/**
 *
 */
export const columnChartConfig = (
  columnData?: Array<any>,
  lineData?: Array<any>
): Highcharts.Options => {
  return {
    lang: {
      noData: TranslateText('no_data_found', 'No data found')
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    chart: {
      type: 'column',
      animation: false,
      zooming: { type: 'x' },
      resetZoomButton: {
        position: {
          x: 0,
          y: 0
        }
      },
      height: 'calc(45vh + 100px)'
    },
    title: {
      text: 'Monthly Leak Detection (Count)'
    },
    xAxis: {
      type: 'category'
    },
    time: {
      useUTC: false
    },
    yAxis: [
      {
        title: {
          text: 'Leak Events'
        }
      },
      {
        title: {
          text: 'Duration'
        },
        opposite: true
      }
    ],
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true
        }
      }
    },
    tooltip: {
      formatter: function () {
        if (this.series.name === 'Count') {
          return this.series.name + ': ' + this.y;
        } else {
          return (
            this.series.name +
            ': ' +
            // @ts-ignore
            Highcharts.numberFormat(this.y, 2)
          );
        }
      },
      style: {
        color: 'white'
      },
      backgroundColor: 'rgba(77, 77, 77, 0.7)'
    },
    series: [
      {
        type: 'column',
        name: 'Count',
        data: columnData
      },
      {
        type: 'line',
        name: 'Duration(Hours)',
        data: lineData,
        yAxis: 1
      }
    ]
  };
};
