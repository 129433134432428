/**
 * Sidebar Items Style
 * @author mahesh.kedari@shorelineiot.com
 */
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { Colors } from './../../../constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  routeLinks: {
    textDecoration: 'none'
  },
  listIcon: {
    color: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText
  },
  listText: {
    color: `${theme.palette.primary.contrastText} !important`
  },
  subMenu: {
    padding: 0,
    margin: 0,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light
    },
    '&.Mui-selected:hover': {
      backgroundColor: Colors.sidebarMenuItemSelectedHover
    }
  },
  Menu: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light
    },
    '&.Mui-selected:hover': {
      backgroundColor: Colors.sidebarMenuItemSelectedHover
    }
  },
  sublistText: {
    paddingLeft: '30px',
    color: `${theme.palette.primary.contrastText} !important`,
    maxHeight: '2.2em',
    display: 'inline-flex',
    '& span': {
      display: 'flex'
    }
  },
  marker: {
    display: 'inline-block',
    borderLeft: 'solid thin #fff',
    position: 'relative',
    height: '39px',
    top: '-20px',
    marginRight: '-1px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0px',
    marginLeft: '-0.4em',
    paddingRight: '25px',
    marginRight: '-5px',
    marginTop: '-2px'
  },
  customIcon: {
    display: 'inline-block',
    margin: 0,
    marginLeft: '-0.68em',
    paddingRight: '20px'
  },
  subMenuDefaultText: {
    width: 140,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '5px',
    marginBottom: '3px'
  },
  defaultText: {
    width: 135,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '5px',
    marginBottom: '3px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));
