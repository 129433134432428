/**
 * SL Waterfall Chart Viewer
 * @author mahesh.kedari@shorelineiot.com
 */
import React from 'react';
import { ChartViewProps } from '../common/ChartViewProps';
import { SLLineChart3DHC } from '../..';
import {
  DEFAULT_CONFIG_3D,
  calculateUnitData
} from '../../../../features/workflow/trend-analysis/waterfall/header/waterfall.helper';
import { FREQUENCY_UNIT } from '../../../../features/workflow/trend-analysis/store';
import TranslateText from '../../../../i18n/TranslateText';

const SLWaterfallChartViewer: React.FC<ChartViewProps> = React.forwardRef(
  ({ chartSettings, resultSet }: ChartViewProps) => {
    const unit = chartSettings?.waterfallXUnit || FREQUENCY_UNIT.Hz;
    const chartRef = React.useRef<React.MutableRefObject<any>>();
    if (!resultSet?.eventsData?.length)
      return (
        <div>
          {TranslateText(
            'no_events_found_for_selected_criteria',
            'No Events found for selected criteria'
          )}
        </div>
      );

    return (
      <SLLineChart3DHC
        config3d={chartSettings?.config3d || DEFAULT_CONFIG_3D}
        chartRef={chartRef}
        data={calculateUnitData(resultSet?.eventsData, unit)}
        chartTitle={
          resultSet?.deviceName ? `${resultSet.deviceName} - Waterfall Chart` : 'Waterfall Chart'
        }
        xAxisZoomConfig={chartSettings?.xAxisZoomConfig}
      />
    );
  }
);

export default SLWaterfallChartViewer;
