import BatteryStatusConditionalFormatting from './BatteryStatusConditionalFormatting';
import {
  BatteryRemainingDisplayTypes,
  defaultSensorNameConditionObject
} from './BatteryStatusConstants';
import { SLDeleteButtonWithTooltip, SLDivider } from '../../..';
import SLAddButtonWithTooltip from '../../button/AddButtonWithTooltip';
import { Divider, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { getConditionList } from './batteryStatusUtils';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { BatteryStatusColumnConditionType } from './batteryStatusTypes';
import TranslateText from '../../../../i18n/TranslateText';

const BatteryStatusConditionContainer = ({
  handleUpdate,
  columnName,
  settings
}: {
  handleUpdate: () => void;
  columnName: string;
  settings: BatteryStatusColumnConditionType;
}) => {
  const prefix = `${columnName}.conditions`;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: prefix
  });

  const handleAddCondition = () => {
    append({ ...defaultSensorNameConditionObject });
    handleUpdate();
  };

  return (
    <>
      {columnName === 'battery_remaining' && (
        <Controller
          name={`${columnName}.display`}
          control={control}
          defaultValue={settings.display}
          render={({ field }) => (
            <RadioGroup
              row
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleUpdate();
              }}>
              <FormControlLabel
                value={BatteryRemainingDisplayTypes.ICON}
                control={<Radio />}
                label={TranslateText('battery_icon', 'Battery Icon')}
              />
              <FormControlLabel
                value={BatteryRemainingDisplayTypes.PERCENTAGE}
                control={<Radio />}
                label={TranslateText('percentage', 'Percentage')}
              />
            </RadioGroup>
          )}
        />
      )}
      <SLDivider textAlign="center">
        {TranslateText('conditional_formatting', 'Conditional Fomatting')}
      </SLDivider>
      {fields &&
        fields.map((field, index) => (
          <>
            <Grid container key={field.id} sx={{ marginTop: '7px', marginBottom: '7px' }}>
              <Grid xs={10}>
                <BatteryStatusConditionalFormatting
                  handleUpdate={handleUpdate}
                  prefix={`${prefix}.${index}`}
                  conditionList={getConditionList(columnName)}
                  settings={settings.conditions[index]}
                />
              </Grid>
              <Grid xs={2}>
                <div style={{ padding: '5px' }}>
                  <SLDeleteButtonWithTooltip
                    tooltip="Delete Condition"
                    onRemove={() => {
                      remove(index);
                      handleUpdate();
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider />
          </>
        ))}
      <div style={{ marginTop: '10px' }}>
        <SLAddButtonWithTooltip tooltip="Add Condition" onAdd={handleAddCondition} />
      </div>
    </>
  );
};

export default BatteryStatusConditionContainer;
