/**
 * VA Narrowbands Saga
 * @author mahesh.kedari@shorelineiot.com
 */
import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import moment from 'moment';

import * as VA_ACTIONS from '../vibrationAnalysis.actionTypes';
import { VAactions } from '..';
import { EventSelectionPayload } from '../vibrationAnalysis.actions';
import { GenericObject, SafeSaga, httpGet, APISERVICES } from '../../../../../framework';
import { SagaIterator } from 'redux-saga';
import { FFT_CHART_STATUS, DAY_RANGE } from '../index';
import { convertToEpoch } from '../../helper/convertToEpoch';

function getNarrowbandsForDevices(slug: string, deviceId: string | number) {
  return httpGet(`orgs/${slug}/devices/${deviceId}/narrowbands_list`, null, APISERVICES.VA_API);
}

function* fetchLatestNarrowbandsForDevices(devicesWithoutEventsList: any) {
  const narrowbandListForDevices: any = [];
  const outputNBList: GenericObject = yield all(
    devicesWithoutEventsList.map((input: any) => {
      return call(getNarrowbandsForDevices, input.slug, input.deviceId);
    })
  );
  /**
   * All narrowbands type has same object so taking velocity nbs
   */
  outputNBList.forEach((currentList: any, index: number) => {
    narrowbandListForDevices.push({
      deviceId: devicesWithoutEventsList[index].deviceId,
      deviceName: devicesWithoutEventsList[index].deviceName,
      narrowbands: currentList.narrowbands['velocity'] || []
    });
  });
  return narrowbandListForDevices;
}

/**
 *
 */
function* getAllNarrowbandsForEvent(payload: any) {
  const result: { narrowbands: any } = yield httpGet(
    `orgs/${payload.slug}/devices/${payload.deviceId}/narrowbands?unit=${
      payload?.unit?.motionUnitType
    }&event_id=${payload.eventId}&dpids=${payload.dpids.join(',')}`,
    null,
    APISERVICES.VA_API
  );
  const narrowbandForEvent = {
    deviceId: payload.deviceId,
    eventId: payload.eventId,
    dpids: payload.dpids,
    deviceName: payload.deviceName,
    narrowbands: result?.narrowbands || []
  };
  return narrowbandForEvent;
}

function* fetchNarrowbandsForEvents(payload: any): GenericObject {
  const unit = payload?.units;
  return yield all(
    payload?.datapointsWithEvents.map((data: any) => {
      const payload: any = { ...data, unit };
      return call(getAllNarrowbandsForEvent, payload);
    })
  );
}
/**
 *
 */
function* getNarrowbandsSaga(action: AnyAction): GenericObject {
  const { slug, devices, events, units, nbdpid, checkednb } = action.payload;

  const selectedEvents = yield select(
    (state: any) => state.features.workflow.va.events.selectedEvent
  );

  const datapointsWithEvents: any = [];
  // Navigate through all the devices and check if they have events configured or not
  devices.forEach((currentDevice: any) => {
    const eventsForCurrentDevice = events?.filter(
      (event: any) => event.device_id === currentDevice.device_id
    );
    // Find out unique events
    const uniqueEvents = eventsForCurrentDevice.filter(
      (event: any, index: number, array: Array<any>) =>
        array.findIndex((selectedEvent) => event.event_id === selectedEvent.event_id) === index
    );
    if (uniqueEvents?.length > 0) {
      // If events are present for current device, then
      // map all the events to all the ODRs for currently selected datapoints
      const eventsForAllODRs = currentDevice.dpids.reduce(
        (acc: Array<any>, currentDpidObj: any) => {
          // Here otherDpids is an array of all the ODRs which contains corresponding dpid
          currentDpidObj.otherDpids.forEach((dpidObj: any) => {
            uniqueEvents.forEach((currentEvent: any) => {
              acc.push({
                ...currentEvent,
                dpids: dpidObj.dpid,
                deviceName: currentDevice.device_name
              });
            });
          });
          return acc;
        },
        []
      );

      //NEW narrowbands api supports multiple dpids on single call so creating payload accordingly and
      //getting all needed fields in single object
      const newPayload = new Map();

      eventsForAllODRs.forEach((event: any) => {
        const payload = newPayload.get(event.event_id);
        newPayload.set(event.event_id, {
          deviceId: event.device_id,
          eventId: event.event_id,
          slug: event.slug,
          deviceName: event.deviceName,
          dpids: payload ? [...payload.dpids, event.dpids] : [event.dpids]
        });
      });
      const payloadConstruct = Array.from(newPayload.values());
      datapointsWithEvents.push(...payloadConstruct);
    }
  });
  const payload = { datapointsWithEvents: datapointsWithEvents, units: units };
  // Make a concurrent request for narrowbands of devices which has events and which doesn't have events
  const [narrowbandsForEvents] = yield all([call(fetchNarrowbandsForEvents, payload)]);

  // Merge Response Arrays to make a consolidated list of narrowbands
  const allNarrowbands: Array<any> = [...narrowbandsForEvents];
  yield put(
    VAactions.fetchNarrowbandsSuccess(allNarrowbands, units, selectedEvents, nbdpid, checkednb)
  );

  /**
   * fetchevent needs start and end value so we are taking currently set value
   * if found or otherwise initial values
   * as initi value for start and end is null
   */
  const start: string | moment.Moment | undefined = yield select(
    (state: any) => state.features.workflow.va.events.startDate
  );
  const end: string | moment.Moment | undefined = yield select(
    (state: any) => state.features.workflow.va.events.endDate
  );

  const startDate: string =
    convertToEpoch(start) || moment().subtract(DAY_RANGE, 'days').valueOf().toString();
  const endDate: string = convertToEpoch(end) || moment().valueOf().toString();

  const selectedNarrowband: {} = yield select(
    (state: any) => state.features.workflow.va.narrowbands.selectedNarrowband
  );
  const eventsState: { deviceEvents: any } = yield select(
    (state: any) => state.features.workflow.va.events
  );
  const fftchartStatus: {} = yield select(
    (state: any) => state.features.workflow.va.fftChart.status
  );

  /**
   * we are getting unique device/event id as we want to know if there are more than
   * one device event matching
   */
  const uniqueDeviceIds = eventsState.deviceEvents.reduce((acc: any[], obj: { device_id: any }) => {
    if (!acc.includes(obj.device_id)) {
      acc.push(obj.device_id);
    }
    return acc;
  }, []);

  if (
    uniqueDeviceIds.length > 1 &&
    eventsState.deviceEvents.length > 0 &&
    selectedNarrowband &&
    fftchartStatus === FFT_CHART_STATUS.FETCHING
  ) {
    /**
     * we calling narrowband_data API to get latest trendchart data as
     * we have found more than 1 device matching timestamp event
     * fftchartStatus === FFT_CHART_STATUS.FETCHING > as other API also getting
     * called so we need to check this
     */
    yield put(VAactions.fetchEvents(slug, selectedNarrowband, units, startDate, endDate));
  }
}

function* getLatestNBSaga(action: AnyAction): GenericObject {
  const { slug, devices, units, nbdpid, checkednb } = action.payload;
  const selectedEvents = yield select(
    (state: any) => state.features.workflow.va.events.selectedEvent
  );

  const payload = devices.map((currentDevice: { device_id: number; device_name: string }) => {
    const singleDevicePayload = {
      deviceId: currentDevice.device_id,
      slug,
      deviceName: currentDevice.device_name
    };
    return singleDevicePayload;
  });
  const [latestNarrowbandsForDevice] = yield all([call(fetchLatestNarrowbandsForDevices, payload)]);

  // Merge Response Arrays to make a consolidated list of narrowbands
  yield put(
    VAactions.fetchNarrowbandDataSuccess(
      latestNarrowbandsForDevice,
      units,
      selectedEvents,
      nbdpid,
      checkednb
    )
  );
}
/**
 *
 * @param action
 */
function* _getNarrowbandsForSelectedEvent(action: AnyAction): GenericObject {
  const {
    units,
    selectedEvent,
    devices,
    slug
  }: // selectedUnits,
  EventSelectionPayload = action.payload;
  const frequencyUnit: {} = yield select((state) => state.features.workflow.va.units.frequencyUnit);
  const amplitudeSelection: {} = yield select(
    (state) => state.features.workflow.va.units.amplitudeSelection
  );
  const motionUnitType: {} = yield select(
    (state) => state.features.workflow.va.units.motionUnit.selectedMotionUnitType
  );
  const selectedUnits: any = {
    amplitudeSelection,
    frequencyUnit,
    motionUnitType
  };

  const selectedEvents: {} = yield select(
    (state: any) => state.features.workflow.va.events.selectedEvent
  );
  // const { deviceId } = selectedEvent;
  //
  const event_id = selectedEvent?.pointEvent;

  //NEW narrowbands api supports multiple dpids on single call so creating payload accordingly and
  //getting all needed fields in single object

  const datapointsWithEvents: any[] = [];
  devices.forEach((device: any) => {
    //
    const { device_id } = device;
    // If current device ID does not matches with the device ID coming from event, ignore those devices
    //
    const payloadConstruct: any = { dpids: [] };
    device.dpids.forEach((selectedDpid: any) => {
      selectedDpid.otherDpids.forEach((datapoint: any) => {
        payloadConstruct.deviceId = device_id;
        payloadConstruct.eventId = event_id;
        payloadConstruct.slug = slug;
        payloadConstruct.deviceName = device.device_name;
        payloadConstruct.dpids = [...payloadConstruct.dpids, datapoint.dpid];
      });
    });
    datapointsWithEvents.push(payloadConstruct);
  });
  const payload = { datapointsWithEvents: datapointsWithEvents, units: units };

  const [narrowbandsForEvents] = yield call(fetchNarrowbandsForEvents, payload);
  const allNarrowbands: Array<any> = [...narrowbandsForEvents];

  yield put(
    VAactions.handleNarrowbandsAfterSelectedEventUpdate(
      allNarrowbands,
      selectedUnits,
      selectedEvents
    )
  );
}

function* handleError(error: any) {
  yield put(VAactions.fetchNarrowbandsFailure(error));
}

/**
 *
 */
export function* watchVANarrowbandsSaga(): SagaIterator {
  yield all([
    takeEvery(
      VA_ACTIONS.FETCH_NARROWBANDS,
      SafeSaga(
        getNarrowbandsSaga,
        VA_ACTIONS.FETCH_NARROWBANDS,
        handleError,
        null,
        'Failed to fetch Narrowbands'
      )
    ),
    takeEvery(
      VA_ACTIONS.FETCH_NARROWBAND_DATA,
      SafeSaga(
        getLatestNBSaga,
        VA_ACTIONS.FETCH_NARROWBAND_DATA,
        null,
        null,
        'Failed to fetch latest Narrowbands'
      )
    )

    // takeEvery(
    //   VA_ACTIONS.UPDATE_EVENT_SELECTION,
    //   SafeSaga(
    //     getNarrowbandsForSelectedEvent,
    //     `${VA_ACTIONS.UPDATE_EVENT_SELECTION}-Narrowbands`,
    //     handleError
    //   )
    // )
  ]);
}
