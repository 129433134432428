import { DeviceIcon, SidebarMenu, useSlugContext } from '../../../framework';
import { sidebarId } from '../../../framework/components/sidebar/sidebar.utils';

const NewAssetsMenu = () => {
  const { slug } = useSlugContext();

  return (
    <SidebarMenu
      testID={sidebarId.menuItems('powertrains')}
      route={`/${slug}/app/new-asset-dashboard`}
      textKey="powertrains"
      defaultText="Powertrains"
      icon={<DeviceIcon className="MuiSvgIcon-root" />}
    />
  );
};

export default NewAssetsMenu;
