/**
 * Icon Cell Component
 * @author mahesh.kedari@shorelineiot.com
 */
import { IconsList } from '../common/IconsList';

interface Props {
  iconName: string;
  color?: string;
}

const IconCell = ({ iconName, color }: Props) => {
  const Component = IconsList.find((icon: any) => icon.value === iconName)?.label;
  if (Component) {
    return <div style={{ color }}>{Component} </div>;
  }
  return null;
};

export default IconCell;
