export type FetchUsersArgs = {
  slug: string;
  showProgressBar?: boolean;
};

export type FetchUserGroupsArgs = {
  slug: string;
  payload?: {
    user_uuid: string;
  };
};

export type RemoveUserArgs = {
  slug: string;
  id: string;
  removedSubSlugs?: string;
};

export type InviteUserArgs = {
  firstName?: string;
  lastName?: string;
  email: string;
  userRole: any;
  slug: string;
  orgSlugs: Array<string>;
  isMainOrg?: boolean;
};

export type ChangeRoleArgs = {
  slug: string;
  userId: string;
  roleId: string;
};

export type ResendInviteArgsReqBody = {
  role_id: string;
  first_name?: string;
  last_name?: string;
  email: string;
};

export type ResendInviteArgs = {
  slug: string;
  reqBody: ResendInviteArgsReqBody;
};

export type ChangeNotificationArgs = {
  feature: string;
  email: boolean;
  sms: boolean;
  appNotification: boolean;
  userId: string;
  slug: string;
};

export type BlockUserArgs = {
  userId: string;
  slug: string;
};

export type UnblockUserArgs = {
  userId: string;
  slug: string;
};

export const USER_TAG_TYPES = {
  USER: 'User',
  USER_GROUP: 'User_Group',
  ORPHAN_GROUP: 'Orphan_Group'
};

export type Invitee = {
  firstName?: string;
  lastName?: string;
  email: string;
  userRole: any;
  slug: string;
  orgSlugs: Array<string>;
  isMainOrg?: boolean;
};

export type ChangeUserGroupArgs = {
  slug: string;
  userId: string;
  associate: number[];
  dissociate: number[];
};
