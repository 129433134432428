export interface BucketConfig {
  bucket_name: string;
  folder_name?: string;
  region_name: string;
  aws_access_key_id: string;
  aws_secret_access_key: string;
}
export interface BucketConfigArgs {
  slug: string;
  formData: BucketConfig;
}
export interface fetchConfig {
  slug: string;
}

export interface ScheduleConfigArgs {
  slug: string;
  formData: { powertrain_ids: string[]; scheduled_time: string };
}
export const DATA_EXPORT_TAG = {
  DATA_EXPORT: 'DATA_EXPORT'
};

export interface UseQuerySkipOptions {
  /**
   * Prevents a query from automatically running.
   * @default `false`
   */
  skip?: boolean;
}

export interface ScheduleConfig {
  last_export_time: string | null;
  next_export_time: string;
  last_data_export_range_start: string | null;
  last_data_export_range_end: string | null;
  powertrains: Record<string, string>;
  cloud_update_interval_string: string;
}

export interface ConfiguredSchedulesResponse {
  configured_schedules: ScheduleConfig[];
  scheduled_time: string;
}

export interface ScheduleTableType {
  powertrainNames: string;
  cloudUpdateInterval: string;
  lastExportTime: string | undefined;
  lastExportDateTimeRange: string;
  nextExportTime: string;
  scheduledTime: string | undefined;
}

export interface S3TestValidationResponse {
  message: string;
  test_file_name: string;
  test_file_content: string;
  bucket_name: string;
  folder_name: string;
}
