/**
 * Application Layout -
 * Multi-lingual support and theme support will be added from this file
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { useState } from 'react';
import { ThemeProvider, StyledEngineProvider, Theme, createTheme } from '@mui/material';
import defaultTheme from '../framework/theme/default/theme';
import FeaturesLayout from '../features/FeaturesLayout';
import { ThemeContext } from '../framework';
import SLConfigureStore from '../configureStore';
import { Provider } from 'react-redux';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const store = SLConfigureStore();
/**
 *
 */
export default function AppLayout(): JSX.Element {
  const [theme, setCustomTheme] = useState<Theme>(defaultTheme);
  const setTheme = (newTheme: Theme) => {
    if (newTheme) {
      const newThemeObj = createTheme(newTheme);
      setCustomTheme(newThemeObj);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <FeaturesLayout />
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
}
