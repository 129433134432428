/**
 * Leak Detection Columns
 * @author mahesh.kedari@shorelineiot.com
 */
import { ReactElement } from 'react';
import { SLTableColumn, useShowTableRowTooltip } from '../../../../framework';

interface Props {
  model: string;
}
interface RowObject {
  rowObj: any;
}
function Name({ model }: Props): ReactElement {
  return useShowTableRowTooltip(model);
}
function Count({ model }: Props): ReactElement {
  return useShowTableRowTooltip(model);
}

function TotalDuration({ model }: Props): ReactElement {
  return useShowTableRowTooltip(model);
}

function AverageDuration({ rowObj }: RowObject): ReactElement {
  return <span>{(rowObj?.total_duration / rowObj.occurrence_count)?.toFixed(2)}</span>;
}

export const LeakHistoricalAlarmColumns: SLTableColumn<Record<string, any>>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    CellRenderer: ({ cellValue }: any) => <Name model={cellValue} />,
    minSize: 30,
    size: 190,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.name?.toLowerCase();
      const rowBValue = rowB.original?.name?.toLowerCase();
      return rowAValue > rowBValue ? 1 : -1;
    }
  },
  {
    accessorKey: 'occurrence_count',
    header: 'Leak Events',
    CellRenderer: ({ cellValue }: any) => <Count model={cellValue} />,
    minSize: 30,
    size: 90,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.occurrence_count;
      const rowBValue = rowB.original?.occurrence_count;
      return rowAValue - rowBValue;
    }
  },
  {
    accessorKey: 'total_duration',
    header: 'Total Duration(Hours)',
    CellRenderer: ({ cellValue }: any) => <TotalDuration model={cellValue} />,
    minSize: 30,
    size: 90,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.total_duration;
      const rowBValue = rowB.original?.total_duration;
      return rowAValue - rowBValue;
    }
  },
  {
    accessorKey: 'alarm_id',
    header: 'Average Duration(Hours)',
    CellRenderer: ({ rowObj }: any) => <AverageDuration rowObj={rowObj} />,
    minSize: 30,
    size: 90,
    sortingFn: (rowA: any, rowB: any): number => {
      const rowAValue = rowA.original?.total_duration / rowA.original?.occurrence_count;
      const rowBValue = rowB.original?.total_duration / rowB.original?.occurrence_count;
      return rowAValue - rowBValue;
    }
  }
];
