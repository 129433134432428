/**
 * Footer Component
 * @author mahesh.kedari@shorelineiot.com
 */
import Container from '@mui/material/Container';
import { useStyles } from './footer.styles';
import Copyright from './copyrights/Copyrights';
/**
 *
 */
const SLFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Copyright />
      </Container>
    </footer>
  );
};
export default SLFooter;
