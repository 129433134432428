/**
 * Alerts Sidebar Menu
 * @author shubham.holkar@shorelineiot.com
 */
import { ACCESS_PERMIT, SidebarMenu, usePermissions, useSlugContext } from '../../framework';
import { AlertsIcon } from '../../framework/icons';
import { ALERTS_PERMISSIONS } from './alerts.permissions';
import { sidebarId } from '../../framework/components/sidebar/sidebar.utils';

export default function AlertsMenu() {
  const { slug } = useSlugContext();
  const hasViewPermission: ACCESS_PERMIT = usePermissions(ALERTS_PERMISSIONS?.VIEW_LIST);
  if (hasViewPermission === ACCESS_PERMIT.ALLOW) {
    return (
      <SidebarMenu
        testID={sidebarId.menuItems('rules')}
        route={`/${slug}/app/alerts`}
        textKey="rules"
        defaultText="Rules"
        icon={<AlertsIcon className="MuiSvgIcon-root" />}
      />
    );
  }
  return null;
}
