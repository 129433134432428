import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { LightTooltip } from '../tooltip/Tooltip';
import { Grid } from '@mui/material';
import { MRT_Row, MRT_TableInstance } from 'material-react-table';
import { useStyles } from './styles/SLEditActionButtons.styles';
import { toSnakeCase } from '../../../i18n/translation.helper';

interface Props<TData extends Record<string, any> = {}> {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
  variant?: 'icon' | 'text';
}

export const SLEditActionButtons = <TData extends Record<string, any> = {}>({
  row,
  table,
  variant = 'icon'
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { CancelIcon, SaveIcon },
      localization,
      onEditingRowSave,
      onEditingRowCancel
    },
    refs: { editInputRefs },
    setEditingRow
  } = table;
  const { editingRow } = getState();
  const classes = useStyles();

  const handleCancel = () => {
    setEditingRow(null);

    onEditingRowCancel?.({
      row: row,
      table: table
    });
  };

  const handleSave = () => {
    const newValues = editingRow?.original;

    Object.values(editInputRefs?.current)?.forEach((input) => {
      if (input.value !== undefined) {
        // @ts-ignore
        newValues[input.name] = input.value;
      }
    });

    onEditingRowSave?.({
      exitEditingMode: () => setEditingRow(null),
      row: editingRow ?? row,
      table,
      values: newValues || { ...row.original }
    });
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} className={classes.container}>
      {variant === 'icon' ? (
        <Grid container wrap="nowrap" justifyContent="flex-start">
          <Grid item>
            <LightTooltip
              title={localization.cancel}
              placement="bottom-end"
              translationId={toSnakeCase(localization.cancel)}>
              <IconButton aria-label={localization.cancel} onClick={handleCancel}>
                <CancelIcon />
              </IconButton>
            </LightTooltip>
          </Grid>
          <Grid item className={classes.saveButtonContainer}>
            <LightTooltip
              title={localization.save}
              placement="bottom-end"
              translationId={toSnakeCase(localization.save)}>
              <IconButton aria-label={localization.save} color="info" onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            </LightTooltip>
          </Grid>
        </Grid>
      ) : (
        <>
          <Button onClick={handleCancel}>{localization.cancel}</Button>
          <Button onClick={handleSave} variant="contained">
            {localization.save}
          </Button>
        </>
      )}
    </Box>
  );
};
