import { createRoot } from 'react-dom/client';
import 'typeface-poppins';
import './index.css';
import App from './App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  VITE_AUTH_USER_POOL_ID,
  VITE_AUTH_USER_POOL_WEB_CLIENT,
  VITE_BUGSNAG_KEY,
  VITE_IDENTITY_POOL_ID
} from './framework/services/envConstants';
import { ResourcesConfig } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';

if (VITE_IDENTITY_POOL_ID && VITE_AUTH_USER_POOL_ID && VITE_AUTH_USER_POOL_WEB_CLIENT) {
  const AuthConfig: ResourcesConfig['Auth'] = {
    Cognito: {
      identityPoolId: VITE_IDENTITY_POOL_ID,
      userPoolId: VITE_AUTH_USER_POOL_ID,
      userPoolClientId: VITE_AUTH_USER_POOL_WEB_CLIENT
    }
  };
  Amplify.configure({
    Auth: AuthConfig
  });
}

if (VITE_BUGSNAG_KEY && import.meta.env.PROD) {
  Bugsnag.start({
    apiKey: String(VITE_BUGSNAG_KEY),
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
    maxBreadcrumbs: 100,
    maxEvents: 100
  });
}
const container = document?.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);
root.render(<App />);
