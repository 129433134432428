/**
 * Generic Dashboard Type Aggregator
 * @author aditya.bhadange@shorelineiot.com
 */

import { Device } from '..';
import { Alarm } from '../../../alarms/store';

export enum DASHBOARD_TYPE {
  ASSET_DASHBOARD = 'asset_dashboard',
  SENSOR_HEALTH = 'sensor_health'
}

export type GenericDashboardType = 'asset_dashboard' | 'sensor_health';

interface FetchGenericDashboardArgs<T extends GenericDashboardType> {
  slug: string;
  dashboard_type: T;
}

/**
 * Represents the properties for fetching a generic dashboard with sensor health data.
 */
interface FetchGenericDashboardArgsSensorHealth {
  /**
   * The type of compatible device for which sensor health data is fetched.
   */
  compatible_device_type: string;
}

/**
 * Represents the merged properties for fetching a generic dashboard.
 * If the dashboard type is 'sensor_health', the `compatible_device_type` property is included.
 * @template T - The type of the dashboard ('asset_dashboard' or 'sensor_health').
 */
export type MergedFetchGenericDashboardArgs<T extends GenericDashboardType> =
  T extends 'sensor_health'
    ? FetchGenericDashboardArgs<T> & FetchGenericDashboardArgsSensorHealth
    : FetchGenericDashboardArgs<T>;

export type FetchGenericDashboardQueryParams = Omit<
  FetchGenericDashboardArgs<GenericDashboardType>,
  'slug'
>;

export type DashboardPositionMeta = [number, number, number, number, number, number];

interface ViewConfig {
  is_hidden: string;
  position_meta: DashboardPositionMeta;
  widget_settings: {
    title: string;
    widget_type: string;
    /**
     * `redirect_datapoint_type` might not be needed in future, but keeping
     * it for now o maitaining parity with old code.
     */
    redirect_datapoint_type?: string;
  };
}

interface WidgetConfig {
  units: string;
  sensor_type: string;
  view_config: ViewConfig;
  datapoint_type: string;
}

export enum WIDGET_TYPE {
  SNS_LIST = 'SNS_LIST',
  ASSET_ALARMS = 'ASSET_ALARMS',
  IMAGE_WIDGET = 'IMAGE_WIDGET',
  DEVICE_ALARMS = 'DEVICE_ALARMS',
  SNS_RSRQ = 'SNS_RSRQ',
  SNS_BAT_ACCUM = 'SNS_BAT_ACCUM',
  SNS_TOTAL_VIBRATION_ENERGY = 'SNS_TOTAL_VIBRATION_ENERGY',
  SNS_TEMP_SURFACE = 'SNS_TEMP_SURFACE',
  SNS_HMD = 'SNS_HMD',
  SNS_TEMP_ENV = 'SNS_TEMP_ENV'
}

export type WidgetType =
  | WIDGET_TYPE.SNS_LIST
  | WIDGET_TYPE.ASSET_ALARMS
  | WIDGET_TYPE.IMAGE_WIDGET
  | WIDGET_TYPE.DEVICE_ALARMS
  | WIDGET_TYPE.SNS_RSRQ
  | WIDGET_TYPE.SNS_BAT_ACCUM
  | WIDGET_TYPE.SNS_TOTAL_VIBRATION_ENERGY
  | WIDGET_TYPE.SNS_TEMP_SURFACE
  | WIDGET_TYPE.SNS_HMD
  | WIDGET_TYPE.SNS_TEMP_ENV;

export interface DashboardWidget {
  widget_type: WidgetType;
  config: WidgetConfig;
  widget_uuid: string;
}

interface DashboardConfig {
  title: string;
  dashboard_type: string;
}

export interface GenericDashboardResponse {
  widgets: DashboardWidget[];
  compatible_device_type: string | null;
  dashboard_config: DashboardConfig;
  dashboard_type: string;
  dashboard_uuid: string;
}

export interface FetchAssetWidgetDataArgs {
  slug: string;
  widget_uuid: string;
  asset_path: string;
}

export interface FetchSensorWidgetDataArgs {
  slug: string;
  widget_uuid: string;
  device_id: number;
  dpid?: number;
  start_ts?: number;
  end_ts?: number;
}

export type FetchSensorWidgetDataQueryParams = Omit<FetchSensorWidgetDataArgs, 'slug'>;

export type FetchAssetWidgetDataQueryParams = Omit<FetchAssetWidgetDataArgs, 'slug'>;

type SNSListWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_LIST;
  data: Device[];
};

type AssetAlarmsWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.ASSET_ALARMS;
  data: Alarm[];
};

type TVEWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_TOTAL_VIBRATION_ENERGY;
  data: any[];
};

type DeviceAlarmsWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.DEVICE_ALARMS;
  data: Alarm[];
};

type CellularWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_RSRQ;
  data: [{ value: number; ts: number }];
};

type SurfaceTemperatureWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_TEMP_SURFACE;
  data: any[];
};

type HumidityWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_HMD;
  data: any[];
};

type BatteryWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_BAT_ACCUM;
  data: [{ batt_remaining: number; days_remaining: number }];
};

type EnvironmentTemperatureWidget = {
  widget_uuid: string;
  widget_type: WIDGET_TYPE.SNS_TEMP_ENV;
  data: any[];
};

export type FetchAssetWidgetDataResponse = SNSListWidget | AssetAlarmsWidget;

export type FetchSensorWidgetDataResponse =
  | TVEWidget
  | DeviceAlarmsWidget
  | CellularWidget
  | SurfaceTemperatureWidget
  | HumidityWidget
  | BatteryWidget
  | EnvironmentTemperatureWidget;

export interface FetchImageWidgetDataArgs {
  assetSlug: string;
  assetPath: string;
}

interface ImageMarker {
  top: number;
  left: number;
  sensor_id: number;
}

export interface FetchImageWidgetDataResponse {
  imageUrlData: string;
  imageMarkerData: {
    markers: ImageMarker[];
  };
}

export interface UpdateSensorWidgetDataArgs {
  slug: string;
  widgets: UpdateSensorWidgetsPayload[];
}

export interface UpdateSensorWidgetDataResponse {
  slug: string;
}

interface UpdateSensorWidgetsPayload {
  config: WidgetConfig[];
  widget_uuid: string;
}

export interface UpdateSensorWidgetDataQueryParams {
  widgets: UpdateSensorWidgetsPayload[];
}
