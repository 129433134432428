/**
 * Main List Items
 * @author mahesh.kedari@shorelineiot.com
 */

import DeviceMenu from '../features/device/DeviceMenu';
// import DeviceProfileMenu from '../features/device-profile/DeviceProfileMenu';
import AlertsMenu from '../features/alerts/AlertsMenu';
import WorkflowMenu from '../features/workflow/WorkflowMenu';
import FirmwareMenu from '../features/firmware/FirmwareMenu';
import ReportsMenu from '../features/reports/ReportsMenu';
import OrgSettingsMenu from '../features/org-settings/OrgSettings.SidebarMenu';
import ActivityLogsMenu from '../features/activity-logs/ActivityLogs.menu';
import DashboardMenu from '../features/dashboard/DashboardMenu';
import AlarmsMenu from '../features/alarms/AlarmsMenu';
import TemplatesMenu from '../features/templates/TemplatesMenu';
import ChatMenu from '../features/chat/Chat.menu';
import NewAssetsMenu from '../features/device/new-asset-dashboard/NewAssetsMenu';
import UserScriptsMenu from '../features/user-scripts/UserScript.menu';

export default function SidebarMenuList(): JSX.Element {
  return (
    <>
      <DashboardMenu />
      <AlarmsMenu />
      <DeviceMenu />
      <NewAssetsMenu />
      <WorkflowMenu />
      <OrgSettingsMenu />
      <ActivityLogsMenu />
      <ReportsMenu />
      <FirmwareMenu />
      <AlertsMenu />
      <TemplatesMenu />
      <ChatMenu />
      <UserScriptsMenu />
    </>
  );
}
