/**
 * SL Sidebar
 * @author mahesh.kedari@shorelineiot.com
 */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import { List, Box, useMediaQuery, useTheme, IconButton, Typography } from '@mui/material';
import { LightTooltip } from '../tooltip/Tooltip';
import { useStyles } from './Sidebar.styles';
import { useSlugContext } from '../../lib/slugContext';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { useSidebarOpenState } from './sidebar.slector';
import { closeSidebar, toggleSidebar } from './sidebar.slice';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import TranslateText from '../../../i18n/TranslateText';

interface Props {
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  header?: ReactNode;
  children: ReactNode;
}
const SLSidebar: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { slug } = useSlugContext();
  const { variant, header, children } = props;
  const [hover, setHover] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const dispatch = useDispatch();

  const drawerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const drawerElement = drawerRef.current;
    const handleTransitionEnd = (event: TransitionEvent) => {
      if (event.propertyName === 'width') {
        /*  This solution is based on issue or discussion on ResponsiveGridLayout's GitHub page.

            The ResponsiveGridLayout used inside DynamicDashboard does not
          automatically adjust the container width after opening/closing the sidebar.
          To address this issue, we dispatch a "resize" event to trigger a container width
          adjustment when the sidebar width transition completes. 
          Reference: (https://github.com/react-grid-layout/react-grid-layout/issues/1909).
       */
        window.dispatchEvent(new Event('resize'));
      }
    };

    if (drawerElement) {
      drawerElement.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (drawerElement) {
        drawerElement.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [drawerRef]);

  const openSidebar = useSidebarOpenState();

  const handleDrawerToggle = () => {
    dispatch(toggleSidebar());
  };

  const handleSideBarClose = () => {
    dispatch(closeSidebar());
  };

  const handleMouseEnter = debounce(() => {
    setHover(true);
  }, 100);

  const handleMouseLeave = debounce(() => {
    setHover(false);
  }, 100);

  return (
    <div style={{ position: 'relative' }}>
      <Drawer
        variant={variant}
        ref={drawerRef}
        PaperProps={{
          elevation: 5
        }}
        classes={{
          paper: clsx(classes.drawerPaper, !openSidebar && !hover && classes.drawerPaperClose),
          root: isDesktop && !openSidebar && !hover ? classes.drawerRootClose : undefined
        }}
        open={openSidebar || (isDesktop && hover && !openSidebar)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        transitionDuration={0}
        onClose={handleSideBarClose}>
        <div className={classes.header}>{header}</div>
        <List className={classes.list}>{children}</List>
        <LightTooltip title={`Version: ${import.meta.env.VITE_WEBSITE_VERSION}`} placement="top">
          <Box display="flex" justifyContent="center" className={classes.footer}>
            <Typography variant="subtitle1" className="tour-release-notes">
              {slug === 'shorelinedemo'
                ? 'Powered by AWS'
                : TranslateText('powered_by_shoreline', 'Powered by Shoreline')}
            </Typography>
          </Box>
        </LightTooltip>
      </Drawer>
      {isDesktop && (
        <LightTooltip title={openSidebar ? 'Collapse' : 'Expand'}>
          <IconButton onClick={handleDrawerToggle} className={classes.collapseIcon} size="small">
            {openSidebar ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
          </IconButton>
        </LightTooltip>
      )}
    </div>
  );
};

export default SLSidebar;
