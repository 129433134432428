/**
 * Invite Accept Failed
 * @author mahesh.kedari@shorelineiot.com
 */
import { Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSlugContext } from '../../../framework';
import { FailureLarge } from '../../../framework/icons';
/**
 *
 * @returns
 */
export default function NotInvitedUser() {
  const slug = useSlugContext();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FailureLarge />
      <Typography variant="h2">Invalid User</Typography>
      <br />
      <Typography variant="h4">
        This user is not invited to any of the organization in our system. <br />
        Please contact your organization administator for further assistance
      </Typography>
      <br />
      <Typography>
        Try with different email ID? &nbsp;
        <RouterLink to={`/${slug}/auth/login`}>Login</RouterLink>
      </Typography>
    </Box>
  );
}
