/**
 * Banner component
 * @author mahesh.kedari@shorelineiot.com
 */
import Alert, { AlertProps } from '@mui/material/Alert';
import { Typography } from '@mui/material';

interface Props extends AlertProps {
  message: string;
  alertClassName?: string;
  messageClassName?: string;
}

const SLBanner: React.FC<Props> = ({
  message,
  alertClassName,
  messageClassName,
  ...otherProps
}: Props) => {
  return (
    <Alert className={alertClassName} {...otherProps}>
      <Typography className={messageClassName} variant="h4">
        {message}
      </Typography>
    </Alert>
  );
};

export default SLBanner;
